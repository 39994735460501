export const TABS = [
  {
    key: '1',
    label: 'Acquisitions',
  },
  {
    key: '2',
    label: 'Dispositions',
  },
  {
    key: '3',
    label: 'Inter-Company Transfers',
  },
];

export const TAB_TO_DATA_KEY = {
  1: 'Acquisitions',
  2: 'Dispositions',
  3: 'Inter-Company Transfers',
};
