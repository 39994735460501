import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const createWatchlist = async (params) => {
  await postRequest('/data/watchlist/create-list', params);
};

export const useCreateWatchlist = (onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createWatchlist,
    onSuccess: () => {
      onSuccess();
      showSuccessToast('List created successfully');
      queryClient.invalidateQueries({
        queryKey: ['watchLists'],
      });
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.reason || 'Creating list failed');
    },
  });
};

export const getWatchLists = async () => {
  const response = await getRequest('/data/watchlist/get-watch-lists');

  const { data } = response.data;

  return data;
};

export const useGetWatchLists = () =>
  useQuery({
    queryKey: ['watchLists'],
    queryFn: () => getWatchLists(),
  });

export const addToWatchList = async (params) => {
  await postRequest('/data/watchlist/add-account-to-list', params);
};

export const useAddToWatchlist = (onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addToWatchList,
    onSuccess: () => {
      onSuccess();
      showSuccessToast('Account added to the list!');
      queryClient.invalidateQueries({
        queryKey: ['watchLists'],
      });
      queryClient.invalidateQueries({
        queryKey: ['watchListDetails'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Adding account to the list failed!',
      );
    },
  });
};

export const removeFromWatchlist = async (params) => {
  await postRequest('/data/watchlist/remove-account-from-list', params);
};

export const useRemoveFromWatchlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeFromWatchlist,
    onSuccess: () => {
      showSuccessToast('Account removed from the list!');
      queryClient.invalidateQueries({
        queryKey: ['watchLists'],
      });
      queryClient.invalidateQueries({
        queryKey: ['watchListDetails'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason ||
          'Removing account from the list failed!',
      );
    },
  });
};

export const getListDetails = async (params) => {
  const response = await postRequest(
    '/data/watchlist/get-watchlist-details',
    params,
  );

  const { data } = response.data;

  return data;
};

export const useGetListDetails = ({
  listId,
  selectedMonthRange,
  appliedFilters,
}) =>
  useQuery({
    queryKey: ['watchListDetails', listId, selectedMonthRange, appliedFilters],
    queryFn: () =>
      getListDetails({ listId, selectedMonthRange, appliedFilters }),
    enabled: Boolean(listId),
  });
