import { Table } from 'antd';
import { getTableColumns } from './constants';
import { isMobile } from '../../../../../../services/clientService';

export const PortfolioAggregationsTable = ({ isLoading, data }) => {
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  return (
    <Table
      rowKey="address"
      loading={isLoading}
      columns={getTableColumns()}
      dataSource={data?.portfolioAggregations || []}
      scroll={tableScroll}
    />
  );
};
