import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  buildLoginUrlWithRedirect,
  buildOnboardingPageUrl,
} from '../services/urlBuilderService';
import { getUserData } from '../reducers/Auth/actions';
import {
  getAuthToken,
  postRefreshTasks,
  QUERY_PARAM_USER_ID,
  QUERY_PARAM_MAGIC_TOKEN,
} from '../services/authService';
import { getQueryParam } from '../services/clientService';
import { Spinner } from '../components/layout';
import ProtectedLayout from '../containers/layout';

const PrivateRoute = (props) => {
  const {
    isAppLoading,
    userDetails,
    component: Component,
    path,
    sidebar,
    ...rest
  } = props;
  useEffect(() => {
    postRefreshTasks();
  }, []);
  useEffect(() => {
    const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);
    const queryUserId = getQueryParam(QUERY_PARAM_USER_ID);
    const authToken = getAuthToken();

    if (!magicToken && !authToken) props.push(buildLoginUrlWithRedirect());
    else if (!userDetails && !isAppLoading) {
      let placeFriendlyUrl;

      if (window.location.pathname.startsWith('/p/')) {
        placeFriendlyUrl = window.location.pathname
          .substring(1)
          .replace('p', '');
      }
      props.getUserData(queryUserId, placeFriendlyUrl);
    }
  }, [isAppLoading, userDetails]);

  useEffect(() => {
    if (
      userDetails &&
      !userDetails?.defaultOrganization?.dataSubscription
        ?.isCompletedOnboarding &&
      !rest?.location?.pathname?.includes('/onboarding')
    ) {
      props.push(buildOnboardingPageUrl());
    }
  }, [userDetails, rest]);

  const connectedStates =
    userDetails?.defaultOrganization?.dataSubscription?.activatedStates || [];
  const hasNationwideAccess =
    userDetails?.defaultOrganization?.dataSubscription?.hasNationwideAccess;

  useEffect(() => {
    if (
      userDetails &&
      userDetails?.defaultOrganization?.dataSubscription
        ?.isCompletedOnboarding &&
      !hasNationwideAccess &&
      connectedStates.length === 0
    ) {
      props.push('/settings');
    }
  }, [userDetails]);

  const render = () =>
    userDetails ? (
      <ProtectedLayout {...props}>
        <Component {...props} sidebar={sidebar} userDetails={userDetails} />
      </ProtectedLayout>
    ) : (
      <Spinner />
    );

  return <Route path={path} render={render} {...rest} />;
};
const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, isAppLoading } = AuthReducer;
  return {
    userDetails,
    isAppLoading,
  };
};
const mapDispatchToProps = {
  getUserData,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
