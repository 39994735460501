import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { getTableColumns } from './constants';
import { isMobile } from '../../../../../../services/clientService';
import { useReportContact } from '../../../../../../queries/DiscoverOrganization';
import { showSuccessToast } from '../../../../../../services/notificationsService';

import './index.scss';

export const ContactsTable = ({
  isLoading,
  data,
  refetch,
  setSelectedContactAcquiredProperties,
  setSelectedOrganizationName,
  setSelectedRows,
}) => {
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };
  const dispatch = useDispatch();

  const { mutate: reportContact } = useReportContact(refetch, () =>
    showSuccessToast('Invalid contact reported successfully!'),
  );
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  return (
    <Table
      rowKey="id"
      loading={isLoading}
      columns={getTableColumns({
        dispatch,
        reportContact,
        setSelectedContactAcquiredProperties,
        setSelectedOrganizationName,
      })}
      dataSource={data}
      scroll={tableScroll}
      className="all-contacts-table"
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      pagination={pagination}
    />
  );
};
