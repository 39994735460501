import { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { AiOutlineMinus } from '@react-icons/all-files/ai/AiOutlineMinus';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';

import { setModalState } from '../../../reducers/Modal/actions';
import { MODAL_INVITE_MEMBER } from '../../../reducers/Modal/types';
import EmailInput from '../../../components/forms/EmailInput';
import { ZorbaButton } from '../../../components/forms';
import { sendMemberInvitation } from '../../../reducers/Auth/actions';

import './index.scss';

export const InviteMembersModal = () => {
  const dispatch = useDispatch();
  const { isInviteMemberModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { isAddingNewMembers } = useSelector(({ AuthReducer }) => AuthReducer);
  const initialInputs = [{ value: '', error: '', key: uuid() }];
  const [inputs, setInputs] = useState(initialInputs);
  const handleClose = () => {
    dispatch(setModalState(MODAL_INVITE_MEMBER, false));
    setInputs(initialInputs);
  };

  const handleEmailChange = ({ value, isValid, errorMessage }, index) => {
    const newInputs = [...inputs];
    newInputs[index].value = value;

    if (!isValid) {
      newInputs[index].error = errorMessage;
    } else {
      newInputs[index].error = '';
    }

    setInputs(newInputs);
  };

  const handleAddInput = () => {
    const newInputs = [...inputs, { value: '', error: '', key: uuid() }];
    setInputs(newInputs);
  };

  const handleRemoveInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const handleSubmit = async () => {
    await dispatch(
      sendMemberInvitation(
        inputs
          .map((input) => input.value.trim())
          .filter((input) => Boolean(input)),
        handleClose,
      ),
    );
  };

  return (
    <Modal
      open={isInviteMemberModalOpen}
      footer={null}
      onCancel={handleClose}
      title="Invite your colleagues"
      className="invite-members-modal"
      destroyOnClose
    >
      <p className="invite-members-description">
        {`Fill in the emails of your team members, they will receive an invite to
        join your organization so you can collaborate on your account.`}
      </p>
      <div className="invite-members-container">
        {inputs.map((input, index) => (
          <div className="invite-members-input-container" key={input.key}>
            <EmailInput
              onChange={(event) => handleEmailChange(event, index)}
              showErrorMessage={Boolean(input.error)}
              errorMessage={input.error}
              fullWidth
              label=""
              formItemWithoutLabel
            />
            <div className="invite-members-icon-container">
              {index === inputs.length - 1 && (
                <AiOutlinePlus
                  className="icon"
                  onClick={handleAddInput}
                  size={20}
                />
              )}
              {inputs.length > 1 && (
                <AiOutlineMinus
                  className="icon"
                  size={20}
                  onClick={() => handleRemoveInput(index)}
                />
              )}
            </div>
          </div>
        ))}
        <div className="invite-members-button-container">
          <ZorbaButton
            fullWidth={false}
            onClick={handleSubmit}
            disabled={
              inputs.some((input) => input.error) ||
              inputs.every((input) => !input.value)
            }
            loading={isAddingNewMembers}
          >
            Invite
          </ZorbaButton>
        </div>
      </div>
    </Modal>
  );
};
