import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './index.scss';

export default (props) => {
  const { className = '', fontSize = 24 } = props;
  const antIcon = <LoadingOutlined style={{ fontSize }} spin />;
  return (
    <div className={`spinner-wrapper ${className && className}`}>
      <Spin indicator={antIcon} />
    </div>
  );
};
