import {
  getCookie,
  setCookie,
} from '@zorba-shared/client/Services/clientService';

export const COOKIE_NAME_TRACKING_PARAMS = 'tracking_params';

export const getQueryParamsAsObj = (location = window.location) => {
  const search = location.search.substring(1);
  if (!search) return JSON.stringify({});

  return JSON.stringify(
    JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`,
    ),
  );
};
export const setInitialClientUrl = () => {
  const trackingParams = getCookie(COOKIE_NAME_TRACKING_PARAMS);
  if (!trackingParams) {
    setCookie(
      COOKIE_NAME_TRACKING_PARAMS,
      getQueryParamsAsObj(window.location),
    );
  }
};
export const getTrackingParams = () => {
  const trackingParams = getCookie(COOKIE_NAME_TRACKING_PARAMS);

  return trackingParams ? JSON.parse(trackingParams) : {};
};
