import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const getAccountDetails = async (params) => {
  const response = await postRequest(
    '/data/top-buyers-report/account-details',
    params,
  );

  const { data } = response.data;

  return data;
};

export const getAccountMailingAddresses = async (params) => {
  const response = await postRequest(
    '/data/top-buyers-report/account-mailing-addresses',
    params,
  );

  const { data } = response.data;

  return data;
};

export const getAccountTransactions = async (params) => {
  const response = await postRequest(
    '/data/top-buyers-report/account-transactions',
    params,
  );

  const { data } = response.data;

  return data;
};

export const getAccountStats = async (params) => {
  const response = await postRequest(
    '/data/top-buyers-report/account-stats',
    params,
  );

  const { data } = response.data;

  return data;
};

export const getAccountEntities = async (params) => {
  const response = await postRequest('/data/entity/get-entities', params);

  const { data } = response.data;

  return data;
};
export const getAccountStates = async (params) => {
  const response = await postRequest('/data/buybox/active-states', params);

  const { data } = response.data;

  return data;
};

export const getAccountBuyBox = async (params) => {
  const response = await postRequest(
    '/data/buybox/get-organization-buybox',
    params,
  );

  const { data } = response.data;

  return data;
};

export const useGetAccountEntities = ({ postgresId }) =>
  useQuery({
    queryKey: ['accountEntities', postgresId],
    queryFn: () => getAccountEntities({ postgresId }),
  });

export const useGetAccountDetails = ({
  selectedRange,
  postgresId,
  appliedFilters,
}) =>
  useQuery({
    queryKey: ['accountDetails', selectedRange, postgresId, appliedFilters],
    queryFn: () =>
      getAccountDetails({ selectedRange, postgresId, appliedFilters }),
  });

export const useGetAccountMailingAddresses = ({ organizationId }) =>
  useQuery({
    queryKey: ['accountMailingAddresses', organizationId],
    queryFn: () => getAccountMailingAddresses({ organizationId }),
  });

export const useGetAccountTransactions = ({
  selectedRange,
  postgresId,
  appliedFilters,
}) =>
  useQuery({
    queryKey: [
      'accountTransactions',
      selectedRange,
      postgresId,
      appliedFilters,
    ],
    queryFn: () =>
      getAccountTransactions({ selectedRange, postgresId, appliedFilters }),
  });

export const useGetAccountStats = ({ postgresId, appliedFilters }) =>
  useQuery({
    queryKey: ['accountStats', postgresId, appliedFilters],
    queryFn: () => getAccountStats({ postgresId, appliedFilters }),
  });

export const useGetAccountBuyBox = ({
  state,
  postgresId,
  selectedMonthRange,
  propertyType,
}) =>
  useQuery({
    queryKey: ['buyBox', state, selectedMonthRange, propertyType],
    queryFn: () =>
      getAccountBuyBox({
        state,
        organizationId: postgresId,
        selectedMonthRange,
        propertyType,
      }),
    enabled: Boolean(state),
  });

export const useGetAccountStates = ({
  selectedMonthRange,
  postgresId,
  propertyType,
}) =>
  useQuery({
    queryKey: ['activeStates', selectedMonthRange, postgresId, propertyType],
    queryFn: () =>
      getAccountStates({ selectedMonthRange, postgresId, propertyType }),
    enabled:
      Boolean(propertyType) &&
      Boolean(selectedMonthRange) &&
      Boolean(postgresId),
  });

export const downloadZipcodes = async (fileName, params) => {
  try {
    const response = await postRequest(
      '/data/buybox/download-zipcodes',
      params,
    );
    const { reason } = response.data;

    if (reason) {
      showErrorToast(reason);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  } catch (e) {
    showErrorToast(`Downloading zipcodes failed: ${e.message}`);
  }
};

export const deleteEntity = async (params) => {
  await postRequest('/data/entity/delete-entity', params);
};

export const useDeleteEntity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEntity,
    onSuccess: () => {
      showSuccessToast('Entity deleted successfully');
      queryClient.invalidateQueries({
        queryKey: ['accountEntities'],
      });
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.reason || 'Deleting entity failed');
    },
  });
};

const getOrganizationRegexes = async (params) => {
  const response = await postRequest(
    '/data/organization/get-organization-regexes',
    params,
  );

  const { data } = response.data;

  return data;
};

export const useGetOrganizationRegexes = ({ organizationId }) =>
  useQuery({
    queryKey: ['organizationRegexes', organizationId],
    queryFn: () =>
      getOrganizationRegexes({
        organizationId,
      }),
    enabled: Boolean(organizationId),
  });

export const deleteOrganizationRegex = async (params) => {
  await postRequest('/data/organization/delete-organization-regex', params);
};

export const useDeleteOrganizationRegex = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteOrganizationRegex,
    onSuccess: () => {
      showSuccessToast('Organization regex deleted successfully');
      queryClient.invalidateQueries({
        queryKey: ['organizationRegexes'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Deleting organization regex failed',
      );
    },
  });
};

export const createOrganizationRegex = async (params) => {
  await postRequest('/data/organization/create-organization-regex', params);
};

export const useCreateOrganizationRegex = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOrganizationRegex,
    onSuccess: () => {
      showSuccessToast('Organization regex successfully created');
      queryClient.invalidateQueries({
        queryKey: ['organizationRegexes'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Creating organization regex failed',
      );
    },
  });
};

export const updateOrganizationDetails = async (params) => {
  await postRequest('/data/organization/update-organization-details', params);
};

export const useUpdateOrganizationDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOrganizationDetails,
    onSuccess: () => {
      showSuccessToast('Organization details updated successfully');
      queryClient.invalidateQueries({
        queryKey: ['accountDetails'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Updating organization details failed',
      );
    },
  });
};
