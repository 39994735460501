export const buildQueryString = (url, queryParams) => {
  if (!url || !queryParams) return url || '';

  let baseUrl = url;
  const queryString = Object.keys(queryParams)
    .filter((x) => !!queryParams[x])
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
  if (queryString.length > 0) {
    baseUrl += `?${queryString}`;
  }
  return baseUrl;
};

export function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}