import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setAppliedFilters } from '../../../../../../../../../reducers/Transactions/actions';
import {
  ZorbaButton,
  ZorbaInput,
} from '../../../../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../../../../components/forms/ZorbaButton/constants';

export const PurchasePriceBuilder = ({ onApplyFilter }) => {
  const dispatch = useDispatch();
  const { appliedFiltersTransactions: appliedFilters } = useSelector(
    ({ TransactionsReducer }) => TransactionsReducer,
  );
  const defaultSelectValue = appliedFilters.purchasePrice || [];

  const [purchasePrice, setPurchasePrice] = useState(defaultSelectValue);
  const handleChange = (newValue, modifiedValueIndex) => {
    const updatedValue = [...purchasePrice];
    updatedValue[modifiedValueIndex] = newValue;

    if (modifiedValueIndex === 0) {
      updatedValue[1] = null;
    }

    const filteredValue = updatedValue.filter(Boolean);
    setPurchasePrice(filteredValue);
  };

  const isApplyButtonDisabled = !purchasePrice.length;

  const handleApply = () => {
    dispatch(setAppliedFilters('purchasePrice', purchasePrice));
    onApplyFilter();
  };

  return (
    <div className="builder-container">
      <h3>Purchase Price</h3>
      <div className="purchase-price-options-container">
        <ZorbaInput
          placeholder="No min"
          value={purchasePrice[0]}
          type="number"
          onChange={(nevVal) => handleChange(nevVal, 0)}
          minValue={0}
          fullWidth={false}
        />
        <span className="separator">-</span>
        <ZorbaInput
          placeholder="No max"
          value={purchasePrice[1]}
          type="number"
          onChange={(nevVal) => handleChange(nevVal, 1)}
          minValue={0}
          fullWidth={false}
        />
      </div>
      <ZorbaButton
        variant={BUTTON_VARIANT_DARK}
        disabled={isApplyButtonDisabled}
        onClick={handleApply}
        fullWidth={false}
      >
        Apply
      </ZorbaButton>
    </div>
  );
};
