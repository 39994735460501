import { useHistory } from 'react-router-dom';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';

import './index.scss';

export const Header = ({ buyerName }) => {
  const history = useHistory();
  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/search');
    }
  };

  return (
    <div className="back-container">
      <div className="back-btn" onClick={goBack}>
        <div className="circle-icon">
          <MdArrowBack size={30} color="#7299EC" />
        </div>
        <span className="back-text">Back</span>
      </div>
      <h1 className="buyer-name">{buyerName}</h1>
    </div>
  );
};
