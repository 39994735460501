import { Card } from 'antd';
import { showErrorToast } from '../../../../../../services/notificationsService';
import { useGetTitleOrganizationClients } from '../../../../../../queries/TitleOrganization';
import { ClientsTable } from '../ClientsTable';

export const Clients = ({ selectedRange, postgresId, appliedFilters }) => {
  const {
    data: clientsData,
    isLoading: isClientsDataLoading,
    isError: isClientsDataError,
  } = useGetTitleOrganizationClients({
    selectedRange,
    postgresId,
    appliedFilters,
  });

  if (isClientsDataError) {
    showErrorToast('Fetching clients failed!');
  }

  return (
    <>
      <Card title={`Clients (${clientsData?.length || 0})`}>
        <ClientsTable data={clientsData} isLoading={isClientsDataLoading} />
      </Card>
    </>
  );
};
