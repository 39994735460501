import { Divider, Popconfirm, Table } from 'antd';
import getSlug from 'speakingurl';
import { Link } from 'react-router-dom';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentHeader from '../../../../components/ContentHeader';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_LIGHT } from '../../../../components/forms/ZorbaButton/constants';
import SearchCard from './Components/SearchCard';
import { capitalizeEveryWord } from '../../../../services/textService';
import { CreateOrganizationModal } from '../../../BuyerSearch/Components/CreateOrganizationModal';
import { CreateEntityModal } from '../../../BuyerSearch/Components/CreateEntityModal';
import { postRequest } from '../../../../services/requestService';
import { setModalState } from '../../../../reducers/Modal/actions';
import {
  MODAL_CREATE_ENTITY,
  MODAL_CREATE_ORGANIZATION,
} from '../../../../reducers/Modal/types';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/notificationsService';

import './index.scss';

export const TitleCompanyTracker = () => {
  const [titleCompanies, setTitleCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(false);
  const dispatch = useDispatch();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');

  const onSearch = async (searchParams) => {
    setLoading(true);
    const res = await postRequest('/data/title-company-search', searchParams);
    setTitleCompanies(res?.data?.titleCompanies || []);
    setLoading(false);
  };

  const openCreateOrganizationModal = () => {
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION, true));
  };
  const openCreateEntityModal = () => {
    dispatch(setModalState(MODAL_CREATE_ENTITY, true));
  };

  const handleEntitySelect = (entityName) => {
    setSelectedEntity(entityName);
    dispatch(setModalState(MODAL_CREATE_ENTITY, true));
  };

  const handleBlackListEntity = async (entityName, organizationId) => {
    try {
      await postRequest('/data/entity/blacklist-entity', {
        entityName,
        organizationId,
      });
      showSuccessToast('Entity successfully blacklisted');
    } catch (error) {
      showErrorToast('Blacklisting entity failed');
    }
  };

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  return (
    <div className="title-company-tracker-page">
      <ContentHeader title="Title Company Tracker" />
      {isZorbaUser && (
        <div className="admin-container">
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_LIGHT}
            onClick={openCreateEntityModal}
          >
            Create entity
          </ZorbaButton>
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_LIGHT}
            onClick={openCreateOrganizationModal}
          >
            Create organization
          </ZorbaButton>
        </div>
      )}
      <SearchCard onSearch={onSearch} loading={loading} />
      <Divider />
      <Table
        loading={loading}
        dataSource={titleCompanies}
        style={{ width: '80%' }}
        pagination={pagination}
        columns={[
          {
            key: 'name',
            title: 'Title Organization Name',
            render: (record) => {
              if (record.organizationId)
                return (
                  <Link
                    className="org-name"
                    target="_blank"
                    to={`/title-company-tracker/${
                      record.organizationId
                    }/${getSlug(record.organizationName)}`}
                  >
                    {record.organizationName}
                  </Link>
                );
              return (
                <div>
                  {record.organizationName
                    ? capitalizeEveryWord(record.organizationName)
                    : ''}
                </div>
              );
            },
          },
          {
            title: 'Title Entity Name',
            render: ({ titleCompanyName, organizationId }) => (
              <div>
                <Link
                  className="org-name"
                  target="_blank"
                  to={`/title-company-tracker/${getSlug(titleCompanyName, {
                    separator: '-',
                  })}`}
                >
                  {titleCompanyName}
                </Link>
                {isZorbaUser && !organizationId && (
                  <FaPlusCircle
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => handleEntitySelect(titleCompanyName)}
                  />
                )}
              </div>
            ),
          },
          ...(isZorbaUser
            ? [
                {
                  title: '',
                  key: 'isBlacklisted',
                  render: ({
                    titleCompanyName,
                    isBlacklisted,
                    organizationId,
                  }) => {
                    if (!isBlacklisted && organizationId) {
                      return (
                        <Popconfirm
                          title={`You are about to blacklist ${titleCompanyName}`}
                          onConfirm={() =>
                            handleBlackListEntity(
                              titleCompanyName,
                              organizationId,
                            )
                          }
                          okText="Confirm"
                          cancelText="Cancel"
                        >
                          <span className="save-on-watchlist">Blacklist</span>
                        </Popconfirm>
                      );
                    }

                    return '';
                  },
                  align: 'center',
                },
              ]
            : []),
        ]}
      />
      <CreateOrganizationModal />
      <CreateEntityModal entity={selectedEntity} />
    </div>
  );
};
