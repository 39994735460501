import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModalState } from '../../../../../../../../reducers/Modal/actions';
import { MODAL_CREATE_WATCHLIST } from '../../../../../../../../reducers/Modal/types';
import {
  ZorbaButton,
  ZorbaInput,
} from '../../../../../../../../components/forms';
import { useCreateWatchlist } from '../../../../../../../../queries/Watchlist';

import './index.scss';

export const CreateWatchListModal = () => {
  const { isCreateWatchlistModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [watchListName, setWatchListName] = useState('');
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_CREATE_WATCHLIST, false));
  };

  const handleSuccess = () => {
    setWatchListName('');
    handleCloseModal();
  };

  const { mutate: createWatchlist, isPending } = useCreateWatchlist(
    handleSuccess,
  );

  const handleCreateWatchList = () => {
    createWatchlist({ listName: watchListName });
  };

  return (
    <Modal
      open={isCreateWatchlistModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Create list"
      className="create-watchlist-modal"
      destroyOnClose
    >
      <div className="input-container">
        <ZorbaInput
          placeholder="List name"
          value={watchListName}
          onChange={(e) => setWatchListName(e.target.value)}
        />
      </div>
      <div className="create-button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleCreateWatchList}
          disabled={!watchListName || isPending}
          loading={isPending}
        >
          Create
        </ZorbaButton>
      </div>
    </Modal>
  );
};
