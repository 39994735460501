import { Popconfirm, Tag, Tooltip } from 'antd';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { GoReport } from '@react-icons/all-files/go/GoReport';
import { BsFillTrashFill } from '@react-icons/all-files/bs/BsFillTrashFill';
import { FaEdit } from '@react-icons/all-files/fa/FaEdit';
import { MdSend } from '@react-icons/all-files/md/MdSend';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PARAM_MARKETING } from '../../../../../../reducers/Modal/types';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';

export const MAP_CONTACT_TYPE_TO_DEPARTMENT = {
  management: 'Management',
  acquisition: 'Acquisitions',
  closing: 'Closings',
};
export const getTableColumns = ({
  dispatch,
  fetchContactEmail,
  hasMoreCredits,
  reportContact,
  isZorbaUser,
  deleteContact,
  handleEditModalOpen,
}) => [
  {
    title: 'First name',
    key: 'firstName',
    render: ({ firstName, isDiscovered }) => (
      <div className={!isDiscovered ? 'blurred-cell' : ''}>{firstName}</div>
    ),
  },
  {
    title: 'Last name',
    key: 'lastName',
    render: ({ lastName, isDiscovered }) => (
      <div className={!isDiscovered ? 'blurred-cell' : ''}>{lastName}</div>
    ),
  },
  {
    title: 'Department',
    key: 'department',
    render: ({ department }) => (
      <Tag color="purple">{MAP_CONTACT_TYPE_TO_DEPARTMENT[department]}</Tag>
    ),
  },
  {
    title: 'Title',
    key: 'title',
    render: ({ title }) => title,
  },
  {
    title: 'Phone number',
    key: 'phoneNumber',
    render: ({ phoneNumber }) => phoneNumber,
  },
  {
    title: 'Email',
    key: 'email',
    render: ({ email, externalContactId, id, isDiscovered }) => {
      if (!email && !isDiscovered) {
        return (
          <Tooltip
            title={
              !hasMoreCredits
                ? 'You are out of credits. Please contact your account manager to upgrade your subscription'
                : ''
            }
          >
            <div>
              <ZorbaButton
                variant={BUTTON_VARIANT_DARK}
                fullWidth={false}
                onClick={() =>
                  fetchContactEmail({
                    contacts: [{ externalContactId, contactId: id }],
                  })
                }
                disabled={!hasMoreCredits}
              >
                Get contact info
              </ZorbaButton>
            </div>
          </Tooltip>
        );
      }
      return email;
    },
  },
  {
    title: '',
    key: 'actions',
    render: (contact) => (
      <div className="contact-actions">
        {contact.isDiscovered && (
          <Tooltip
            title={
              !contact.requestedRefund
                ? 'Report the invalid credit, and our team will review it. If necessary, we will issue a refund.'
                : 'You have already requested a credit refund for this contact. Our team will review your request shortly.'
            }
          >
            <GoReport
              style={{
                cursor: !contact.requestedRefund ? 'pointer' : 'not-allowed',
                fontSize: 23,
                color: !contact.requestedRefund ? 'black' : 'lightgray',
              }}
              onClick={() => {
                if (!contact.requestedRefund) {
                  reportContact({ contactId: contact.id });
                }
              }}
            />
          </Tooltip>
        )}
        {contact.linkedinUrl && (
          <a
            href={contact.linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin
              style={{
                cursor: 'pointer',
                color: '#0e76a8',
                fontSize: 20,
              }}
            />
          </a>
        )}
        {contact.isDiscovered && (
          <MdSend
            style={{ cursor: 'pointer', fontSize: 23 }}
            onClick={() =>
              dispatch(setModalState(MODAL_PARAM_MARKETING, true, { contact }))
            }
          />
        )}
        {contact.isDiscovered && isZorbaUser && (
          <Popconfirm
            title={`Are you sure that you want to delete ${contact.firstName} ${contact.lastName}?`}
            onConfirm={() => deleteContact({ contactId: contact.id })}
            okText="Delete"
            cancelText="Cancel"
          >
            <BsFillTrashFill style={{ cursor: 'pointer', fontSize: 23 }} />
          </Popconfirm>
        )}
        {contact.isDiscovered && isZorbaUser && (
          <FaEdit
            style={{ cursor: 'pointer', fontSize: 23 }}
            onClick={() => handleEditModalOpen(contact)}
          />
        )}
      </div>
    ),
  },
];

export const dataPlaceholder = [
  {
    firstName: 'Lindsey',
    lastName: 'Davis',
    department: 'Management',
    title: 'CEO',
    email: 'l***s@****.com',
  },
  {
    firstName: 'Julian',
    lastName: 'Cheese',
    department: 'Acquisitions',
    title: 'Acquisition Manager',
    email: 'ju***e@****.com',
  },
  {
    firstName: 'Spentressia',
    lastName: 'Fenn',
    department: 'Closings',
    title: 'Realty Purchase Coordinator',
    email: 'sp***n@****.com',
  },
];

export const CONTACT_TYPES = {
  MANAGEMENT: 'management',
  CLOSING: 'closing',
  ACQUISITION: 'acquisition',
};
