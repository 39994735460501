import { BsFillTrashFill } from '@react-icons/all-files/bs/BsFillTrashFill';
import moment from 'moment';
import { addCommas } from '@zorba-shared/core/Services/textService';
import { statesOptions } from '../../../../../../containers/drawer/FiltersDrawer/Components/FiltersBuilder/constants';

export const getTableColumns = ({ handleOpenCancelModal }) => [
  {
    title: 'State',
    key: 'name',
    render: ({ name }) =>
      statesOptions.find((option) => option.value === name)?.label,
  },
  // {
  //   title: 'Active investors',
  //   key: 'activeInvestors',
  //   render: ({ activeInvestors }) => addCommas(activeInvestors),
  // },
  // {
  //   title: 'Home sales in the past 90 days',
  //   key: 'salesLast90Days',
  //   render: ({ salesLast90Days }) => addCommas(salesLast90Days),
  // },
  {
    title: 'Expires At',
    key: 'expiresAt',
    render: ({ expiredAt }) => {
      if (!expiredAt) return null;

      return moment(expiredAt).format('MM/DD/YYYY');
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (item) =>
      !item.disabledAt ? (
        <BsFillTrashFill
          onClick={() => handleOpenCancelModal(item)}
          style={{ cursor: 'pointer' }}
        />
      ) : null,
    align: 'center',
  },
];
