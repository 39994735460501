import React from 'react';

export const renderClonedChildren = (props) =>
  React.Children.map(props.children, (child) => {
    if (
      child.type &&
      child.type.prototype &&
      child.type.prototype.constructor.displayName === 'Input'
    ) {
      return React.cloneElement(child, {});
    }
    return child;
  });
