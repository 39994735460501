import { useEffect, useMemo } from 'react';
import { Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { toQueryString, useSearchParams } from '../../../../../../utils';
import { getDefaultFilters } from '../../../../constants';

import './index.scss';

const prefix = (
  <SearchOutlined
    style={{
      fontSize: 20,
      color: '#8A9099',
    }}
  />
);

const SEARCH_THROTLE_DELAY = 500;
export const ReportFilter = ({ searchCriteria, setSearchCriteria }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  /**
   * Read filters from query params and set it as default values
   */
  useEffect(() => {
    const queryParamsToSet = {};
    searchParams.forEach((value, key) => {
      if (key in getDefaultFilters()) {
        if (Array.isArray(searchCriteria[key])) {
          queryParamsToSet[key] = value.split(',');
        } else {
          queryParamsToSet[key] = value;
        }
      }
    });
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      ...queryParamsToSet,
    }));
    form.setFieldsValue(queryParamsToSet);
  }, []);

  /**
   * Keep watching for changes on search criteria changes and set applied filters in current URL's query params
   */

  useEffect(() => {
    const { createdAtRange, areas, ...rest } = searchCriteria || {};

    const queryParams = toQueryString(rest) || {};
    const currentParamsString = window.location.search;

    if (currentParamsString !== queryParams.toString()) {
      setSearchParams(queryParams);
    }
  }, [searchCriteria]);

  const searchQueryHandler = useMemo(
    () =>
      debounce((event) => {
        const query = event.target.value.trim();
        setSearchCriteria((oldValue) => ({
          ...oldValue,
          query,
        }));
      }, SEARCH_THROTLE_DELAY),
    [],
  );

  return (
    <div className="filter-container">
      <Form layout="horizontal" name="querySearchForm" form={form}>
        <div className="form-field form-field__search">
          <div className="form-field__item">
            <div className="form-field__item__input">
              <Input.Group compact className="search-field">
                <Form.Item name="query">
                  <Input
                    size="large"
                    prefix={prefix}
                    style={{ width: 'calc(100% - 40px)' }}
                    className="search-input"
                    placeholder="Search report by account name or entity name"
                    onChange={searchQueryHandler}
                  />
                </Form.Item>
              </Input.Group>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
