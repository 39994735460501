import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isPossiblePhoneNumber as checkIsPossiblePhoneNumber } from 'libphonenumber-js';
import { disableSpecialCharacters } from './utils';

import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CREATE_CONTACT } from '../../../../../../reducers/Modal/types';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';
import { postRequest } from '../../../../../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { useEditContact } from '../../../../../../queries/DiscoverOrganization';

import './index.scss';

export const CreateContactModal = ({
  organizationId,
  organizationName,
  refetchContacts,
  contactToEdit,
  setContactToEdit,
}) => {
  const { isCreateContactModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({});
  const isPossiblePhoneNumber = checkIsPossiblePhoneNumber(
    params.phoneNumber || '',
    'US',
  );
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  const isPhoneNumberInvalid = Boolean(
    !isPossiblePhoneNumber && !isPhoneNumberFocused && params.phoneNumber,
  );

  useEffect(() => {
    if (contactToEdit) {
      setParams({
        firstName: contactToEdit.firstName,
        lastName: contactToEdit.lastName,
        contactType: contactToEdit.department,
        title: contactToEdit.title,
        email: contactToEdit.email,
        phoneNumber: contactToEdit.phoneNumber,
        linkedinUrl: contactToEdit.linkedinUrl,
      });
    }
  }, [contactToEdit]);

  const handleCloseModal = () => {
    setParams({});
    setContactToEdit(null);
    dispatch(setModalState(MODAL_CREATE_CONTACT, false));
  };

  const handleCreateContact = async () => {
    try {
      setIsLoading(true);
      await postRequest('/data/discover-organization/create-contact', {
        ...params,
        organizationId,
        phoneNumber: params.phoneNumber ? `+1${params.phoneNumber}` : '',
      });
      setIsLoading(false);
      handleCloseModal();
      refetchContacts();
      showSuccessToast('Contact created successfully');
    } catch (error) {
      showErrorToast('Creating contact failed!');
      setIsLoading(false);
    }
  };

  const isCreateDisabled =
    !params.firstName ||
    !params.lastName ||
    !params.email ||
    !params.title ||
    !params.contactType;

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);
  };

  const handlePhoneInputChange = (event) => {
    onChange('phoneNumber', event.target.value.slice(0, 10));
  };

  const { mutate: editContact, isPending: isEditing } = useEditContact(
    handleCloseModal,
  );

  const handleEditContact = () => {
    editContact({
      ...params,
      contactId: contactToEdit.id,
    });
  };

  return (
    <Modal
      open={isCreateContactModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`${
        contactToEdit ? 'Edit' : 'Create new'
      } contact - ${organizationName}`}
      className="create-contact-modal"
      destroyOnClose
    >
      <div className="input-container">
        <ZorbaInput
          label="First name*"
          placeholder="First name"
          onChange={(e) => onChange('firstName', e.target.value)}
          value={params.firstName}
        />
      </div>
      <div className="input-container">
        <ZorbaInput
          label="Last name*"
          placeholder="Last name"
          onChange={(e) => onChange('lastName', e.target.value)}
          value={params.lastName}
        />
      </div>
      <div className="select-container">
        <ZorbaSelect
          label="Department*"
          onChange={(value) => onChange('contactType', value)}
          value={params.contactType}
          options={[
            { label: 'Management', value: 'management' },
            { label: 'Closing', value: 'closing' },
            { label: 'Acquisition', value: 'acquisition' },
          ]}
        />
      </div>
      <div className="input-container">
        <ZorbaInput
          label="Title*"
          placeholder="Title"
          onChange={(e) => onChange('title', e.target.value)}
          value={params.title}
        />
      </div>
      <div className="input-container">
        <ZorbaInput
          label="Email*"
          placeholder="Email"
          onChange={(e) => onChange('email', e.target.value)}
          value={params.email}
        />
      </div>
      <div className="input-container">
        <ZorbaInput
          placeholder="5129565087"
          onChange={handlePhoneInputChange}
          value={params.phoneNumber}
          prefix={contactToEdit ? '' : '+1'}
          onKeyPress={disableSpecialCharacters}
          onBlur={() => setIsPhoneNumberFocused(false)}
          onFocus={() => setIsPhoneNumberFocused(true)}
        />
      </div>
      <div className="input-container">
        <ZorbaInput
          label="Linkedin URL"
          placeholder="https://linkedin.com/in/sarah-lopez-34994b89"
          onChange={(e) => onChange('linkedinUrl', e.target.value)}
          value={params.linkedinUrl}
        />
      </div>
      <div className="create-button-container">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={() => {
            if (contactToEdit) {
              handleEditContact();
            } else {
              handleCreateContact();
            }
          }}
          disabled={
            isCreateDisabled || isLoading || isPhoneNumberInvalid || isEditing
          }
          loading={isLoading || isEditing}
        >
          {contactToEdit ? 'Edit contact' : 'Create contact'}
        </ZorbaButton>
      </div>
    </Modal>
  );
};
