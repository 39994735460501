import { Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';

import { setModalState } from '../../../../reducers/Modal/actions';
import { MODAL_CREATE_ORGANIZATION } from '../../../../reducers/Modal/types';
import {
  ZorbaInput,
  ZorbaButton,
  ZorbaSelect,
} from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { postRequest } from '../../../../services/requestService';

import './index.scss';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/notificationsService';

const SEARCH_THROTLE_DELAY = 500;

export const CreateOrganizationModal = () => {
  const { isCreateOrganizationModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState('');
  const [similarOrganizations, setSimilarOrganizations] = useState([]);
  const [selectedCompanyType, setSelectedCompanyType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const handleCloseModal = () => {
    setOrganizationName('');
    setSelectedCompanyType('');
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION, false));
  };

  const findSimilarOrganizations = async (name) => {
    try {
      setIsLoading(true);
      const { data } = await postRequest(
        '/data/organization/get-similar-organizations',
        {
          organizationName: name,
        },
      );
      setIsInitialFetchDone(true);
      setSimilarOrganizations(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSimilarOrganizations([]);
    }
  };

  const debouncedFindSimilarOrganizations = useMemo(
    () =>
      debounce((name) => findSimilarOrganizations(name), SEARCH_THROTLE_DELAY),
    [],
  );

  useEffect(() => {
    if (organizationName.length > 2) {
      debouncedFindSimilarOrganizations(organizationName);
    } else {
      setSimilarOrganizations([]);
    }
    return () => {
      debouncedFindSimilarOrganizations.cancel();
    };
  }, [organizationName, debouncedFindSimilarOrganizations]);

  const handleInputChange = (event) => {
    const query = event.target.value;
    setOrganizationName(query);
  };

  const handleOpenNewTab = (organizationId, name) => {
    window.open(`/top-buyers/${organizationId}/${name}`, '_blank');
  };

  const handleCreateOrganization = async () => {
    try {
      setIsLoading(true);
      const response = await postRequest(
        '/data/organization/create-organization',
        {
          organizationName,
          organizationType: selectedCompanyType,
        },
      );
      setIsLoading(false);
      handleCloseModal();

      if (response && response.data?.organizationId) {
        const newUrl = `/top-buyers/${response.data.organizationId}/${organizationName}`;
        window.open(newUrl, '_blank');
      }
      showSuccessToast('Organization created successfully');
    } catch (error) {
      showErrorToast('Creating organization failed!');
      setIsLoading(false);
    }
  };

  const handleCompanyTypeChange = (value) => {
    setSelectedCompanyType(value);
  };

  return (
    <Modal
      open={isCreateOrganizationModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Create new organization"
      className="create-organization-modal"
      destroyOnClose
    >
      <div className="input-container">
        <ZorbaInput
          placeholder="Organization name"
          onChange={handleInputChange}
          value={organizationName}
        />
      </div>
      {similarOrganizations.length > 0 && (
        <div className="organizations-container">
          <h4>Did you mean?</h4>
          {similarOrganizations.map((organization) => (
            <div
              className="organization-container"
              onClick={() =>
                handleOpenNewTab(organization.id, organization.name)
              }
            >
              <span>{organization.name}</span>
              <FaChevronRight size={20} />
            </div>
          ))}
        </div>
      )}
      <div className="company-type-container">
        <ZorbaSelect
          placeholder="Organization type"
          onChange={handleCompanyTypeChange}
          options={[
            { label: 'Buyer', value: 'buyer' },
            { label: 'Lender', value: 'lender' },
            { label: 'Builder', value: 'builder' },
            { label: 'Bank', value: 'bank' },
            { label: 'Title company', value: 'title_company' },
            { label: 'Government entity', value: 'government_entity' },
          ]}
          value={selectedCompanyType}
        />
      </div>
      <div className="create-button-container">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={handleCreateOrganization}
          disabled={
            organizationName.length <= 2 || isLoading || !isInitialFetchDone
          }
          loading={isLoading}
        >
          Create
        </ZorbaButton>
      </div>
    </Modal>
  );
};
