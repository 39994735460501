import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const fetchContacts = async () => {
  const response = await postRequest('/data/contacts/get-contacts');

  const { data } = response.data;

  return data;
};
export const useFetchContacts = () =>
  useQuery({
    queryKey: ['allContacts'],
    queryFn: () => fetchContacts(),
  });
