import React from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  ResponsiveContainer,
} from 'recharts';
import { Spinner } from '../../../../../../components/layout';

export const AcquisitionsChart = ({
  isLoading,
  accountStatsData = [],
  dataKey,
}) =>
  isLoading ? (
    <Spinner />
  ) : (
    <ResponsiveContainer width="100%" height="85%">
      <BarChart data={accountStatsData}>
        <XAxis dataKey="name" />
        <YAxis type="number" domain={[0, 'dataMax']} />
        <Tooltip cursor={false} />
        <Bar dataKey={dataKey} fill="#7388D9" barSize="55" />
      </BarChart>
    </ResponsiveContainer>
  );
