import * as Sentry from '@sentry/react';
import { isDev } from './clientService';
import { postRequest } from './requestService';

export const initLoggers = () => {
  if (isDev()) return;

  Sentry.init({
    dsn:
      'https://5063ad5ac669dc3942f415c079282a64@o497229.ingest.us.sentry.io/4507151844769792',
    beforeSend(event) {
      return event;
    },
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https:api.getzorba.com/'],
      }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    whitelistUrls: ['getzorba.com', 'www.getzorba.com', 'data.getzorba.com'],
    ignoreErrors: [
      /ResizeObserver/,
      /Cannot read property 'querySelectorAll' of null/,
      /Cannot read property 'focus' of null/,
      /InvalidStateError: The object is in an invalid state./,
      /InvalidStateError: The object/,
      /evaluating 'Ye.current.focus'/,
      /Error: Could not load "util"/,
      /e[n].toUpperCase is not a function/,
      /Object.sendBeacon(frame-modern)/,
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });
};

export const logError = async (e) => {
  try {
    if (isDev()) return;

    await postRequest('/health/log-client-error', { message: e?.stack || e });
  } catch (error) {
    console.log(error);
  }
};
