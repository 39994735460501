import { Tag } from 'antd';
import { v4 as uuid } from 'uuid';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../../../../../../services/clientService';
import {
  BUTTON_VARIANT_LIGHT,
  BUTTON_VARIANT_LINK,
} from '../../../../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import {
  propertyTypeOptions,
  statesOptions,
} from '../TransactionsFiltersDrawer/Components/FiltersBuilder/constants';
import {
  deleteAppliedFilter,
  removePropertyTypeFromAppliedFilters,
  removeStateFromAppliedFilters,
  resetAppliedFilters,
} from '../../../../../../reducers/Transactions/actions';
import { setDrawerState } from '../../../../../../reducers/Drawer/actions';
import { TRANSACTIONS_FILTERS_DRAWER } from '../../../../../../reducers/Drawer/types';
import { TransactionsFiltersDrawer } from '../TransactionsFiltersDrawer';

import './index.scss';

export const TransactionsViewPanel = ({ children }) => {
  const dispatch = useDispatch();
  const { appliedFiltersTransactions } = useSelector(
    ({ TransactionsReducer }) => TransactionsReducer,
  );
  const handleClearState = (state) => {
    dispatch(removeStateFromAppliedFilters(state));
  };

  const handleClearPropertyType = (propertyType) => {
    dispatch(removePropertyTypeFromAppliedFilters(propertyType));
  };

  const handleClearAllFilters = () => {
    dispatch(resetAppliedFilters());
  };

  const handleClearPurchasePrice = () => {
    dispatch(deleteAppliedFilter('purchasePrice'));
  };

  const handleClearLocation = () => {
    dispatch(deleteAppliedFilter('location'));
  };

  const openFiltersDrawer = () => {
    dispatch(setDrawerState(TRANSACTIONS_FILTERS_DRAWER, true));
  };

  return (
    <>
      <div className="transactions-applied-filters-container">
        <div className="tags-container">
          {(
            appliedFiltersTransactions?.state?.isAnyOf ||
            appliedFiltersTransactions?.state?.isNoneOf ||
            []
          ).map((el) => (
            <Tag className="tag-close" key={uuid()}>
              {statesOptions.find((option) => option.value === el)?.label}
              <IoMdClose onClick={() => handleClearState(el)} />
            </Tag>
          ))}
          {(
            appliedFiltersTransactions?.propertyType?.isAnyOf ||
            appliedFiltersTransactions?.propertyType?.isNoneOf ||
            []
          ).map((el) => (
            <Tag className="tag-close" key={uuid()}>
              {propertyTypeOptions.find((option) => option.value === el)?.label}
              <IoMdClose onClick={() => handleClearPropertyType(el)} />
            </Tag>
          ))}
          {appliedFiltersTransactions?.purchasePrice?.length && (
            <Tag className="tag-close" key={uuid()}>
              Purchase Price:{' '}
              {`${getPriceText(appliedFiltersTransactions.purchasePrice[0])} ${
                appliedFiltersTransactions.purchasePrice[1] ? ' - ' : ''
              } ${getPriceText(appliedFiltersTransactions.purchasePrice[1])}`}
              <IoMdClose onClick={() => handleClearPurchasePrice()} />
            </Tag>
          )}
          {appliedFiltersTransactions?.location?.length && (
            <Tag className="tag-close" key={uuid()}>
              {appliedFiltersTransactions?.location
                .map((loc) => loc.formattedAddress)
                .join(' | ')}
              <IoMdClose onClick={() => handleClearLocation()} />
            </Tag>
          )}
        </div>
        {Object.keys(appliedFiltersTransactions).length > 0 && (
          <ZorbaButton
            variant={BUTTON_VARIANT_LINK}
            onClick={handleClearAllFilters}
            fullWidth={isMobile()}
          >
            Clear all
          </ZorbaButton>
        )}
        <ZorbaButton
          variant={BUTTON_VARIANT_LIGHT}
          onClick={openFiltersDrawer}
          fullWidth={isMobile()}
          icon={<BiFilterAlt />}
        >
          {Object.keys(appliedFiltersTransactions).length
            ? `Applied filters (${
                Object.keys(appliedFiltersTransactions).length
              })`
            : 'Filters'}
        </ZorbaButton>
        {children}
      </div>
      <TransactionsFiltersDrawer />
    </>
  );
};
