import { Table } from 'antd';
import { getTableColumns } from './constants';
import { isMobile } from '../../../../../../services/clientService';

export const AcquiredPropertiesTable = ({
  isLoading,
  setFilteredCount,
  data,
  isMultiFamilySelected,
  isVacantSelected,
}) => {
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  return (
    <Table
      rowKey="address"
      loading={isLoading}
      columns={getTableColumns({ isMultiFamilySelected, isVacantSelected })}
      dataSource={data || []}
      scroll={tableScroll}
      onChange={(pagination, filters, sorter, extra) => {
        const { currentDataSource } = extra; // This contains the filtered data
        const filteredCount = currentDataSource.length;
        setFilteredCount(filteredCount);
      }}
    />
  );
};
