import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetTopBuyersByKnownAccount } from '../../../../../../queries/TopBuyersReport';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_ADD_ACCOUNT_TO_WATCHLIST } from '../../../../../../reducers/Modal/types';
import { useGetWatchLists } from '../../../../../../queries/Watchlist';
import { postRequest } from '../../../../../../services/requestService';
import {
  useGetMappingRequests,
  useRequestMapping,
} from '../../../../../../queries/MappingRequests';
import { setSorting } from '../../../../../../reducers/TopBuyersReport/actions';

export const TopBuyersByKnownAccountTable = ({
  selectedMonthRange,
  setSelectedAccountId,
  searchCriteria,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const { sorting } = useSelector(({ TopBuyersReport }) => TopBuyersReport);
  const handleTableChange = (pagination, filters, sorter) => {
    const { columnKey, order } = sorter;

    dispatch(setSorting({ columnKey, order }));
  };

  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');
  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };
  const isTransactionTypeSelected = appliedFilters?.transactionType;

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  const {
    data: topBuyersByKnownAccountData,
    isLoading,
    isError,
  } = useGetTopBuyersByKnownAccount({
    selectedMonthRange,
    appliedFilters: { ...appliedFilters, ...searchCriteria },
    isMounted,
  });

  const {
    data: mappingRequests,
    isLoading: isFetchingMappingRequests,
  } = useGetMappingRequests();

  const { data: lists } = useGetWatchLists();

  if (isError) {
    showErrorToast('Fetching report failed!');
  }

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  const openAddAccountModal = (selectedAccountId) => {
    dispatch(setModalState(MODAL_ADD_ACCOUNT_TO_WATCHLIST, true));
    setSelectedAccountId(selectedAccountId);
  };

  const navigateToWatchlist = () => {
    history.push('/');
  };
  const {
    mutate: requestMapping,
    isPending: isRequestingMapping,
  } = useRequestMapping();

  const handleBlackListName = async (entityName) => {
    try {
      await postRequest('/data/entity/blacklist-name', {
        entityName,
      });
      showSuccessToast('Entity successfully blacklisted');
    } catch (error) {
      showErrorToast('Blacklisting entity failed');
    }
  };

  const requestedEntities = (mappingRequests || [])
    .filter((request) => request.kind === 'entity')
    .map((request) => request.entityName);

  return (
    <Table
      rowKey="organizationName"
      loading={isLoading}
      columns={getTableColumns({
        openAddAccountModal,
        lists,
        navigateToWatchlist,
        isTransactionTypeSelected,
        handleRequestMapping: requestMapping,
        requestedEntities,
        isRequestingMapping,
        isZorbaUser,
        handleBlackListName,
        isFetchingMappingRequests,
        sorting: {
          columnKey: sorting.columnKey,
          order: sorting.order,
        },
      })}
      dataSource={topBuyersByKnownAccountData}
      pagination={pagination}
      scroll={tableScroll}
      onChange={handleTableChange}
    />
  );
};
