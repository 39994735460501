import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import { Contacts } from './Components/Contacts';
import ContentHeader from '../../components/ContentHeader';

export default () => {
  useDocumentTitle(`Contacts`);
  useEffect(() => {
    reportScreen('contacts page');
  }, []);

  return (
    <Page>
      <ContentHeader title="Contacts" />
      <Contacts />
    </Page>
  );
};
