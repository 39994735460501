import { Card, Input } from 'antd';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { useEffect, useState } from 'react';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';

import './index.scss';

export default ({ loading, query, onSearch }) => {
  const [searchParams, setSearchParams] = useState({});

  const onChange = (key, value) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    });
  };
  const onSearchClick = () => {
    onSearch(searchParams);
  };

  useEffect(() => {
    if (query != null) {
      onChange('name', query);
      onSearch({ name: query });
    }
  }, [query]);

  return (
    <Card className="search-card">
      <div className="row">
        <Input
          value={searchParams.name}
          placeholder="Amherst Holdings"
          onChange={(e) => onChange('name', e.target.value)}
        />
        <ZorbaButton
          loading={loading}
          onClick={() => onSearchClick()}
          variant={BUTTON_VARIANT_DARK}
          disabled={!searchParams.name?.length}
        >
          {loading ? null : <FaSearch />}
        </ZorbaButton>
      </div>
    </Card>
  );
};
