import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import { Link } from 'react-router-dom';
import { Popconfirm, Tag } from 'antd';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FaBuilding } from '@react-icons/all-files/fa/FaBuilding';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';

export const COMPANY_TYPES_MAP = {
  buyer: 'Buyer',
  lender: 'Lender',
  builder: 'Builder',
  bank: 'Bank',
  title_company: 'Title company',
  blacklisted: 'Blacklisted',
  government_entity: 'Government entity',
};

export const COMPANY_TYPES_COLORS = {
  buyer: 'geekblue',
  lender: 'gold',
  builder: 'volcano',
  bank: 'green',
  title_company: 'purple',
  government_entity: 'cyan',
};

export const getTableColumns = ({
  handleEntitySelect,
  isZorbaUser,
  isTransactionTypeSelected,
  handleBlackListName,
}) => [
  {
    title: 'Entity name',
    key: 'buyerName',
    render: ({ buyerName, companyType, organizationName }) => (
      <div>
        <div
          style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}
        >
          <Link className="buyer-name" to={`/top-buyers/${buyerName}`}>
            {buyerName}
          </Link>
          {companyType && (
            <Tag
              color={COMPANY_TYPES_COLORS[companyType]}
              style={{ marginLeft: '10px' }}
            >
              {COMPANY_TYPES_MAP[companyType]}
            </Tag>
          )}
          {isZorbaUser && !organizationName && (
            <>
              <FaPlusCircle
                style={{ cursor: 'pointer' }}
                onClick={() => handleEntitySelect(buyerName)}
              />
              <Popconfirm
                title={`You are about to blacklist ${buyerName} from both reports.`}
                onConfirm={() => handleBlackListName(buyerName)}
                okText="Confirm"
                cancelText="Cancel"
              >
                <FaTrash style={{ cursor: 'pointer' }} />
              </Popconfirm>
            </>
          )}
        </div>
      </div>
    ),
  },
  ...(isZorbaUser
    ? [
        {
          title: '',
          key: 'buyerSearch',
          render: ({ buyerName }) => (
            <Link
              to={`/search?query=${buyerName}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black' }}
            >
              <FaSearch />
            </Link>
          ),
        },
      ]
    : []),
  ...(isZorbaUser
    ? [
        {
          title: '',
          key: 'openCorporates',
          render: ({ buyerName }) => (
            <a
              href={`https://opencorporates.com/companies?utf8=%E2%9C%93&utf8=%E2%9C%93&q=${buyerName}&jurisdiction_code=&type=companies`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black' }}
            >
              <FaBuilding />
            </a>
          ),
        },
      ]
    : []),
  {
    title: 'Account',
    key: 'organizationName',
    render: ({ organizationId, organizationName }) => (
      <Link
        className="buyer-name"
        to={`/top-buyers/${organizationId}/${organizationName}`}
      >
        {organizationName}
      </Link>
    ),
  },
  {
    title: 'Total Acquired Properties',
    key: 'totalProperties',
    render: ({ totalAcquiredProperties }) => totalAcquiredProperties,
  },
  ...(!isTransactionTypeSelected
    ? [
        {
          title: 'Total Sold Properties',
          key: 'totalSoldProperties',
          render: ({ totalSoldProperties }) => totalSoldProperties,
        },
      ]
    : []),
  {
    title: 'Last transaction',
    key: 'lastTransactionDate',
    render: ({ lastTransactionDate }) => getFormattedTime(lastTransactionDate),
  },
];
