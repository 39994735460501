export const getTableColumns = () => [
  {
    title: 'Entity name',
    key: 'lenderName',
    render: ({ lenderName }) => lenderName,
  },
  {
    title: 'Total Acquired Properties',
    key: 'totalAcquiredProperties',
    render: ({ totalAcquiredProperties }) => totalAcquiredProperties,
  },
];
