import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import { AccountDetails } from './Components/AccountDetails';

export default () => {
  const { entityName } = useParams();
  useDocumentTitle(`${entityName}`);
  useEffect(() => {
    reportScreen('entity account page');
  }, []);

  return (
    <Page>
      <AccountDetails />
    </Page>
  );
};
