import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const getMappingRequests = async () => {
  const response = await postRequest('/data/entity/get-mapping-requests');

  const { data } = response.data;

  return data;
};

export const useGetMappingRequests = () =>
  useQuery({
    queryKey: ['mappingRequests'],
    queryFn: () => getMappingRequests(),
  });

export const requestMapping = async (entityName) => {
  await postRequest('/data/entity/request-mapping', { entityName });
};

export const useRequestMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requestMapping,
    onSuccess: () => {
      showSuccessToast('Entity mapping requested successfully!');
      queryClient.invalidateQueries({
        queryKey: ['mappingRequests'],
      });
    },
    onError: () => {
      showErrorToast('Requesting entity mapping failed!');
    },
  });
};

export const requestContactMapping = async (params) => {
  await postRequest('/data/entity/request-contacts', params);
};

export const useRequestContactMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requestContactMapping,
    onSuccess: () => {
      showSuccessToast('Contacts requested successfully!');
      queryClient.invalidateQueries({
        queryKey: ['mappingRequests'],
      });
    },
    onError: () => {
      showErrorToast('Requesting contacts failed!');
    },
  });
};

export const requestOrganizationContactMapping = async (params) => {
  await postRequest('/data/organization/request-contacts', params);
};

export const useRequestContactOrganizationMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requestOrganizationContactMapping,
    onSuccess: () => {
      showSuccessToast('Contacts requested successfully!');
      queryClient.invalidateQueries({
        queryKey: ['mappingRequests'],
      });
    },
    onError: () => {
      showErrorToast('Requesting contacts failed!');
    },
  });
};
