import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Page } from '../../components/layout';
import { TitleOrganization } from './Components/TitleOrganization';

export default () => {
  useDocumentTitle('Title Organization Page');

  return (
    <Page>
      <TitleOrganization />
    </Page>
  );
};
