import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import {
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';
import {
  COMPANY_TYPES_COLORS,
  COMPANY_TYPES_MAP,
} from '../../../../../TopBuyersReportPage/Components/TopBuyersReport/Components/TopBuyersByEntityNameTable/constants';

export const getTableColumns = () => [
  {
    title: 'Sale date',
    key: 'saleDate',
    render: ({ saleDate }) => getFormattedTime(saleDate),
    width: 200,
  },
  {
    title: 'Account',
    key: 'organizationName',
    render: ({ organizationId, organizationName, companyType }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link
          className="buyer-name"
          to={`/top-buyers/${organizationId}/${organizationName}`}
        >
          {organizationName}
        </Link>
        {companyType && (
          <Tag
            color={COMPANY_TYPES_COLORS[companyType]}
            style={{ marginLeft: '10px' }}
          >
            {COMPANY_TYPES_MAP[companyType]}
          </Tag>
        )}
      </div>
    ),
  },
  {
    title: 'Buyer',
    key: 'buyer',
    render: ({ buyer }) => buyer,
  },
  {
    title: 'Seller',
    key: 'seller',
    render: ({ seller }) => seller,
  },
  {
    title: 'Address',
    key: 'address',
    render: ({ address }) => address,
  },
  {
    title: 'City',
    key: 'city',
    render: ({ city }) => city,
  },
  {
    title: 'State',
    key: 'state',
    render: ({ state }) => state,
  },
  {
    title: 'Zip',
    key: 'zip',
    render: ({ zip }) => zip,
  },
  {
    title: 'Number of units',
    key: 'numberOfUnits',
    render: ({ numberOfUnits }) => numberOfUnits,
  },
  {
    title: 'Purchase price',
    key: 'purchasePrice',
    render: ({ purchasePrice }) => getPriceText(purchasePrice),
  },
  {
    title: 'AVM',
    key: 'avm',
    render: ({ avm }) => getPriceText(avm),
  },
];
