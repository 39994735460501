import { Liquid } from 'liquidjs';
import { parse } from 'json2csv';

export const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};
export const handleDownload = (
  customTemplates,
  userDetails,
  rowsToDownload,
) => {
  const textTemplates = customTemplates.map((customTemplate) => ({
    name: customTemplate.templateName,
    template: customTemplate.template,
  }));

  const fillTemplateWithParams = async (template, contact) => {
    const params = {
      user_name: userDetails.name || '',
      user_first_name: userDetails.firstName || '',
      user_last_name: userDetails.lastName || '',
      contact_first_name: contact?.firstName || '',
      contact_last_name: contact?.lastName || '',
      contact_organization_name:
        contact.acquiredProperties[0]?.organizationName || '',
      acquiredProperties_total: contact.acquiredProperties?.length || 0,
      acquisition_1: {
        address: contact.acquiredProperties[0]?.address || '',
        state: contact.acquiredProperties[0]?.state || '',
        title_company_name:
          contact.acquiredProperties[0]?.titleCompanyName || '',
        lender_name: contact.acquiredProperties[0]?.lenderName || '',
      },
      acquisition_2: {
        address: contact.acquiredProperties[1]?.address || '',
        state: contact.acquiredProperties[1]?.state || '',
        title_company_name:
          contact.acquiredProperties[1]?.titleCompanyName || '',
        lender_name: contact.acquiredProperties[1]?.lenderName || '',
      },
    };

    const engine = new Liquid();
    return engine.parseAndRender(template, params);
  };

  const downloadPayload = Promise.all(
    rowsToDownload.map(async (contact) => {
      const contactData = {
        'First Name': contact.firstName,
        'Last Name': contact.lastName,
        Title: contact.title,
        Department: contact.department,
        Email: contact.email,
        'Phone number': contact.phoneNumber,
        'Linkedin URL': contact.linkedinUrl,
      };

      await Promise.all(
        textTemplates.map(async ({ name, template }) => {
          contactData[name.trim()] = await fillTemplateWithParams(
            template,
            contact,
          );
        }),
      );

      return contactData;
    }),
  );

  downloadPayload.then((payload) => {
    if (payload.length) {
      const csv = parse(payload);
      const getFormattedDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options).replace(/\//g, '_');
      };

      const currentDate = new Date();
      const formattedDate = getFormattedDate(currentDate);

      downloadCSV(csv, `contacts_${formattedDate}.csv`);
    }
  });
};
