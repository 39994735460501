const getParamName = (flow) => `is${flow}ModalOpen`;
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const MODAL_PARAM_ONBOARDING = getParamName('OnboardingModal');
export const MODAL_PARAM_PHONE_UPDATE = getParamName('PhoneUpdate');

export const MODAL_PARAM_EMAIL_VERIFICATION = getParamName('EmailVerification');
export const MODAL_PARAM_PHONE_VERIFICATION = getParamName('PhoneVerification');
export const MODAL_PARAM_MARKETING = getParamName('Marketing');

export const ALLOWED_MODALS_TO_OPEN_BY_QUERY = [];

export const MODAL_INVITE_MEMBER = getParamName('InviteMember');
export const MODAL_UNSAVED_BUYBOX_CHANGES = getParamName(
  'UnsavedBuyBoxChanges',
);

export const MODAL_ACCEPT_TERMS_AND_CONDITIONS = getParamName(
  'AcceptTermsAndConditions',
);

export const MODAL_CREATE_WATCHLIST = getParamName('CreateWatchlist');

export const MODAL_ADD_ACCOUNT_TO_WATCHLIST = getParamName(
  'AddAccountToWatchlist',
);

export const MODAL_CONNECT_STATE = getParamName('ConnectState');

export const MODAL_CANCEL_STATE = getParamName('CancelState');

export const MODAL_CREATE_ORGANIZATION = getParamName('CreateOrganization');
export const MODAL_CREATE_ENTITY = getParamName('CreateEntity');

export const MODAL_CREATE_CONTACT = getParamName('CreateContact');

export const MODAL_CREATE_ORGANIZATION_REGEX = getParamName(
  'CreateOrganizationRegex',
);
