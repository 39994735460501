import * as types from './types';

export const setSorting = (sorting) => (dispatch) => {
  dispatch({
    type: types.SET_SORTING,
    payload: {
      sorting,
    },
  });
};
