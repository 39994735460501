import { Liquid } from 'liquidjs';
import { parse } from 'json2csv';
import getSlug from 'speakingurl';

const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};

export const handleDownload = ({
  customTemplates,
  userDetails,
  accountTransactionsData,
  organizationName,
  contactsToDownload,
}) => {
  const textTemplates = customTemplates.map((customTemplate) => ({
    name: customTemplate.templateName,
    template: customTemplate.template,
  }));

  const acquiredProperties = accountTransactionsData?.acquiredProperties || [];

  const fillTemplateWithParams = async (template, contact) => {
    const params = {
      user_name: userDetails.name || '',
      user_first_name: userDetails.firstName || '',
      user_last_name: userDetails.lastName || '',
      contact_first_name: contact?.firstName || '',
      contact_last_name: contact?.lastName || '',
      contact_organization_name: organizationName,
      acquiredProperties_total: acquiredProperties?.length || 0,
      acquisition_1: {
        address: acquiredProperties[0]?.address || '',
        // city: acquiredProperties[0]?.city || '',
        state: acquiredProperties[0]?.state || '',
        // zip: acquiredProperties[0]?.zip || '',
        title_company_name: acquiredProperties[0]?.titleCompanyName || '',
        lender_name: acquiredProperties[0]?.lenderName || '',
      },
      acquisition_2: {
        address: acquiredProperties[1]?.address || '',
        // city: acquiredProperties[1]?.city || '',
        state: acquiredProperties[1]?.state || '',
        // zip: acquiredProperties[1]?.zip || '',
        title_company_name: acquiredProperties[1]?.titleCompanyName || '',
        lender_name: acquiredProperties[1]?.lenderName || '',
      },
    };

    const engine = new Liquid();
    return engine.parseAndRender(template, params);
  };

  const downloadPayload = Promise.all(
    contactsToDownload.map(async (contact) => {
      const contactData = {
        'First Name': contact.firstName,
        'Last Name': contact.lastName,
        Title: contact.title,
        Department: contact.department,
        Email: contact.email,
        'Phone number': contact.phoneNumber,
        'Linkedin URL': contact.linkedinUrl,
      };

      await Promise.all(
        textTemplates.map(async ({ name, template }) => {
          contactData[name.trim()] = await fillTemplateWithParams(
            template,
            contact,
          );
        }),
      );

      return contactData;
    }),
  );

  downloadPayload.then((payload) => {
    if (payload.length) {
      const csv = parse(payload);
      downloadCSV(
        csv,
        `contacts_${getSlug(organizationName, {
          separator: '_',
        })}.csv`,
      );
    }
  });
};
