import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Radio, Card } from 'antd';
import { useFetchContacts } from '../../../../queries/Contacts';
import { ContactsTable } from './Components/ContactsTable';
import { showErrorToast } from '../../../../services/notificationsService';
import { MarketingModal } from '../../../AccountPage/Components/AccountDetails/Components/MarketingModal';
import { CONTACT_TYPES } from '../../../AccountPage/Components/AccountDetails/Components/ContactsTable/constants';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../components/forms/ZorbaButton/constants';
import { useGetCustomTemplates } from '../../../../queries/CustomTemplates';
import { handleDownload } from './service';

import './index.scss';

export const Contacts = () => {
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [
    selectedContactAcquiredProperties,
    setSelectedContactAcquiredProperties,
  ] = useState([]);
  const [department, setDepartment] = useState('');
  const [selectedOrganizationName, setSelectedOrganizationName] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    data: contactsData,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useFetchContacts();
  const { data: customTemplates = [] } = useGetCustomTemplates();

  if (isError) {
    showErrorToast('Fetching contcts failed!');
  }

  const filteredContacts = (contactsData || []).filter(
    (contact) => !department || contact.department === department,
  );

  const getCardTitle = () => (
    <div className="buttons-container">
      <ZorbaButton
        fullWidth={false}
        variant={BUTTON_VARIANT_LINK}
        disabled={isLoading || isRefetching || !selectedRows.length}
        onClick={() =>
          handleDownload(customTemplates, userDetails, selectedRows)
        }
      >
        {`Download as CSV (${selectedRows.length})`}
      </ZorbaButton>
    </div>
  );

  return (
    <div className="contacts-page">
      <Card title={getCardTitle()}>
        <Radio.Group
          options={[
            { label: 'All', value: '' },
            { label: 'Management', value: CONTACT_TYPES.MANAGEMENT },
            { label: 'Acquisitions', value: CONTACT_TYPES.ACQUISITION },
            { label: 'Closings', value: CONTACT_TYPES.CLOSING },
          ]}
          onChange={(e) => {
            setDepartment(e.target.value);
          }}
          value={department}
          optionType="button"
          buttonStyle="solid"
        />
        <br /> <br />
        <ContactsTable
          data={filteredContacts}
          isLoading={isLoading || isRefetching}
          refetch={refetch}
          setSelectedContactAcquiredProperties={
            setSelectedContactAcquiredProperties
          }
          setSelectedOrganizationName={setSelectedOrganizationName}
          setSelectedRows={setSelectedRows}
        />
      </Card>
      <MarketingModal
        acquiredProperties={selectedContactAcquiredProperties}
        organizationName={selectedOrganizationName}
      />
    </div>
  );
};
