import { Card } from 'antd';

import './index.scss';

export const EmptyState = ({ propertyType, selectedMonthRange }) => (
  <Card className="empty-state">
    <img src="/img/upgrade-empty-state.png" />
    <div className="title">No transactions found</div>
    <p>
      {`We couldn’t find a single transaction in the past ${selectedMonthRange}
      months where this company purchased ${propertyType}`}
    </p>
    <br />
    <br />
  </Card>
);
