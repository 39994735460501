import ReactDOM from 'react-dom';
// import { ErrorBoundary } from '@highlight-run/react';
import App from './App';
// import { isDev } from './services/clientService';

const rootElement = document.getElementById('root');
// const isDevMode = isDev();

// It returns the ErrorBoundary component if the app is not in development mode
function errorBoundaryWrapper() {
  // COMMENTED OUT DURING HIGHLIGHT MIGRATION
  // return !isDevMode ? (
  //   <ErrorBoundary showDialog>
  //     <App />
  //   </ErrorBoundary>
  // ) : (
  //   <App />
  // );

  return <App />;
}

ReactDOM.render(errorBoundaryWrapper(), rootElement);
if (module.hot) {
  module.hot.accept('./App', () => {
    // const NextApp = require('./App').default;
    ReactDOM.render(errorBoundaryWrapper(), document.getElementById('root'));
  });
}
