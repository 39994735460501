import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setAppliedFilters } from '../../../../../../../../../reducers/Transactions/actions';
import { ZorbaButton } from '../../../../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../../../../components/forms/ZorbaButton/constants';
import MultiPlacesAutoComplete from '../../../../../../../../../components/forms/MultiPlacesAutoComplete';

export const LocationBuilder = ({ onApplyFilter }) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const { appliedFiltersTransactions: appliedFilters } = useSelector(
    ({ TransactionsReducer }) => TransactionsReducer,
  );
  const defaultSelectValue = appliedFilters.location || [];

  const [location, setLocation] = useState(defaultSelectValue);
  const handleChange = (newValue) => {
    setLocation(newValue);
  };

  const isApplyButtonDisabled = !location.length;

  const handleApply = () => {
    dispatch(setAppliedFilters('location', location));
    onApplyFilter();
  };

  const hasNationwideAccess =
    userDetails?.defaultOrganization?.dataSubscription?.hasNationwideAccess;

  const activatedStates = userDetails?.defaultOrganization?.dataSubscription?.activatedStates.map(
    (state) => state.name,
  );

  return (
    <div className="builder-container">
      <h3>Location</h3>
      <div className="options-container">
        <MultiPlacesAutoComplete
          showOnlyLocalitiesAndZipCodes
          onChange={handleChange}
          value={location}
          hasNationwideAccess={hasNationwideAccess}
          activatedStates={activatedStates}
        />
      </div>
      <ZorbaButton
        variant={BUTTON_VARIANT_DARK}
        disabled={isApplyButtonDisabled}
        onClick={handleApply}
        fullWidth={false}
      >
        Apply
      </ZorbaButton>
    </div>
  );
};
