import { Link } from 'react-router-dom';

export const getTableColumns = () => [
  {
    title: 'Organization',
    key: 'organizationName',
    render: ({ organizationName, organizationId }) => (
      <div style={{ width: '100%', height: '100%', filter: 'blur(4px)' }}>
        <Link
          className="buyer-name"
          to={`/top-buyers/${organizationId}/${organizationName}`}
        >
          {organizationName}
        </Link>
      </div>
    ),
  },
  {
    title: 'Entity',
    key: 'entityName',
    render: ({ entityName }) => entityName,
  },
  {
    title: 'Number of transactions',
    key: 'numOfTransactions',
    render: ({ numOfTransactions }) => numOfTransactions,
  },
  {
    title: 'Operating states',
    key: 'operatingStates',
    render: ({ uniqueStates = [] }) => uniqueStates.join(', '),
  },
];
