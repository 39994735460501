import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { useGetAccountDetails } from '../../../../queries/Account';
import { showErrorToast } from '../../../../services/notificationsService';
import { getDefaultRangePickerValue } from '../../../../utils';
import { ViewPanel } from '../../../../containers/view/ViewPanel';
import { ZorbaDateRangePicker } from '../../../../components/forms/ZorbaDateRangePicker';
import { ContactsTable } from './Components/ContactsTable';
import { BuyBoxCard } from './Components/BuyboxCard';
import { AccountTabs } from './Components/AccountTabs';
import { Header } from './Components/Header';
import { Overview } from './Components/Overview';
import { Transactions } from './Components/Transactions';
import { AdminPanel } from './Components/AdminPanel';

import './index.scss';

export const AccountDetails = () => {
  const { postgresId } = useParams();
  const [activeTab, setActiveTab] = useState('1');
  const [buyerName, setBuyerName] = useState('');
  const [selectedRange, setSelectedRange] = useState(
    getDefaultRangePickerValue(90),
  );
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );

  const {
    data: accountDetailsData,
    isLoading: isDetailsLoading,
    isError: isDetailsError,
  } = useGetAccountDetails({
    selectedRange,
    postgresId,
    appliedFilters,
  });

  useEffect(() => {
    if (
      accountDetailsData?.organization?.name &&
      accountDetailsData?.organization?.name !== buyerName
    ) {
      setBuyerName(accountDetailsData?.organization?.name);
    }
  }, [accountDetailsData]);

  const companyType = accountDetailsData?.organization?.company_type;

  if (isDetailsError) {
    showErrorToast('Fetching account details failed!');
  }

  const isMultiFamilySelected = appliedFilters?.propertyType?.isAnyOf?.includes(
    'Multi Family Home',
  );
  const isVacantSelected = appliedFilters?.propertyType?.isAnyOf?.includes(
    'Vacant',
  );

  const renderComponent = () => {
    switch (activeTab) {
      case '1':
        return (
          <Overview
            postgresId={postgresId}
            appliedFilters={appliedFilters}
            selectedRange={selectedRange}
            accountDetailsData={accountDetailsData}
            isDetailsLoading={isDetailsLoading}
          />
        );
      case '2':
        return <BuyBoxCard postgresId={postgresId} buyerName={buyerName} />;
      case '3':
        return (
          <Transactions
            isMultiFamilySelected={isMultiFamilySelected}
            isVacantSelected={isVacantSelected}
            appliedFilters={appliedFilters}
            selectedRange={selectedRange}
            postgresId={postgresId}
          />
        );
      case '4':
        return (
          <ContactsTable
            organizationId={postgresId}
            selectedRange={selectedRange}
            appliedFilters={appliedFilters}
            organizationName={buyerName}
          />
        );

      case '5':
        return (
          <AdminPanel
            companyType={companyType}
            isFetchingCompanyData={isDetailsLoading}
            organizationId={postgresId}
            organizationName={buyerName}
            accountDetailsData={accountDetailsData}
          />
        );

      default:
        return (
          <Overview
            postgresId={postgresId}
            appliedFilters={appliedFilters}
            selectedRange={selectedRange}
          />
        );
    }
  };

  return (
    <div className="account-details">
      <div className="account-details-filter">
        <Header
          buyerName={buyerName}
          accountDetailsData={accountDetailsData}
          organizationId={postgresId}
        />
        <AccountTabs setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
      <Divider className="divider" />
      {(activeTab === '1' || activeTab === '3') && (
        <ViewPanel>
          <ZorbaDateRangePicker
            onChange={(e) => setSelectedRange(e)}
            value={selectedRange}
            rangePresets={[
              { label: 'Last 30 days', value: 30 },
              { label: 'Last 60 days', value: 60 },
              { label: 'Last 90 days', value: 90 },
            ]}
            disabled={isDetailsLoading}
          />
        </ViewPanel>
      )}
      {renderComponent()}
    </div>
  );
};
