import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlagCheck } from '../../utils/useFeatureFlagCheck';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { MarketViewReport } from './Components/MarketViewReport';

export default () => {
  useDocumentTitle('Market view report');
  useEffect(() => {
    reportScreen('market view report');
  }, []);
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  useFeatureFlagCheck(userDetails, 'marketOverviewReport');

  return (
    <Page>
      <ContentHeader title="Market Overview" />
      <MarketViewReport />
    </Page>
  );
};
