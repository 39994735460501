import { Card } from 'antd';
import { useState } from 'react';
import { Spinner } from '../../../../../../components/layout';
import { AcquisitionsChart } from '../AcquisitionsChart';
import { OverviewHeader } from './Header';
import { TAB_TO_DATA_KEY } from './Header/constants';
import { useGetTitleOrganizationStats } from '../../../../../../queries/TitleOrganization';
import { showErrorToast } from '../../../../../../services/notificationsService';

import { EntitiesTable } from '../../../../../AccountPage/Components/AccountDetails/Components/EntitiesTable';
import { useGetAccountEntities } from '../../../../../../queries/Account';

import './index.scss';

export const Overview = ({
  titleOrganizationData,
  appliedFilters,
  isDataLoading,
  postgresId,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const {
    data: titleOrganizationStats,
    isLoading: isStatsLoading,
    isError: isStatsError,
  } = useGetTitleOrganizationStats({ postgresId, appliedFilters });

  const {
    data: accountEntities,
    isLoading: isLoadingEntities,
  } = useGetAccountEntities({ postgresId });

  if (isStatsError) {
    showErrorToast('Fetching organization stats failed!');
  }
  const transactionsNumber =
    titleOrganizationData?.acquiredPropertiesCount || 0;
  const investorsNumber = titleOrganizationData?.investorsNumber || 0;
  const homeBuyersNumber = titleOrganizationData?.homeBuyersNumber || 0;
  const operatingStatesNumber = titleOrganizationData?.activeStatesNumber || 0;
  return (
    <>
      {!isDataLoading ? (
        <div className="stats">
          <Card>
            <h3>Transactions</h3>
            <h1>{transactionsNumber}</h1>
          </Card>
          <Card>
            <h3>Investor Client Transactions</h3>
            <h1>{`${investorsNumber} ${
              investorsNumber > 0
                ? `(${((investorsNumber / transactionsNumber) * 100).toFixed(
                    0,
                  )}%)`
                : ''
            }`}</h1>
          </Card>
          <Card>
            <h3>Home Buyer Transactions</h3>
            <h1>{`${homeBuyersNumber} ${
              homeBuyersNumber > 0
                ? `(${((homeBuyersNumber / transactionsNumber) * 100).toFixed(
                    0,
                  )}%)`
                : ''
            }`}</h1>
          </Card>
          <Card>
            <h3>Operating Markets</h3>
            <h1>{operatingStatesNumber}</h1>
          </Card>
        </div>
      ) : (
        <Spinner />
      )}
      <div className="entities-and-chart-container">
        <Card
          title={`Associated Entities (${
            accountEntities?.entities?.length || 0
          })`}
          className="entities-card"
        >
          <EntitiesTable
            isLoading={isLoadingEntities}
            accountDetailsData={accountEntities}
          />
        </Card>
        <Card
          title={
            <OverviewHeader activeTab={activeTab} setActiveTab={setActiveTab} />
          }
          className="acquisitions-chart-card"
        >
          <AcquisitionsChart
            isLoading={isStatsLoading}
            accountStatsData={titleOrganizationStats}
            dataKey={TAB_TO_DATA_KEY[activeTab]}
          />
        </Card>
      </div>
    </>
  );
};
