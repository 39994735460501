import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';
import {
  showErrorToast,
} from '../../services/notificationsService';

export const getAccountEntityDetails = async (params) => {
  const response = await postRequest('/data/entity/details', params);

  const { data } = response.data;

  return data;
};

export const getAccountEntityTransactions = async (params) => {
  const response = await postRequest('/data/entity/transactions', params);

  const { data } = response.data;

  return data;
};

export const getAccountEntityStats = async (params) => {
  const response = await postRequest('/data/entity/stats', params);

  const { data } = response.data;

  return data;
};

export const getAccountEntityStates = async (params) => {
  const response = await postRequest('/data/entity/active-states', params);

  const { data } = response.data;

  return data;
};

export const getAccountEntityBuyBox = async (params) => {
  const response = await postRequest('/data/entity/get-entity-buybox', params);

  const { data } = response.data;

  return data;
};
//
// export const useGetAccountEntities = ({ postgresId }) =>
//   useQuery({
//     queryKey: ['accountEntities', postgresId],
//     queryFn: () => getAccountEntities({ postgresId }),
//   });
//
export const useGetAccountEntityDetails = ({
  selectedRange,
  entityName,
  appliedFilters,
}) =>
  useQuery({
    queryKey: [
      'accountEntityDetails',
      selectedRange,
      entityName,
      appliedFilters,
    ],
    queryFn: () =>
      getAccountEntityDetails({ selectedRange, entityName, appliedFilters }),
  });

export const useGetAccountEntityTransactions = ({
  selectedRange,
  entityName,
  appliedFilters,
}) =>
  useQuery({
    queryKey: [
      'accountEntityTransactions',
      selectedRange,
      entityName,
      appliedFilters,
    ],
    queryFn: () =>
      getAccountEntityTransactions({
        selectedRange,
        entityName,
        appliedFilters,
      }),
  });

export const useGetAccountEntityStats = ({ entityName, appliedFilters }) =>
  useQuery({
    queryKey: ['accountEntityStats', entityName, appliedFilters],
    queryFn: () => getAccountEntityStats({ entityName, appliedFilters }),
  });

export const useGetAccountEntityBuyBox = ({
  state,
  entityName,
  selectedMonthRange,
  propertyType,
}) =>
  useQuery({
    queryKey: ['buyBox', state, selectedMonthRange, propertyType],
    queryFn: () =>
      getAccountEntityBuyBox({
        state,
        entityName,
        selectedMonthRange,
        propertyType,
      }),
    enabled: Boolean(state),
  });

export const useGetAccountEntityStates = ({
  selectedMonthRange,
  entityName,
  propertyType,
}) =>
  useQuery({
    queryKey: [
      'activeEntityStates',
      selectedMonthRange,
      entityName,
      propertyType,
    ],
    queryFn: () =>
      getAccountEntityStates({ selectedMonthRange, entityName, propertyType }),
    enabled:
      Boolean(propertyType) &&
      Boolean(selectedMonthRange) &&
      Boolean(entityName),
  });

export const downloadZipcodes = async (fileName, params) => {
  try {
    const response = await postRequest(
      '/data/entity/download-zipcodes',
      params,
    );
    const { reason } = response.data;

    if (reason) {
      showErrorToast(reason);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  } catch (e) {
    showErrorToast(`Downloading zipcodes failed: ${e.message}`);
  }
};
