import { Card, Table, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { useDispatch } from 'react-redux';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { Spinner } from '../../../../../../components/layout';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { postRequest } from '../../../../../../services/requestService';
import {
  useDeleteOrganizationRegex,
  useGetOrganizationRegexes,
  useUpdateOrganizationDetails,
} from '../../../../../../queries/Account';

import './index.scss';
import { CreateOrganizationRegexModal } from './Components/CreateOrganizationRegexModal';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CREATE_ORGANIZATION_REGEX } from '../../../../../../reducers/Modal/types';

export const AdminPanel = ({
  companyType,
  isFetchingCompanyData,
  organizationId,
  organizationName,
  accountDetailsData,
}) => {
  const dispatch = useDispatch();
  const [selectedCompanyType, setSelectedCompanyType] = useState('');
  const [organizationNameValue, setOrganizationNameValue] = useState(
    organizationName,
  );
  const [organizationLinkedinValue, setOrganizationLinkedinValue] = useState(
    accountDetailsData?.organization?.linkedin_page,
  );
  const [organizationWebsiteValue, setOrganizationWebsiteValue] = useState(
    accountDetailsData?.organization?.website_url,
  );
  const [organizationDisableApollo, setOrganizationDisableApollo] = useState(
    accountDetailsData?.organization?.disable_apollo,
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setSelectedCompanyType(companyType);
  }, [companyType]);

  const handleCompanyTypeChange = async (value) => {
    setIsLoading(true);
    try {
      await postRequest('/data/organization/set-organization-type', {
        organizationId,
        organizationType: value,
      });
      setSelectedCompanyType(value);
      showSuccessToast('Organization type saved!');
    } catch (error) {
      showErrorToast('Saving organization type failed!');
    }
    setIsLoading(false);
  };

  const {
    data: organizationRegexesData,
    isLoading: isFetchingOrganizationRegexes,
    isFetching,
  } = useGetOrganizationRegexes({ organizationId });

  const {
    mutate: deleteOrganizationRegex,
    isPending,
  } = useDeleteOrganizationRegex();

  const {
    mutate: updateOrganizationDetails,
    isPending: isUpdatingOrganizationDetails,
  } = useUpdateOrganizationDetails();

  const openCreateOrganizationRegexModal = () => {
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION_REGEX, true));
  };

  return (
    <>
      <div className="admin-panel-container">
        <Card title="Organization type" className="organization-type-card">
          <div className="organization-type-select">
            {isFetchingCompanyData || isLoading ? (
              <Spinner />
            ) : (
              <ZorbaSelect
                placeholder="Company type"
                onChange={handleCompanyTypeChange}
                options={[
                  { label: 'Buyer', value: 'buyer' },
                  { label: 'Lender', value: 'lender' },
                  { label: 'Builder', value: 'builder' },
                  { label: 'Bank', value: 'bank' },
                  { label: 'Title company', value: 'title_company' },
                  { label: 'Government entity', value: 'government_entity' },
                ]}
                value={selectedCompanyType}
              />
            )}
          </div>
        </Card>
        <Card
          className="regexes-card"
          title={
            <div className="organization-regexes-title">
              Organization regexes{' '}
              <FaPlusCircle
                size={25}
                className="plus-icon"
                onClick={openCreateOrganizationRegexModal}
              />
            </div>
          }
        >
          <Table
            loading={isFetchingOrganizationRegexes || isPending || isFetching}
            dataSource={organizationRegexesData}
            columns={[
              {
                title: 'Regex',
                key: 'regex',
                render: ({ regex }) => regex,
              },
              {
                title: '',
                key: uuid(),
                render: ({ id }) => (
                  <BiTrash
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteOrganizationRegex({ regexId: id })}
                  />
                ),
              },
            ]}
          />
        </Card>
        <Card title="Organization details" className="organization-name-card">
          <div className="organization-name-container">
            <ZorbaInput
              label="Name"
              fullWidth
              value={organizationNameValue}
              onChange={(e) => setOrganizationNameValue(e.target.value)}
            />
            <ZorbaInput
              label="Linkedin URL"
              fullWidth
              value={organizationLinkedinValue}
              onChange={(e) => setOrganizationLinkedinValue(e.target.value)}
            />
            <ZorbaInput
              label="Website URL"
              fullWidth
              value={organizationWebsiteValue}
              onChange={(e) => setOrganizationWebsiteValue(e.target.value)}
            />
            <div className="organization-switch">
              <label>Should we disable Apollo contact fetching?</label>
              <Switch
                checked={organizationDisableApollo}
                onChange={(checked) => setOrganizationDisableApollo(checked)}
              />
            </div>
            <div className="save-button-container">
              <ZorbaButton
                fullWidth={false}
                onClick={() =>
                  updateOrganizationDetails({
                    organizationId,
                    organizationName: organizationNameValue,
                    linkedinUrl: organizationLinkedinValue,
                    websiteUrl: organizationWebsiteValue,
                    disableApollo: organizationDisableApollo,
                  })
                }
                disabled={isUpdatingOrganizationDetails}
                loading={isUpdatingOrganizationDetails}
              >
                Save
              </ZorbaButton>
            </div>
          </div>
        </Card>
      </div>
      <CreateOrganizationRegexModal
        organizationId={organizationId}
        organizationName={organizationName}
        organizationRegexesData={organizationRegexesData}
      />
    </>
  );
};
