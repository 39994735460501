import { Card } from 'antd';
import { getPriceText } from '@zorba-shared/core/Services/textService';

export const SingleFamilyBuyBox = ({ buyBoxData }) => (
  <>
    <div className="buybox-container">
      <Card className="buybox-detail-card">
        <h2>{buyBoxData?.beds ? `${buyBoxData.beds}+` : 'N/D'}</h2>
        <h3>Beds</h3>
      </Card>
      <Card className="buybox-detail-card">
        <h2>{buyBoxData?.baths ? `${buyBoxData.baths}+` : 'N/D'}</h2>
        <h3>Baths</h3>
      </Card>
      <Card className="buybox-detail-card">
        <h2>{buyBoxData?.yearBuilt ? `${buyBoxData.yearBuilt}+` : 'N/D'}</h2>
        <h3>Year built</h3>
      </Card>
    </div>
    <div className="buybox-container">
      <Card className="buybox-detail-card">
        <h2>
          {buyBoxData?.buildingSize
            ? `${buyBoxData.buildingSize} sqft+`
            : 'N/D'}
        </h2>
        <h3>Building size</h3>
      </Card>
      <Card className="buybox-detail-card">
        <h2>
          {buyBoxData?.averagePurchasePrice
            ? `${getPriceText(buyBoxData.averagePurchasePrice)}`
            : 'N/D'}
        </h2>
        <h3>AVG Purchase Price</h3>
      </Card>
    </div>
  </>
);
