import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FullPageSpinner } from './components/layout';

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

import TopBuyersReportPage from './pages/TopBuyersReportPage';
import AccountPage from './pages/AccountPage';
import EmailConfirmationPage from './pages/EmailConfirmationPage';
import WatchlistPage from './pages/WatchlistPage';
import TopLendersReportPage from './pages/TopLendersReportPage';
import InsightsPage from './pages/InsightsPage';
import OnboardingPage from './pages/OnboardingPage';
import ConnectStatesPage from './pages/ConnectStatesPage';
import TopTitleCompaniesReportPage from './pages/TopTitleCompaniesReportPage';
import { isMobile } from './services/clientService';
import BuyerSearch from './pages/BuyerSearch';
import CompanyUnmaskingPage from './pages/CompanyUnmaskingPage';
import TransactionsPage from './pages/TransactionsPage';
import MarketViewPage from './pages/MarketViewPage';
import CompanyTransfersPage from './pages/CompanyTransfersPage';
import TitleCompanyTrackerPage from './pages/TitleCompanyTrackerPage';
import TitleEntityPage from './pages/TitleEntityPage';
import TitleOrganizationPage from './pages/TitleOrganizationPage';
import EntityAccountPage from './pages/EntityAccountPage';
import ContactsPage from './pages/ContactsPage';
import RequestLogsPage from './pages/RequestLogsPage';

const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ './pages/LoginPage'),
);

export default () => (
  <Suspense fallback={<FullPageSpinner />}>
    <Switch>
      <Route path="/auth/:authState" component={LoginPage} />
      <Route path="/auth" component={LoginPage} />
      <Route path="/login" exact component={LoginPage} />
      <PublicRoute
        path="/confirm-email"
        exact
        component={EmailConfirmationPage}
      />
      <PrivateRoute path="/" exact component={WatchlistPage} sidebar />
      <PrivateRoute
        path="/top-buyers"
        exact
        component={TopBuyersReportPage}
        sidebar
      />
      <PrivateRoute path="/search" exact component={BuyerSearch} sidebar />
      <PrivateRoute
        path="/top-buyers/:entityName"
        exact
        component={EntityAccountPage}
        sidebar
      />
      <PrivateRoute
        path="/top-buyers/:postgresId/:buyerName"
        exact
        component={AccountPage}
        sidebar
      />
      <PrivateRoute
        path="/top-lenders"
        exact
        component={TopLendersReportPage}
        sidebar
      />
      <PrivateRoute
        path="/top-title-companies"
        exact
        component={TopTitleCompaniesReportPage}
        sidebar
      />
      <PrivateRoute path="/insights" exact component={InsightsPage} sidebar />
      <PrivateRoute
        exact
        path="/onboarding/:stepKey"
        component={OnboardingPage}
        sidebar={!isMobile()}
      />
      <PrivateRoute
        exact
        path="/settings"
        component={ConnectStatesPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/company-unmasking"
        component={CompanyUnmaskingPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/transactions"
        component={TransactionsPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/market-view"
        component={MarketViewPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/company-transfers"
        component={CompanyTransfersPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/title-company-tracker"
        component={TitleCompanyTrackerPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/title-company-tracker/:titleCompanyName"
        component={TitleEntityPage}
        sidebar
      />
      <PrivateRoute
        exact
        path="/title-company-tracker/:postgresId/:organizationName"
        component={TitleOrganizationPage}
        sidebar
      />
      <PrivateRoute exact path="/contacts" component={ContactsPage} sidebar />
      <PrivateRoute
        exact
        path="/request-logs"
        component={RequestLogsPage}
        sidebar
      />
    </Switch>
  </Suspense>
);
