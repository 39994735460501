import { StateBuilder } from './Components/FiltersBuilder/StateBuilder';
import { PropertyTypeBuilder } from './Components/FiltersBuilder/PropertyTypeBuilder';

export const REPORT_FILTER_CONFIG = [
  {
    title: 'State',
    value: 'state',
    builder: StateBuilder,
  },
  {
    title: 'Property type',
    value: 'propertyType',
    builder: PropertyTypeBuilder,
  },
];
