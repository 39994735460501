import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { TopBuyersReport } from './Components/TopBuyersReport';

export default () => {
  useDocumentTitle('Top Buyers Report');
  useEffect(() => {
    reportScreen('top buyers report');
  }, []);

  return (
    <Page>
      <ContentHeader title="Top Buyers report" />
      <TopBuyersReport />
    </Page>
  );
};
