import { useQuery, useIsFetching } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getTopBuyersByKnownOrganization = async (searchCriteria = {}) => {
  const res = await postRequest(
    '/data/top-buyers-report/known-organizations',
    searchCriteria,
  );

  const { data } = res.data;

  return data;
};

export const getTopBuyersByEntityName = async (searchCriteria = {}) => {
  const res = await postRequest(
    '/data/top-buyers-report/entity-name',
    searchCriteria,
  );

  const { data } = res.data;

  return data;
};

export const useGetTopBuyersByEntityName = ({
  selectedMonthRange,
  appliedFilters,
  isMounted,
}) => {
  const isQueryEnabled = useIsFetching({ queryKey: ['views'] }) === 0;

  return useQuery({
    queryKey: ['topBuyersByEntityName', selectedMonthRange, appliedFilters],
    queryFn: () =>
      getTopBuyersByEntityName({
        selectedMonthRange,
        appliedFilters,
      }),
    enabled: isQueryEnabled && isMounted,
    refetchOnWindowFocus: false,
  });
};

export const useGetTopBuyersByKnownAccount = ({
  selectedMonthRange,
  appliedFilters,
  isMounted,
}) => {
  const isQueryEnabled = useIsFetching({ queryKey: ['views'] }) === 0;

  return useQuery({
    queryKey: ['topBuyersByKnownAccount', selectedMonthRange, appliedFilters],
    queryFn: () =>
      getTopBuyersByKnownOrganization({
        selectedMonthRange,
        appliedFilters,
      }),
    enabled: isQueryEnabled && isMounted,
  });
};
