import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { getTabs } from './constants';

import './index.scss';

export const AccountTabs = ({ setActiveTab, activeTab }) => {
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('@getzorba.com');

  return (
    <Tabs
      items={getTabs(isZorbaUser)}
      onChange={(tabKey) => setActiveTab(tabKey)}
      defaultActiveKey={activeTab}
      className="account-tabs"
    />
  );
};
