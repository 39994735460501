import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Tag } from 'antd';
import { MdExitToApp } from '@react-icons/all-files/md/MdExitToApp';

import { AiOutlineUserAdd } from '@react-icons/all-files/ai/AiOutlineUserAdd';
import { logoutUser } from '../../../../../../services/authService';
import { InviteMembersModal } from '../../../../../auth/InviteMembersModal';
import { ZorbaSelect } from '../../../../../../components/forms';
import { updateUserDetails } from '../../../../../../reducers/Auth/actions';
import { MODAL_INVITE_MEMBER } from '../../../../../../reducers/Modal/types';
import { setModalState } from '../../../../../../reducers/Modal/actions';

import './index.scss';

export const UserPanel = () => {
  const dispatch = useDispatch();
  const { userDetails = {} } = useSelector(({ AuthReducer }) => AuthReducer);
  const handleLogout = useCallback(async () => {
    await logoutUser();
  }, []);
  const connectedStates =
    userDetails?.defaultOrganization?.dataSubscription?.activatedStates || [];

  const companyType =
    userDetails?.defaultOrganization?.dataSubscription?.companyType;
  const usedContactCredits =
    userDetails?.defaultOrganization?.dataSubscription?.usedContactCredits || 0;
  const totalContactCredits =
    userDetails?.defaultOrganization?.dataSubscription?.totalContactCredits ||
    50;

  const isZorbaUser = userDetails?.email?.includes('getzorba.com');

  const handleChangeCompanyType = (value) => {
    dispatch(
      updateUserDetails({
        dataSubscription: {
          ...userDetails?.defaultOrganization?.dataSubscription,
          companyType: value,
        },
      }),
    );
  };

  const getNextCreditResetDate = () => {
    const now = new Date();
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return nextMonth.toLocaleDateString('en-US');
  };

  return (
    <div className="user-panel-container">
      <div className="user-details-container">
        {isZorbaUser && (
          <ZorbaSelect
            options={[
              { label: 'Lender', value: 'lender' },
              { label: 'Brokerage', value: 'brokerage' },
              { label: 'Title Company', value: 'title_company' },
            ]}
            value={companyType}
            onChange={(newValue) => handleChangeCompanyType(newValue)}
          />
        )}
        <div>
          <Tag className="states-tag">{`States: ${connectedStates.length} / 50`}</Tag>
          <Tooltip
            title={`Credits Reset On - ${getNextCreditResetDate()}`}
            placement="right"
          >
            <Tag
              className="states-tag"
              style={{ cursor: 'pointer' }}
            >{`Contacts: ${usedContactCredits} / ${totalContactCredits}`}</Tag>
          </Tooltip>
        </div>
        <h3 className="user-name">{userDetails.name}</h3>
        <span className="user-organization">
          {userDetails.defaultOrganization?.name}
        </span>
        <span className="user-email">{userDetails.email}</span>
      </div>
      <div className="buttons-container">
        <Tooltip title="Invite new members">
          <button
            className="logout-button"
            onClick={() => {
              dispatch(setModalState(MODAL_INVITE_MEMBER, true));
            }}
            type="button"
          >
            <AiOutlineUserAdd size={16} color="#3f434a" />
          </button>
        </Tooltip>
        <Tooltip title="Sign out">
          <button
            className="logout-button"
            onClick={handleLogout}
            type="button"
          >
            <MdExitToApp size={16} color="#3f434a" />
          </button>
        </Tooltip>
      </div>
      <InviteMembersModal />
    </div>
  );
};
