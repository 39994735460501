import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getTitleOrganizationDetails = async (params) => {
  const response = await postRequest(
    '/data/title-organization/details',
    params,
  );

  const { data } = response.data;

  return data;
};
export const useGetTitleOrganizationDetails = ({
  selectedRange,
  postgresId,
  appliedFilters,
}) =>
  useQuery({
    queryKey: [
      'titleOrganizationDetails',
      selectedRange,
      postgresId,
      appliedFilters,
    ],
    queryFn: () =>
      getTitleOrganizationDetails({
        selectedRange,
        postgresId,
        appliedFilters,
      }),
    enabled: Boolean(postgresId),
  });

export const getTitleOrganizationStats = async (params) => {
  const response = await postRequest('/data/title-organization/stats', params);

  const { data } = response.data;

  return data;
};
export const useGetTitleOrganizationStats = ({ postgresId, appliedFilters }) =>
  useQuery({
    queryKey: ['titleOrganizationStats', postgresId, appliedFilters],
    queryFn: () =>
      getTitleOrganizationStats({
        postgresId,
        appliedFilters,
      }),
    enabled: Boolean(postgresId),
  });

export const getTitleOrganizationTransactions = async (params) => {
  const response = await postRequest(
    '/data/title-organization/transactions',
    params,
  );

  const { data } = response.data;

  return data;
};
export const useGetTitleOrganizationTransactions = ({
  postgresId,
  appliedFilters,
  selectedRange,
}) =>
  useQuery({
    queryKey: [
      'titleOrganizationTransactions',
      postgresId,
      appliedFilters,
      selectedRange,
    ],
    queryFn: () =>
      getTitleOrganizationTransactions({
        postgresId,
        appliedFilters,
        selectedRange,
      }),
    enabled: Boolean(postgresId),
  });

export const getTitleOrganizationClients = async (params) => {
  const response = await postRequest(
    '/data/title-organization/clients',
    params,
  );

  const { data } = response.data;

  return data;
};
export const useGetTitleOrganizationClients = ({
  postgresId,
  appliedFilters,
  selectedRange,
}) =>
  useQuery({
    queryKey: [
      'titleOrganizationClients',
      postgresId,
      appliedFilters,
      selectedRange,
    ],
    queryFn: () =>
      getTitleOrganizationClients({
        postgresId,
        appliedFilters,
        selectedRange,
      }),
    enabled: Boolean(postgresId),
  });
