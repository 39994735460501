import Tooltip from '../../../../../../components/forms/Tooltip';

export const getTabs = (isContactRequested) => [
  {
    key: '1',
    label: 'Overview',
  },
  {
    key: '2',
    label: 'Buy Box',
  },
  {
    key: '3',
    label: 'Transactions',
  },
  {
    key: '4',
    label: isContactRequested ? (
      <Tooltip title="You already requested contacts for this entity. We will contact you soon.">
        Request contacts
      </Tooltip>
    ) : (
      'Request contacts'
    ),
    disabled: isContactRequested,
  },
];
