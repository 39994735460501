import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const getViews = async () => {
  const response = await getRequest('/data/view/get-views');

  const { data } = response.data;

  return data;
};

export const useGetViews = () =>
  useQuery({
    queryKey: ['views'],
    queryFn: () => getViews(),
  });

export const createView = async (params) => {
  const { data } = await postRequest('/data/view/create-view', params);

  return data;
};

export const useCreateView = (onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createView,
    onSuccess: (data) => {
      onSuccess(data.viewId);
      showSuccessToast('View created successfully');
      queryClient.invalidateQueries({
        queryKey: ['views'],
      });
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.reason || 'Creating view failed');
    },
  });
};

export const deleteView = async (params) => {
  await postRequest('/data/view/delete-view', params);
};

export const useDeleteView = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteView,
    onSuccess: () => {
      showSuccessToast('View deleted successfully');
      queryClient.invalidateQueries({
        queryKey: ['views'],
      });
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.reason || 'Deleting view failed');
    },
  });
};

export const updateView = async (params) => {
  await postRequest('/data/view/update-view', params);
};

export const useUpdateView = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateView,
    onSuccess: () => {
      showSuccessToast('View updated successfully');
      queryClient.invalidateQueries({
        queryKey: ['views'],
      });
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.reason || 'Updating view failed');
    },
  });
};
