import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getCompanyTransfers = async ({
  selectedMonths,
  minNumberOfTransactions,
}) => {
  const res = await postRequest('/data/company-transfers/discover', {
    selectedMonthRange: selectedMonths,
    minNumberOfTransactions,
  });

  const { data } = res.data;

  return data;
};

export const useGetCompanyTransfers = ({
  selectedMonths,
  minNumberOfTransactions,
}) =>
  useQuery({
    queryKey: ['companyTransfers', selectedMonths, minNumberOfTransactions],
    queryFn: () =>
      getCompanyTransfers({ selectedMonths, minNumberOfTransactions }),
  });
