import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useGetTopBuyersByEntityName } from '../../../../../../queries/TopBuyersReport';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { CreateEntityModal } from '../../../../../BuyerSearch/Components/CreateEntityModal';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CREATE_ENTITY } from '../../../../../../reducers/Modal/types';
import { postRequest } from '../../../../../../services/requestService';

export const TopBuyersByEntityNameTable = ({
  selectedMonthRange,
  searchCriteria,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');
  const [selectedEntity, setSelectedEntity] = useState('');
  const dispatch = useDispatch();

  const isTransactionTypeSelected = appliedFilters?.transactionType;

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  const {
    data: topBuyersByEntityNameData,
    isLoading,
    isError,
  } = useGetTopBuyersByEntityName({
    selectedMonthRange,
    appliedFilters: { ...appliedFilters, ...searchCriteria },
    isMounted,
  });

  if (isError) {
    showErrorToast('Fetching report failed!');
  }

  const handleEntitySelect = (entityName) => {
    setSelectedEntity(entityName);
    dispatch(setModalState(MODAL_CREATE_ENTITY, true));
  };

  const handleBlackListName = async (entityName) => {
    try {
      await postRequest('/data/entity/blacklist-name', {
        entityName,
      });
      showSuccessToast('Entity successfully blacklisted');
    } catch (error) {
      showErrorToast('Blacklisting entity failed');
    }
  };

  return (
    <>
      <Table
        rowKey="buyerName"
        loading={isLoading}
        columns={getTableColumns({
          handleEntitySelect,
          isZorbaUser,
          isTransactionTypeSelected,
          handleBlackListName,
        })}
        dataSource={topBuyersByEntityNameData}
        pagination={pagination}
        scroll={tableScroll}
      />
      <CreateEntityModal entity={selectedEntity} />
    </>
  );
};
