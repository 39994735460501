import classNames from 'classnames';
import ZorbaButton from '../../../../../components/forms/ZorbaButton';

import './index.scss';

export default (props) => {
  const { isLoading, floating = false } = props;
  return (
    <div className={classNames('onboarding-step-view-buttons', { floating })}>
      <ZorbaButton
        loading={isLoading}
        onClick={() => props.onContinue()}
        className="onboarding-submit-btn"
      >
        Continue
      </ZorbaButton>
    </div>
  );
};
