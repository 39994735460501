import { Card } from 'antd';
import { useState } from 'react';
import { Spinner } from '../../../../../../components/layout';
import { AcquisitionsChart } from '../AcquisitionsChart';
import { OverviewHeader } from './Header';
import { TAB_TO_DATA_KEY } from './Header/constants';
import { useGetTitleEntityStats } from '../../../../../../queries/TitleEntity';
import { showErrorToast } from '../../../../../../services/notificationsService';

import './index.scss';

export const Overview = ({
  titleCompanyName,
  appliedFilters,
  titleCompanyData,
  isDataLoading,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const {
    data: titleCompanyStats,
    isLoading: isStatsLoading,
    isError: isStatsError,
  } = useGetTitleEntityStats({ titleCompanyName, appliedFilters });

  if (isStatsError) {
    showErrorToast('Fetching account stats failed!');
  }
  const transactionsNumber = titleCompanyData?.acquiredPropertiesCount || 0;
  const investorsNumber = titleCompanyData?.investorsNumber || 0;
  const homeBuyersNumber = titleCompanyData?.homeBuyersNumber || 0;
  const operatingStatesNumber = titleCompanyData?.activeStatesNumber || 0;
  return (
    <>
      {!isDataLoading ? (
        <div className="stats">
          <Card>
            <h3>Transactions</h3>
            <h1>{transactionsNumber}</h1>
          </Card>
          <Card>
            <h3>Investor Client Transactions</h3>
            <h1>{`${investorsNumber} ${
              investorsNumber > 0
                ? `(${((investorsNumber / transactionsNumber) * 100).toFixed(
                    0,
                  )}%)`
                : ''
            }`}</h1>
          </Card>
          <Card>
            <h3>Home Buyer Transactions</h3>
            <h1>{`${homeBuyersNumber} ${
              homeBuyersNumber > 0
                ? `(${((homeBuyersNumber / transactionsNumber) * 100).toFixed(
                    0,
                  )}%)`
                : ''
            }`}</h1>
          </Card>
          <Card>
            <h3>Operating Markets</h3>
            <h1>{operatingStatesNumber}</h1>
          </Card>
        </div>
      ) : (
        <Spinner />
      )}
      <div className="entities-and-chart-container">
        <Card
          title={
            <OverviewHeader activeTab={activeTab} setActiveTab={setActiveTab} />
          }
          className="acquisitions-chart-card"
        >
          <AcquisitionsChart
            isLoading={isStatsLoading}
            accountStatsData={titleCompanyStats}
            dataKey={TAB_TO_DATA_KEY[activeTab]}
          />
        </Card>
      </div>
    </>
  );
};
