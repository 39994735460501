import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Page } from '../../components/layout';
import { TitleEntity } from './Components/TitleEntity';

export default () => {
  useDocumentTitle('Title Entity Page');

  return (
    <Page>
      <TitleEntity />
    </Page>
  );
};
