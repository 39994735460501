import { Breadcrumb } from 'antd';
import { Component } from 'react';
import './index.scss';

export default class Breadcrumbs extends Component {
  onClick = (link) => {};

  render() {
    const { items } = this.props;

    return (
      <Breadcrumb className="breadcrumbs">
        <Breadcrumb.Item href="/">
          <span>Home</span>
        </Breadcrumb.Item>
        {items.map((item, index) => {
          const additionalProps = {};
          if (!item.link) {
            additionalProps.className = 'disabled';
          }

          return (
            <Breadcrumb.Item
              onClick={() => this.onClick(item)}
              key={index}
              {...additionalProps}
            >
              <span>{item.title}</span>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  }
}
