import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import { v4 as uuid } from 'uuid';
import { TopTitleCompaniesTable } from './Components/TopTitleCompaniesTable';
import { useGetTopTitleCompanies } from '../../../../queries/TopTitleCompaniesReport';
import { FiltersDrawer } from '../../../../containers/drawer/FiltersDrawer';
import { rangeOptions } from '../../../TopBuyersReportPage/constants';
import { ViewPanel } from '../../../../containers/view/ViewPanel';

import './index.scss';

export const TopTitleCompanies = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState(3);
  const [createViewMode, setCreateViewMode] = useState(false);

  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  const {
    data: topTitleCompaniesData,
    isLoading,
    isError,
  } = useGetTopTitleCompanies({
    selectedMonthRange: selectedMonths,
    appliedFilters,
    isMounted,
  });

  const handleRangeChange = (value) => {
    setSelectedMonths(value);
  };

  return (
    <div className="top-title-companies-report-page">
      <div className="range-container">
        {rangeOptions.map((option) => (
          <Card
            key={uuid()}
            className={`range-card ${
              selectedMonths === option.value ? 'active' : ''
            }`}
            onClick={() => handleRangeChange(option.value)}
          >
            {option.label}
          </Card>
        ))}
      </div>
      <ViewPanel />
      <Card
        title="Top title companies by market"
        className="top-title-companies-report-table-container"
      >
        <TopTitleCompaniesTable
          isLoading={isLoading}
          isError={isError}
          data={topTitleCompaniesData}
        />
      </Card>
      <FiltersDrawer
        createViewMode={createViewMode}
        setCreateViewMode={setCreateViewMode}
      />
    </div>
  );
};
