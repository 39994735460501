import AppBadge from '../../../../components/AppBadge';

import './index.scss';

export default ({ title }) => (
  <div className="logo">
    <img
      alt="logo"
      width={100}
      src="https://res.cloudinary.com/dyqquolic/image/upload/v1675256922/zorba-logo-black_xwagzk.svg"
    />
    {/*<AppBadge title={title} />*/}
  </div>
);
