import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';

import Logo from '../Logo';
import { UserPanel } from './Components/UserPanel';
import { getSidebarNavItems } from './constants';

import './index.scss';

const SidenavLayout = () => {
  const location = useLocation();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const connectedStates =
    userDetails?.defaultOrganization?.dataSubscription?.activatedStates || [];
  const hasNationwideAccess =
    userDetails?.defaultOrganization?.dataSubscription?.hasNationwideAccess;
  const companyType =
    userDetails?.defaultOrganization?.dataSubscription?.companyType;
  const featureFlags =
    userDetails?.defaultOrganization?.dataSubscription?.featureFlags;
  const isZorbaUser = userDetails?.email?.includes('@getzorba.com');

  const [nav, setNav] = useState(
    getSidebarNavItems({
      shouldDisableNavigation:
        connectedStates.length === 0 && !hasNationwideAccess,
      hasNationwideAccess,
      companyType,
      isZorbaUser,
      featureFlags,
    }),
  );

  useEffect(() => {
    setNav(
      getSidebarNavItems({
        shouldDisableNavigation:
          connectedStates.length === 0 && !hasNationwideAccess,
        hasNationwideAccess,
        companyType,
        isZorbaUser,
        featureFlags,
      }),
    );
  }, [userDetails]);

  const activeKey = nav.find(
    (item) =>
      matchPath(location.pathname, {
        path: item.label?.props?.to,
        exact: false,
        strict: false,
      })?.isExact,
  )?.key;

  const logoUrl = userDetails.defaultOrganization?.logoUrl;

  return (
    <div className="sidebar">
      <div>
        <Logo title="Data Marketplace" />
        {logoUrl && (
          <div className="account-logo">
            <img src={logoUrl} />
          </div>
        )}
        <Menu
          style={{ minWidth: '275px' }}
          className="sidebar-menu"
          theme="light"
          mode="inline"
          selectedKeys={activeKey}
          items={
            userDetails?.defaultOrganization?.dataSubscription
              ?.isCompletedOnboarding
              ? nav
              : []
          }
        />
      </div>
      <UserPanel />
    </div>
  );
};

export default SidenavLayout;
