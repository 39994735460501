import { Table } from 'antd';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import { useGetAccountMailingAddresses } from '../../../../../../queries/Account';

export const MailingAddressesTable = ({ postgresId }) => {
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 10,
  };

  const {
    data: accountMailingAddressesData,
    isLoading: isMailingAddressesLoading,
  } = useGetAccountMailingAddresses({ organizationId: postgresId });

  return (
    <Table
      rowKey="id"
      loading={isMailingAddressesLoading}
      columns={getTableColumns()}
      dataSource={accountMailingAddressesData || []}
      scroll={tableScroll}
      pagination={pagination}
    />
  );
};
