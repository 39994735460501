import { useState } from 'react';
import { Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { postRequest } from '../../services/requestService';
import SearchCard from './Components/SearchCard';
import { CompaniesTable } from './Components/CompaniesTable';

import './index.scss';
import { showErrorToast } from '../../services/notificationsService';

export default () => {
  useDocumentTitle('Company unmasking');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');

  if (!isZorbaUser) {
    history.push('/');
  }

  const onSearch = async (searchParams) => {
    setLoading(true);
    setData([]);
    try {
      const res = await postRequest('/data/unmask-company', searchParams);
      console.log(res.data.data);
      if (res?.data?.data?.company?.status === 'success') {
        setData(
          res?.data?.data?.company?.contacts.map((contact) => ({
            ...contact,
            entityName: contact.name,
            mailingAddress: res?.data?.data?.company?.companyMailingAddress,
            openCorporatesLink: res?.data?.data?.url,
          })) || [],
        );
      }

      if (res?.data?.data?.status === 'failure') {
        showErrorToast(res?.data?.data?.message || 'Company unmasking failed');
      }
    } catch (error) {
      showErrorToast('Company unmasking failed!');
    }
    setLoading(false);
  };

  return (
    <Page className="company-unmasking-page">
      <ContentHeader title="Company Unmasking Tool" />
      <SearchCard onSearch={onSearch} loading={loading} />
      <Divider />
      <CompaniesTable data={data} loading={loading} />
    </Page>
  );
};
