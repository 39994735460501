import { Tag, Tooltip } from 'antd';
import { GoReport } from '@react-icons/all-files/go/GoReport';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { MdSend } from '@react-icons/all-files/md/MdSend';
import { Link } from 'react-router-dom';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PARAM_MARKETING } from '../../../../../../reducers/Modal/types';
import { MAP_CONTACT_TYPE_TO_DEPARTMENT } from '../../../../../AccountPage/Components/AccountDetails/Components/ContactsTable/constants';

export const getTableColumns = ({
  dispatch,
  reportContact,
  setSelectedContactAcquiredProperties,
  setSelectedOrganizationName,
}) => [
  {
    title: 'First name',
    key: 'firstName',
    render: ({ firstName }) => firstName,
  },
  {
    title: 'Last name',
    key: 'lastName',
    render: ({ lastName }) => lastName,
  },
  {
    title: 'Organization',
    key: 'organizationName',
    render: ({ organizationName, organizationId }) => (
      <Link
        className="org-name"
        to={`/top-buyers/${organizationId}/${organizationName}`}
      >
        {organizationName}
      </Link>
    ),
  },
  {
    title: 'Department',
    key: 'department',
    render: ({ department }) => (
      <Tag color="purple">{MAP_CONTACT_TYPE_TO_DEPARTMENT[department]}</Tag>
    ),
  },
  {
    title: 'Title',
    key: 'title',
    render: ({ title }) => title,
  },
  {
    title: 'Phone number',
    key: 'phoneNumber',
    render: ({ phoneNumber }) => phoneNumber,
  },
  {
    title: 'Email',
    key: 'email',
    render: ({ email }) => email,
  },
  {
    title: '',
    key: 'actions',
    render: (contact) => (
      <div className="contact-actions">
        <Tooltip
          title={
            !contact.requestedRefund
              ? 'Report the invalid credit, and our team will review it. If necessary, we will issue a refund.'
              : 'You have already requested a credit refund for this contact. Our team will review your request shortly.'
          }
        >
          <GoReport
            style={{
              cursor: !contact.requestedRefund ? 'pointer' : 'not-allowed',
              fontSize: 23,
              color: !contact.requestedRefund ? 'black' : 'lightgray',
            }}
            onClick={() => {
              if (!contact.requestedRefund) {
                reportContact({ contactId: contact.id });
              }
            }}
          />
        </Tooltip>
        {contact.linkedinUrl && (
          <a
            href={contact.linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin
              style={{
                cursor: 'pointer',
                color: '#0e76a8',
                fontSize: 20,
              }}
            />
          </a>
        )}
        <MdSend
          style={{ cursor: 'pointer', fontSize: 23 }}
          onClick={() => {
            setSelectedContactAcquiredProperties(contact.acquiredProperties);
            setSelectedOrganizationName(contact.organizationName);
            dispatch(setModalState(MODAL_PARAM_MARKETING, true, { contact }));
          }}
        />
      </div>
    ),
  },
];
