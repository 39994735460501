import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_ADD_ACCOUNT_TO_WATCHLIST } from '../../../../../../reducers/Modal/types';
import { ZorbaSelect, ZorbaButton } from '../../../../../../components/forms';
import {
  useAddToWatchlist,
  useGetWatchLists,
} from '../../../../../../queries/Watchlist';

export const AddAccountToWatchListModal = ({
  selectedAccountId,
  setSelectedAccountId,
}) => {
  const { isAddAccountToWatchlistModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [watchListId, setWatchListId] = useState('');
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_ADD_ACCOUNT_TO_WATCHLIST, false));
  };
  const handleSuccess = () => {
    setWatchListId('');
    setSelectedAccountId('');
    handleCloseModal();
  };

  const {
    mutate: addToWatchList,
    isPending: isAddingToWatchList,
  } = useAddToWatchlist(handleSuccess);

  const handleAddToWatchList = () => {
    addToWatchList({ listId: watchListId, accountId: selectedAccountId });
  };
  const { data: lists, isLoading } = useGetWatchLists();
  const selectOptions = (lists || []).map((list) => ({
    label: list.accountIds.some(
      (accountId) => accountId === selectedAccountId,
    ) ? (
      <span
        style={{
          display: 'flex',
          columnGap: '5px',
          alignItems: 'center',
          cursor: 'not-allowed',
        }}
      >
        {list.listName} <FaCheckCircle color="#00cb6f" />
      </span>
    ) : (
      list.listName
    ),
    value: list._id,
    disabled: list.accountIds.some(
      (accountId) => accountId === selectedAccountId,
    ),
  }));

  return (
    <Modal
      open={isAddAccountToWatchlistModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Add to list"
      className="create-watchlist-modal"
      destroyOnClose
    >
      <div className="input-container">
        <ZorbaSelect
          placeholder="List name"
          options={selectOptions}
          onChange={(value) => setWatchListId(value)}
          value={watchListId}
        />
      </div>
      <div className="create-button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleAddToWatchList}
          disabled={!watchListId || isLoading || isAddingToWatchList}
          loading={isLoading || isAddingToWatchList}
        >
          Add
        </ZorbaButton>
      </div>
    </Modal>
  );
};
