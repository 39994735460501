import { Card, Input } from 'antd';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { useState } from 'react';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';

import './index.scss';

export default (props) => {
  const { loading } = props;
  const [searchParams, setSearchParams] = useState({});

  const onChange = (key, value) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    });
  };
  const onSearchClick = () => {
    props.onSearch(searchParams);
  };

  return (
    <Card className="search-card">
      <div className="row">
        <Input
          value={searchParams.name}
          placeholder="Stewart Title"
          onChange={(e) => onChange('name', e.target.value)}
        />
        <ZorbaButton
          loading={loading}
          onClick={() => onSearchClick()}
          variant={BUTTON_VARIANT_DARK}
          disabled={!searchParams.name?.length}
        >
          {loading ? null : <FaSearch />}
        </ZorbaButton>
      </div>
    </Card>
  );
};
