import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getMarketViewReport = async (searchCriteria = {}) => {
  const res = await postRequest('/data/market-view-report', searchCriteria);

  const { data } = res.data;

  return data;
};

export const useGetMarketViewReport = ({ selectedYear, appliedFilters }) =>
  useQuery({
    queryKey: ['marketViewReport', selectedYear, appliedFilters],
    queryFn: () =>
      getMarketViewReport({
        selectedYear,
        appliedFilters,
      }),
  });
