import { useState } from 'react';
import { Card } from 'antd';
import { v4 as uuid } from 'uuid';
import { getDefaultFilters } from './Components/TransactionsQueryFilter/constants';
import { rangeOptions } from '../../../TopBuyersReportPage/constants';
import { TransactionsTable } from './Components/TransactionsTable';
import { TransactionsViewPanel } from './Components/TransactionsViewPanel';
import { TransactionsQueryFilter } from './Components/TransactionsQueryFilter';
import { getQueryParam } from '../../../../services/clientService';

import './index.scss';

export const Transactions = () => {
  const [selectedMonths, setSelectedMonths] = useState(3);
  const handleRangeChange = (value) => {
    setSelectedMonths(value);
  };
  const queryParam = getQueryParam('query');

  const [searchCriteria, setSearchCriteria] = useState(
    getDefaultFilters(queryParam),
  );

  return (
    <div className="transactions-page">
      <TransactionsViewPanel>
        <div className="range-container">
          {rangeOptions.map((option) => (
            <Card
              key={uuid()}
              className={`range-card ${
                selectedMonths === option.value ? 'active' : ''
              }`}
              onClick={() => handleRangeChange(option.value)}
            >
              {option.label}
            </Card>
          ))}
        </div>
      </TransactionsViewPanel>
      <Card>
        <TransactionsQueryFilter
          setSearchCriteria={setSearchCriteria}
          searchCriteria={searchCriteria}
        />
        <TransactionsTable
          selectedMonthRange={selectedMonths}
          searchCriteria={searchCriteria}
        />
      </Card>
    </div>
  );
};
