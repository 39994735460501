import Amplitude from 'amplitude-js';
import * as Sentry from '@sentry/react';

import _ from 'lodash';
import ReactGA from 'react-ga4';
import Analytics from 'analytics';
import customerIo from '@analytics/customerio';
import { isDev } from './clientService';

import {
  getLocalStorageItem,
  LOCAL_STORAGE_IGNORE_ANALYTICS,
} from './localStorageService';

let analytics;
const isDevMode = isDev();
export const PIXEL_ID_SIGNUP_AND_BUY_ALOT = '574231477339669'; // Completed signup & buy 10+ deals (May 23)
const pixels = {
  [PIXEL_ID_SIGNUP_AND_BUY_ALOT]: undefined,
};

// Get all utm parameters from query string
const ampGetQueryParams = (qs) => {
  qs = qs.split('+').join(' ');

  const params = {};
  let tokens;
  const re = /\butm_([^=]+)=([^&]+)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const initAnalytics = () => {
  if (isDevMode || getLocalStorageItem(LOCAL_STORAGE_IGNORE_ANALYTICS)) return;
  analytics = Analytics({
    app: 'zorba',
    version: 1,
    plugins: [
      customerIo({
        siteId: 'b5b545d8741f02dcca74',
      }),
    ],
  });
  window.analytics = analytics;
  ReactGA.initialize('AW-10803247092');
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: 'Zorba Funds',
  });
  Amplitude.getInstance().init('01d39fea36f5130c476444536462355a', null, {
    // optional configuration options
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    // trackingOptions: {
    //   city: false,
    //   ip_address: false,
    // },
  });

  // Populate the object to be sent to Amplitude
  const utmParams = ampGetQueryParams(document.location.search);
  const propertiesToReport = {};
  for (const param in utmParams) {
    propertiesToReport[`utm_${param}`] = utmParams[param];
  }
  if (Object.getOwnPropertyNames(propertiesToReport).length !== 0) {
    Amplitude.getInstance().setUserProperties(propertiesToReport);
  }
};
export const reportEvent = (category, action, additionalParams) => {
  if (isDevMode) return;

  ReactGA.event({
    category,
    action,
    ...additionalParams,
    currentUrl: window.location.href,
  });
  Amplitude.getInstance().logEvent(`${category}_${action}`, additionalParams);

  try {
    analytics.track(`${category}_${action}`, additionalParams);
  } catch (e) {}
};
export const reportScreen = (screenName) => {
  if (isDevMode) return;
  if (getLocalStorageItem(LOCAL_STORAGE_IGNORE_ANALYTICS)) return;
  Amplitude.getInstance().logEvent(`user_pageview`, { screenName });
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: screenName,
  });
  // analytics.page();
};
export const setUserProperty = (key, value, userId) => {
  if (isDevMode) return;

  ReactGA.set({ [key]: value });
  Amplitude.getInstance().setUserProperties({ [key]: value });

  // Identify the user
  if (userId && key !== 'userId') {
    analytics.identify(userId, { [key]: value });
    // if (window.clarity) {
    //   window.clarity("identify", userId);
    //   window.clarity("set", key, value);
    // }
  }
};
export const setUserProperties = (userDetails) => {
  if (isDevMode) return;
  if (window?.__insp && userDetails) {
    userDetails.email && window.__insp.push(['identify', userDetails._id]);
    window.__insp.push([
      'tagSession',
      _.pick(userDetails, ['email', 'name', 'firstName', 'lastName']),
    ]);
    window.__insp.push([
      'tagSession',
      _.pick(userDetails?.defaultOrganization || {}, [
        'mainInterest',
        'investmentType',
      ]),
    ]);
  }

  if (userDetails?._id) {
    const reportedParams = _.pick(userDetails, [
      'firstName',
      'lastName',
      'email',
      'name',
      'kind',
    ]);
    if (userDetails.defaultOrganization?.name) {
      reportedParams.company = userDetails.defaultOrganization?.name;
    }
    reportedParams.userType =
      userDetails?.kind?.length > 0 ? userDetails?.kind[0] : 'agent';
    analytics.identify(userDetails._id, reportedParams);

    try {
      Sentry.setContext('character', {
        userId: userDetails._id,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        friendlyUrl: userDetails.friendlyUrl,
        email: userDetails.email,
      });
      Sentry.setUser({
        userId: userDetails._id,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        friendlyUrl: userDetails.friendlyUrl,
        email: userDetails.email,
      });
    } catch (e) {
      console.log(e);
    }
  }
};

export const reportGoogleConversion = async (
  sendTo = 'AW-10803247092/DJl1CNWJyIMDEPTvsZ8o',
) => {
  if (isDevMode) return;
  if (!window.gtag) return console.log('missing gtag script');
  window.gtag('event', 'conversion', {
    send_to: sendTo,
    // value: amount,
    currency: 'USD',
    transaction_id: '',
  });
};

export const initFacebookAnalytics = async (selectedPixel) => {
  if (isDevMode) return;
  const { default: ReactPixel } = await import('react-facebook-pixel');

  for (const pixelId of selectedPixel ? [selectedPixel] : Object.keys(pixels)) {
    if (!pixels[pixelId]) {
      pixels[pixelId] = ReactPixel;
      pixels[pixelId].init(pixelId, null, {
        autoConfig: true,
        debug: true,
      });
    }
  }
};

export const getFacebookPixel = async (pixelId) => {
  if (!pixels[pixelId]) {
    await initFacebookAnalytics(pixelId);
  }

  return pixels[pixelId];
};

export const reportFacebookConversion = async (pixelId, eventName) => {
  if (!pixelId || isDevMode) return;
  const signupPixel = await getFacebookPixel(PIXEL_ID_SIGNUP_AND_BUY_ALOT);
  signupPixel.trackCustom(eventName);
};
