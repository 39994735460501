import { useRef, useState } from 'react';
import { Avatar, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FaCopy } from '@react-icons/all-files/fa/FaCopy';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PARAM_MARKETING } from '../../../../../../reducers/Modal/types';
import { ZorbaSelect } from '../../../../../../components/forms';
import { useGetCustomTemplates } from '../../../../../../queries/CustomTemplates';
import {
  extractRequiredParams,
  findMatchingProperties,
  injectParams,
  parseLiquidError,
} from './constants';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';

import './index.scss';

export const MarketingModal = ({
  acquiredProperties = [],
  organizationName,
}) => {
  const messageRef = useRef();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [renderedTemplate, setRenderedTemplate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const { data: customTemplates = [] } = useGetCustomTemplates();
  const { isMarketingModalOpen, modalParams } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);

  const handleClose = () => {
    setSelectedTemplate();
    setRenderedTemplate('');
    setErrorMessage('');
    dispatch(setModalState(MODAL_PARAM_MARKETING, false));
  };

  const customTemplateOptions = (customTemplates || []).map(
    (customTemplate) => ({
      label: customTemplate.templateName,
      value: customTemplate._id,
    }),
  );

  const renderTemplate = async (template) => {
    try {
      const requiredParams = extractRequiredParams(template);
      const matchingProperties = findMatchingProperties(
        acquiredProperties,
        requiredParams,
      );
      const params = {
        user_name: userDetails.name || '',
        user_first_name: userDetails.firstName || '',
        user_last_name: userDetails.lastName || '',
        contact_first_name: modalParams?.contact?.firstName || '',
        contact_last_name: modalParams?.contact?.lastName || '',
        contact_organization_name: organizationName,
        acquiredProperties_total: acquiredProperties?.length || 0,
        acquisition_1: {
          address: matchingProperties[0]?.address || '',
          state: matchingProperties[0]?.state || '',
          title_company_name: matchingProperties[0]?.titleCompanyName || '',
          lender_name: matchingProperties[0]?.lenderName || '',
        },
        acquisition_2: {
          address: matchingProperties[1]?.address || '',
          state: matchingProperties[1]?.state || '',
          title_company_name: matchingProperties[1]?.titleCompanyName || '',
          lender_name: matchingProperties[1]?.lenderName || '',
        },
      };
      const liquidTemplate = await injectParams(template, params);
      setRenderedTemplate(liquidTemplate);
    } catch (error) {
      const liquidError = parseLiquidError(error.message);
      if (liquidError) {
        setErrorMessage(liquidError);
      }
      setRenderedTemplate('');
    }
  };

  const handleTemplateChange = async (newValue) => {
    setSelectedTemplate(newValue);
    setErrorMessage('');
    const selected = customTemplates.find(
      (template) => template._id.toString() === newValue.toString(),
    );
    if (selected) {
      await renderTemplate(selected.template);
    }
  };

  const handleCopy = async () => {
    try {
      const textToCopy = messageRef.current.innerText;
      await navigator.clipboard.writeText(textToCopy);
      showSuccessToast('Text copied to clipboard');
    } catch (err) {
      showErrorToast('Copying to clipboard failed!');
    }
  };

  return (
    <Modal
      open={isMarketingModalOpen}
      footer={null}
      onCancel={handleClose}
      title="Marketing"
      className="marketing-modal"
      destroyOnClose
    >
      <div className="contact-info">
        <Avatar size={40} />
        <div className="names">
          <div className="name">{`${modalParams.contact?.firstName} ${modalParams.contact?.lastName}`}</div>
          <div className="title">{`${modalParams.contact?.title}`}</div>
        </div>
      </div>
      <div className="select-container">
        <ZorbaSelect
          placeholder="Select a template"
          options={customTemplateOptions}
          onChange={handleTemplateChange}
          value={selectedTemplate}
        />
      </div>
      {renderedTemplate && (
        <>
          <h3>Personalized message</h3>
          <div className="message-wrapper">
            <div className="copy-btn">
              <FaCopy onClick={handleCopy} />
            </div>
            <div
              className="message"
              ref={messageRef}
              dangerouslySetInnerHTML={{ __html: renderedTemplate }}
            />
          </div>
        </>
      )}
      {errorMessage && (
        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
      )}
    </Modal>
  );
};
