import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import { useGetTransactions } from '../../../../../../queries/Transactions';
import { showErrorToast } from '../../../../../../services/notificationsService';

export const TransactionsTable = ({ selectedMonthRange, searchCriteria }) => {
  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  const { appliedFiltersTransactions: appliedFilters } = useSelector(
    ({ TransactionsReducer }) => TransactionsReducer,
  );

  const { data: transactionsData, isLoading, isError } = useGetTransactions({
    selectedMonthRange,
    appliedFilters: { ...appliedFilters, ...searchCriteria },
  });

  if (isError) {
    showErrorToast('Fetching transactions failed!');
  }

  return (
    <Table
      className="transactions-table"
      rowKey="id"
      loading={isLoading}
      columns={getTableColumns()}
      dataSource={transactionsData || []}
      pagination={pagination}
      scroll={tableScroll}
    />
  );
};
