export const getTableColumns = () => [
  {
    title: 'Address',
    key: 'address',
    render: ({ mailingAddress }) => mailingAddress,
  },
  {
    title: 'City',
    key: 'city',
    render: ({ mailingCity }) => mailingCity,
  },
  {
    title: 'State',
    key: 'state',
    render: ({ mailingState }) => mailingState,
  },
  {
    title: 'Zip',
    key: 'zipCode',
    render: ({ mailingZip }) => mailingZip,
  },
];
