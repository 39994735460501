import { Card, Popconfirm, Radio, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  useDeleteContact,
  useDiscoverOrganizationContacts,
  useGetContactEmail,
  useReportContact,
} from '../../../../../../queries/DiscoverOrganization';
import {
  BUTTON_VARIANT_DARK,
  BUTTON_VARIANT_LINK,
} from '../../../../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import { isMobile } from '../../../../../../services/clientService';
import { MarketingModal } from '../MarketingModal';
import { getTableColumns, dataPlaceholder, CONTACT_TYPES } from './constants';
import { useGetAccountTransactions } from '../../../../../../queries/Account';
import { setUsedContactCredits } from '../../../../../../reducers/Auth/actions';
import { CreateContactModal } from '../CreateContactModal';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CREATE_CONTACT } from '../../../../../../reducers/Modal/types';
import { useGetCustomTemplates } from '../../../../../../queries/CustomTemplates';
import { showSuccessToast } from '../../../../../../services/notificationsService';
import { handleDownload } from './service';
import {
  useGetMappingRequests,
  useRequestContactOrganizationMapping,
} from '../../../../../../queries/MappingRequests';
import { getDefaultRangePickerValue } from '../../../../../../utils';

import './index.scss';

export const ContactsTable = ({
  appliedFilters,
  organizationId,
  organizationName,
}) => {
  const [contactToEdit, setContactToEdit] = useState(null);
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const [department, setDepartment] = useState('');
  const [isDiscoverClicked, setIsDiscoverClicked] = useState(false);
  const { usedContactCredits, totalContactCredits } =
    userDetails.defaultOrganization.dataSubscription || {};
  const { data: customTemplates = [] } = useGetCustomTemplates();

  const hasMoreCredits = (totalContactCredits || 0) > (usedContactCredits || 0);
  const {
    data: contactsData,
    isLoading,
    refetch,
    isRefetching,
    isFetching,
  } = useDiscoverOrganizationContacts({
    organizationId,
    isDiscoverClicked,
  });
  const { data: accountTransactionsData } = useGetAccountTransactions({
    selectedRange: getDefaultRangePickerValue(90),
    postgresId: organizationId,
    appliedFilters,
  });

  const {
    data: mappingRequests,
    isLoading: isFetchingMappingRequests,
  } = useGetMappingRequests();

  const {
    mutate: handleRequestContact,
    isPending: isRequestingContacts,
  } = useRequestContactOrganizationMapping();

  const requestedContacts = (mappingRequests || [])
    .filter((request) => request.kind === 'contact')
    .map((request) => request.mappedOrganizationId);

  const onSuccessContactEmail = (updatedContactCredits) => {
    dispatch(setUsedContactCredits(updatedContactCredits));
  };

  const { mutate: fetchContactEmail, isPending } = useGetContactEmail(
    refetch,
    onSuccessContactEmail,
  );

  const { mutate: reportContact } = useReportContact(refetch, () =>
    showSuccessToast('Invalid contact reported successfully!'),
  );
  const { mutate: deleteContact } = useDeleteContact();

  const filteredContacts = (contactsData || []).filter(
    (contact) => !department || contact.department === department,
  );

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  const handleDiscoverClick = () => {
    setIsDiscoverClicked(true);
  };

  const handleOpenModal = () => {
    dispatch(setModalState(MODAL_CREATE_CONTACT, true));
  };

  const contactsToDownload = filteredContacts.filter(
    (contact) => contact.isDiscovered,
  );

  const contactsToDiscover = filteredContacts
    .filter((contact) => !contact.isDiscovered)
    .map((contact) => ({
      contactId: contact.id,
      externalContactId: contact.externalContactId,
    }));

  const isOrganizationDiscovered = contactsData?.length || isDiscoverClicked;
  const hasMoreCreditsBulkDiscover =
    (totalContactCredits || 0) >=
    (usedContactCredits || 0) + contactsToDiscover.length;
  const handleOnConfirm = () => {
    fetchContactEmail({ contacts: contactsToDiscover });
  };

  const isContactRequested =
    isRequestingContacts ||
    isFetchingMappingRequests ||
    requestedContacts.some(
      (requestedOrganizationId) => requestedOrganizationId === organizationId,
    );
  const isZorbaUser = userDetails?.email?.includes('@getzorba.com');

  const handleEditModalOpen = (contact) => {
    setContactToEdit(contact);
    dispatch(setModalState(MODAL_CREATE_CONTACT, true));
  };
  const getCardTitle = () => (
    <div className="contacts-table-title">
      <span>{`Contacts (${filteredContacts?.length || 0})`}</span>
      <div className="buttons-container">
        {isZorbaUser && (
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_DARK}
            onClick={handleOpenModal}
            disabled={isFetching}
          >
            Create contact
          </ZorbaButton>
        )}
        {!(contactsData || []).length && !isFetching && !isPending && (
          <Tooltip
            title={
              isContactRequested
                ? 'You already requested contacts for this entity. We will contact you soon.'
                : ''
            }
          >
            <div>
              <ZorbaButton
                fullWidth={false}
                variant={BUTTON_VARIANT_LINK}
                onClick={() =>
                  handleRequestContact({ organizationId, organizationName })
                }
                disabled={
                  isFetching ||
                  isPending ||
                  isRequestingContacts ||
                  isContactRequested
                }
              >
                Request contacts
              </ZorbaButton>
            </div>
          </Tooltip>
        )}
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_LINK}
          disabled={
            isFetching ||
            isPending ||
            isRequestingContacts ||
            !filteredContacts?.length ||
            !contactsToDownload.length
          }
          onClick={() =>
            handleDownload({
              customTemplates,
              userDetails,
              accountTransactionsData,
              organizationName,
              contactsToDownload,
            })
          }
        >
          Download as CSV
        </ZorbaButton>
        {contactsToDiscover?.length > 0 && (
          <Popconfirm
            title={`You are about to expose ${contactsToDiscover.length} contacts.`}
            onConfirm={handleOnConfirm}
            okText="Confirm"
            cancelText="Cancel"
            placement="topLeft"
            disabled={!hasMoreCreditsBulkDiscover}
          >
            <Tooltip
              title={
                !hasMoreCreditsBulkDiscover
                  ? `You don't have enough credits to get all of the contacts`
                  : ''
              }
            >
              <div>
                <ZorbaButton
                  fullWidth={false}
                  variant={BUTTON_VARIANT_LINK}
                  disabled={
                    isFetching ||
                    isPending ||
                    isRequestingContacts ||
                    !filteredContacts?.length ||
                    !contactsToDiscover.length ||
                    !hasMoreCreditsBulkDiscover
                  }
                >
                  {`Get all contacts (${contactsToDiscover.length})`}
                </ZorbaButton>
              </div>
            </Tooltip>
          </Popconfirm>
        )}
      </div>
    </div>
  );

  return (
    <div className="contacts-table-container">
      <Card
        title={getCardTitle()}
        className={`${!isOrganizationDiscovered ? 'blurred-card' : 'card'}`}
      >
        <Radio.Group
          options={[
            { label: 'All', value: '' },
            { label: 'Management', value: CONTACT_TYPES.MANAGEMENT },
            { label: 'Acquisitions', value: CONTACT_TYPES.ACQUISITION },
            { label: 'Closings', value: CONTACT_TYPES.CLOSING },
          ]}
          onChange={(e) => {
            setDepartment(e.target.value);
          }}
          value={department}
          optionType="button"
          buttonStyle="solid"
        />
        <br /> <br />
        <Table
          rowKey="name"
          loading={isLoading || isRefetching || isPending}
          columns={getTableColumns({
            dispatch,
            fetchContactEmail,
            hasMoreCredits,
            reportContact,
            isZorbaUser,
            deleteContact,
            handleEditModalOpen,
          })}
          dataSource={
            !isOrganizationDiscovered ? dataPlaceholder : filteredContacts
          }
          scroll={tableScroll}
          className="contacts-table"
        />
        <MarketingModal
          organizationName={organizationName}
          acquiredProperties={accountTransactionsData?.acquiredProperties}
        />
      </Card>
      {!isOrganizationDiscovered && !isLoading && !isFetching && (
        <ZorbaButton
          className="centered-button"
          fullWidth={false}
          onClick={handleDiscoverClick}
          variant={BUTTON_VARIANT_DARK}
        >
          Unlock contacts
        </ZorbaButton>
      )}
      <CreateContactModal
        organizationName={organizationName}
        organizationId={organizationId}
        refetchContacts={refetch}
        contactToEdit={contactToEdit}
        setContactToEdit={setContactToEdit}
      />
    </div>
  );
};
