import { useEffect, useState } from 'react';
import { Card, Radio } from 'antd';
import { v4 as uuid } from 'uuid';

import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getQueryParam } from '../../../../services/clientService';
import {
  getDefaultFilters,
  PREDEFINED_REPORTS,
  rangeOptions,
} from '../../constants';
import { ReportFilter } from './Components/ReportFilter';
import { TopBuyersByKnownAccountTable } from './Components/TopBuyersByKnownAccountTable';
import { TopBuyersByEntityNameTable } from './Components/TopBuyersByEntityNameTable';
import { AddAccountToWatchListModal } from './Components/AddAccountToWatchListModal';
import { ViewPanel } from '../../../../containers/view/ViewPanel';

import './index.scss';

export const TopBuyersReport = () => {
  const [selectedMonths, setSelectedMonths] = useState(3);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const queryReport = queryParams.get('report');
  const [predefinedReport, setPredefinedReport] = useState(
    queryReport || PREDEFINED_REPORTS.BY_KNOWN_ACCOUNTS,
  );
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const queryParam = getQueryParam('query');
  const [searchCriteria, setSearchCriteria] = useState(
    getDefaultFilters(queryParam),
  );
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');

  const handleRangeChange = (value) => {
    setSelectedMonths(value);
  };

  useEffect(() => {
    if (
      !queryReport &&
      predefinedReport === PREDEFINED_REPORTS.BY_KNOWN_ACCOUNTS
    ) {
      return;
    }
    if (predefinedReport && predefinedReport !== queryReport) {
      history.push(`?report=${predefinedReport}`);
    }
  }, [queryReport, predefinedReport, history]);

  return (
    <div className="top-buyers-report-page">
      <div className={`top-buyers-report-radio ${!isZorbaUser ? 'end' : ''}`}>
        {isZorbaUser && (
          <div className="radio-container">
            <Radio.Group
              size="large"
              value={predefinedReport}
              onChange={(e) => setPredefinedReport(e.target.value)}
              className="radio-group"
            >
              <Radio.Button value={PREDEFINED_REPORTS.BY_KNOWN_ACCOUNTS}>
                By known accounts
              </Radio.Button>
              <Radio.Button value={PREDEFINED_REPORTS.BY_ENTITY_NAME}>
                By entity name
              </Radio.Button>
            </Radio.Group>
          </div>
        )}
        <div className="range-container">
          {rangeOptions.map((option) => (
            <Card
              key={uuid()}
              className={`range-card ${
                selectedMonths === option.value ? 'active' : ''
              }`}
              onClick={() => handleRangeChange(option.value)}
            >
              {option.label}
            </Card>
          ))}
        </div>
      </div>
      <ViewPanel />
      <Card className="top-buyers-report-table-container">
        <ReportFilter
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
        />
        {predefinedReport === PREDEFINED_REPORTS.BY_KNOWN_ACCOUNTS && (
          <TopBuyersByKnownAccountTable
            selectedMonthRange={selectedMonths}
            setSelectedAccountId={setSelectedAccountId}
            searchCriteria={searchCriteria}
          />
        )}
        {predefinedReport === PREDEFINED_REPORTS.BY_ENTITY_NAME && (
          <TopBuyersByEntityNameTable
            selectedMonthRange={selectedMonths}
            searchCriteria={searchCriteria}
          />
        )}
      </Card>
      <AddAccountToWatchListModal
        selectedAccountId={selectedAccountId}
        setSelectedAccountId={setSelectedAccountId}
      />
    </div>
  );
};
