import { Card, Table } from 'antd';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { useGetCompanyTransfers } from '../../queries/CompanyTransfers';
import {
  getRowDetailsColumns,
  getTableColumns,
  rangeOptions,
} from './constants';
import { ZorbaInput } from '../../components/forms';

import './index.scss';

const CompanyTransfersPage = () => {
  const [selectedMonths, setSelectedMonths] = useState(3);
  const [minNumberOfTransactions, setMinNumberOfTransactions] = useState(50);
  const { data: companyTransfersData, isLoading } = useGetCompanyTransfers({
    selectedMonths,
    minNumberOfTransactions,
  });

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const handleSearch = (value) => {
    const filtered = companyTransfersData.filter((item) =>
      item.organizationName.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredData(filtered);
    setSearchText(value);
  };

  const handleRangeChange = (value) => {
    setSelectedMonths(value);
  };

  return (
    <Page>
      <ContentHeader title="Company transfers" />
      <div className="company-transfers-page">
        <div className="range-container">
          {rangeOptions.map((option) => (
            <Card
              key={uuid()}
              className={`range-card ${
                selectedMonths === option.value ? 'active' : ''
              }`}
              onClick={() => handleRangeChange(option.value)}
            >
              {option.label}
            </Card>
          ))}
        </div>
        <div className="transactions-input-container">
          <ZorbaInput
            label="Minimum transactions"
            type="number"
            minValue={1}
            fullWidth={false}
            className="number-input"
            value={minNumberOfTransactions}
            onChange={(e) => setMinNumberOfTransactions(e)}
            symbol=""
          />
        </div>
        <div>
          <ZorbaInput
            placeholder="Search by organization name"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          rowKey="organizationId"
          loading={isLoading}
          className="company-transfers-table"
          dataSource={searchText ? filteredData : companyTransfersData}
          columns={getTableColumns()}
          pagination={pagination}
          expandable={{
            expandedRowRender: ({
              totalBuyerTransactions,
              sellerTransactionDetails,
            }) => (
              <Table
                columns={getRowDetailsColumns(totalBuyerTransactions)}
                dataSource={sellerTransactionDetails}
              />
            ),
            rowExpandable: () => true,
          }}
        />
      </div>
    </Page>
  );
};

export default CompanyTransfersPage;
