import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '@zorba-shared/core/Services/textService';
import { FaConnectdevelop } from '@react-icons/all-files/fa/FaConnectdevelop';

import { Popconfirm, Tag, Tooltip } from 'antd';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { v4 as uuid } from 'uuid';
import {
  COMPANY_TYPES_COLORS,
  COMPANY_TYPES_MAP,
} from '../TopBuyersByEntityNameTable/constants';

export const getTableColumns = ({
  openAddAccountModal,
  lists,
  navigateToWatchlist,
  isTransactionTypeSelected,
  handleRequestMapping,
  requestedEntities,
  isRequestingMapping,
  isZorbaUser,
  handleBlackListName,
  isFetchingMappingRequests,
  sorting,
}) => [
  {
    title: 'Account',
    key: 'organizationName',
    render: ({
      organizationId,
      organizationName,
      companyType,
      isOrganization,
      buyerName,
    }) =>
      isOrganization ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            className="buyer-name"
            to={`/top-buyers/${organizationId}/${encodeURIComponent(
              organizationName,
            )}`}
          >
            {organizationName}
          </Link>
          {companyType && (
            <Tag
              color={COMPANY_TYPES_COLORS[companyType]}
              style={{ marginLeft: '10px' }}
            >
              {COMPANY_TYPES_MAP[companyType]}
            </Tag>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link className="buyer-name" to={`/top-buyers/${buyerName}`}>
            {capitalizeFirstLetter(buyerName)}
          </Link>
          {isZorbaUser && (
            <Popconfirm
              title={`You are about to blacklist ${buyerName} from both reports.`}
              onConfirm={() => handleBlackListName(buyerName)}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FaTrash style={{ cursor: 'pointer', marginLeft: '5px' }} />
            </Popconfirm>
          )}
        </div>
      ),
  },
  {
    title: 'Entities',
    key: 'entities',
    render: ({ entities }) => {
      if (Array.isArray(entities)) {
        if (entities?.length <= 2) {
          return entities?.join(', ');
        }
        const displayed = entities.slice(0, 2).join(', ');
        const moreCount = entities.length - 2;
        return `${displayed} and ${moreCount} more`;
      }

      return entities;
    },
  },
  {
    title: 'Acquisitions',
    key: 'acquiredProperties',
    sorter: (a, b) => a.acquiredProperties - b.acquiredProperties,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder:
      sorting?.columnKey === 'acquiredProperties' ? sorting.order : null,
    render: ({ acquiredProperties }) => acquiredProperties,
  },
  ...(!isTransactionTypeSelected
    ? [
        {
          title: 'Dispositions',
          key: 'soldProperties',
          sorter: (a, b) => a.soldProperties - b.soldProperties,
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder:
            sorting?.columnKey === 'soldProperties' ? sorting.order : null,
          render: ({ soldProperties }) => soldProperties,
        },
        {
          title: 'Inter-Company Transfers',
          key: 'portfolioAggregations',
          sorter: (a, b) => a.portfolioAggregations - b.portfolioAggregations,
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder:
            sorting?.columnKey === 'portfolioAggregations'
              ? sorting.order
              : null,
          render: ({ portfolioAggregations }) => portfolioAggregations,
        },
        {
          title: 'Total',
          key: 'totalTransactions',
          sorter: (a, b) => a.totalTransactions - b.totalTransactions,
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder:
            sorting?.columnKey === 'totalTransactions' ? sorting.order : null,
          render: ({ totalTransactions }) => totalTransactions,
        },
      ]
    : []),
  {
    title: 'Watchlist',
    key: 'isOnWatchlist',
    render: ({ organizationId, isOrganization, buyerName }) => {
      if (!lists?.length)
        return (
          <Tooltip title="Create a watchlist to easily track your favorite organizations">
            <span className="save-on-watchlist" onClick={navigateToWatchlist}>
              Save
            </span>
          </Tooltip>
        );

      if (lists.every((list) => list.accountIds.includes(organizationId))) {
        return '';
      }

      if (!isOrganization) {
        if (
          !requestedEntities.some((entity) => entity === buyerName) &&
          !isRequestingMapping &&
          !isFetchingMappingRequests
        ) {
          return (
            <Tooltip title="Request entity mapping">
              <FaConnectdevelop
                style={{ cursor: 'pointer' }}
                size={20}
                onClick={() => handleRequestMapping(buyerName)}
              />
            </Tooltip>
          );
        }
        return (
          <Tooltip title="You already requested mapping for this entity. Our team will get back to you soon.">
            <FaConnectdevelop
              style={{ cursor: 'not-allowed' }}
              size={20}
              color="lightgrey"
            />
          </Tooltip>
        );
      }

      const alreadyUsedLists = lists.filter((list) =>
        list.accountIds.includes(organizationId),
      );

      return (
        <span
          className="save-on-watchlist"
          onClick={() => openAddAccountModal(organizationId)}
        >
          Save
          {alreadyUsedLists?.length > 0 && (
            <Tooltip
              title={
                <span>
                  This account is already saved to:
                  <br />
                  {alreadyUsedLists.map((list) => (
                    <div key={uuid()}>• {list.listName}</div>
                  ))}
                </span>
              }
            >
              <FaInfoCircle />
            </Tooltip>
          )}
        </span>
      );
    },
    align: 'center',
  },
];
