import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getRequestLogs = async (params) => {
  const response = await postRequest(
    '/data/request-logs/get-request-logs',
    params,
  );

  const { data } = response.data;

  return data;
};
export const useGetRequestLogs = () =>
  useQuery({
    queryKey: ['requestLogs'],
    queryFn: () => getRequestLogs(),
  });
