export const HARD_CODED_ORGANIZATIONS = [
  {
    value: '64294720-a8b4-49e8-9e71-19255796261f',
    text: 'Invitation Homes',
    count: 4,
    color: 'green',
  },
  {
    value: '27e6097c-0541-4fad-b90e-115780bf84d1',
    text: 'Avenue One',
    count: 12,
  },
  {
    value: '0c49eb10-6f7a-4cc0-ac44-f9f2a2b66c72',
    text: 'Second Avenue',
    count: 5,
  },
  {
    value: '8234aa6e-f09f-4573-969c-a897f4154beb',
    text: 'Lafayette',
    count: 5,
  },
  {
    value: '0e9eb592-1930-49bd-833d-e0287e94364b',
    text: 'American Homes 4 Rent',
    count: 5,
  },
  {
    value: '9dc0b0e9-e636-4449-a2e2-942f6da69082',
    text: 'Firstkey Homes',
    count: 5,
  },
  { value: 'aa74da13-001e-4c3a-bc32-78f33bd0fdf8', text: 'Tricon', count: 5 },
  { value: 'b81040fd-3e00-4455-874a-7569d63acd30', text: 'Amherst', count: 5 },
  {
    value: 'd9f1e814-954a-45a2-9e4a-d518ad25047f',
    text: 'AHV Communities',
    count: 5,
  },
  { value: '8affa4f7-6e73-4856-b68f-2b6e5f719a1f', text: 'JWB', count: 5 },
  {
    value: 'd8ebda71-9549-4711-a432-420d10432b90',
    text: 'Divvy Homes',
    count: 5,
  },
  { value: '11800002-f8f5-4266-8e2f-8fb296df82e0', text: 'Mynd', count: 5 },
  // { value: "11800002-f8f5-4266-8e2f-8fb296df82e0", text: 'Vinebrook', count: 5},
];
