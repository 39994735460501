import { Alert, Divider, Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import classNames from 'classnames';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CONNECT_STATE } from '../../../../../../reducers/Modal/types';
import { ZorbaButton } from '../../../../../../components/forms';
import { statesOptions } from '../../../../../../containers/drawer/FiltersDrawer/Components/FiltersBuilder/constants';
import { buildCheckoutParams } from './service';
import { postRequest } from '../../../../../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';

import './index.scss';

export const ConnectStateModal = () => {
  const { isConnectStateModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const { activatedStates, totalStateCredits } =
    userDetails.defaultOrganization.dataSubscription || {};
  const [selectedStates, setSelectedStates] = useState([]);
  const existingAndNewStatesCount =
    activatedStates.length + selectedStates.length;
  const { payNowCost } = buildCheckoutParams(
    existingAndNewStatesCount,
    selectedStates,
    totalStateCredits,
  );
  const [loading, setLoading] = useState(false);
  const isButtonDisabled =
    existingAndNewStatesCount < 3 ||
    (existingAndNewStatesCount >= 3 && !selectedStates.length);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_CONNECT_STATE, false));
  };
  const onStateChange = (stateValue) => {
    const currentStatus = selectedStates.includes(stateValue);
    let newValues = [...selectedStates];
    if (!currentStatus) {
      newValues.push(stateValue);
    } else {
      newValues = selectedStates.filter((state) => state !== stateValue);
    }
    setSelectedStates(newValues);
  };

  const onPayClick = async () => {
    setLoading(true);
    try {
      const res = await postRequest('/data/generate-subscription-checkout', {
        newStates: selectedStates,
      });
      if (res?.data?.checkoutPageUrl) {
        window.location.href = res.data.checkoutPageUrl;
      } else {
        showSuccessToast(
          'Your account was upgraded successfully to include the new states. The data will be streamlined into your dashboard in the next 1-3 minutes.',
        );
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (e) {
      showErrorToast('Oops, something happened! Please try again later.');
    }
    setLoading(false);
  };

  const activeStateCodes = (activatedStates || [])
    .filter((state) => state.isActive && !state.disabledAt)
    .map((state) => state.name);
  const currentStateOptions = statesOptions
    .map((stateOption) => ({
      ...stateOption,
      selected: selectedStates.includes(stateOption.value),
    }))
    .filter((stateOption) => !activeStateCodes.includes(stateOption.value));
  return (
    <Modal
      open={isConnectStateModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Connect new state"
      className="connect-state-modal"
      destroyOnClose
    >
      <div className="left">
        <span>
          {`Select the states you'd like to monitor, we'll streamline the
          transaction data into your dashboard within 3-5 minutes.`}
        </span>
        <div className="select-actions">
          {selectedStates.length < 50 && (
            <div
              className="select-all-btn"
              onClick={() =>
                setSelectedStates(currentStateOptions.map((opt) => opt.value))
              }
            >
              Select nationwide access
            </div>
          )}
          {selectedStates.length > 0 && (
            <div
              className="select-all-btn"
              onClick={() => setSelectedStates([])}
            >
              Deselect all
            </div>
          )}
        </div>
        <div className="state-options">
          {currentStateOptions.map((stateOption) => (
            <div
              key={stateOption.value}
              className={classNames('state-option', {
                selected: stateOption.selected,
              })}
              onClick={() => onStateChange(stateOption.value)}
            >
              {stateOption.selected && <FaCheck />}
              {stateOption.label}
            </div>
          ))}
        </div>
      </div>
      <div className="summary">
        <div className="states">
          <div className="state-label">Selected states:</div>
          <div className="state-label">{`${selectedStates.length}/${currentStateOptions.length}`}</div>
        </div>
        <div className="price">
          <div className="price-label">Pay today:</div>
          <div className="price-value">
            {getPriceText(payNowCost)}
            <span>
              every <br />3 months
            </span>
          </div>
          <div className="note">Annual subscription, billed quarterly</div>
        </div>
        <Divider />
        <div className="notes">
          {activatedStates.length ? (
            <div className="summary-item">
              <div className="key">Existing state data ($350/m)</div>
              <div className="value">{activatedStates.length}</div>
            </div>
          ) : null}
          <div className="summary-item">
            <div className="key">New state data ($350/m)</div>
            <div className="value">{selectedStates.length}</div>
          </div>
          {totalStateCredits > 0 && selectedStates.length ? (
            <div className="summary-item">
              <div className="key">Remaining credits ($350/m)</div>
              <div className="value">{-totalStateCredits}</div>
            </div>
          ) : null}
        </div>

        <div className="create-button-container">
          <ZorbaButton
            variant={BUTTON_VARIANT_DARK}
            onClick={onPayClick}
            style={{ height: 42 }}
            disabled={loading || isButtonDisabled}
            loading={loading}
          >
            Confirm payment
          </ZorbaButton>
        </div>
        <br />
        <Alert type="warning" message="Min 3 states required" />
        <br />
        <Alert
          type="warning"
          message="Unlimited nationwide access cost $6,000"
        />
      </div>
    </Modal>
  );
};
