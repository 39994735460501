import { Tabs } from 'antd';
import { TABS } from './constants';

export const OverviewHeader = ({ activeTab, setActiveTab }) => (
  <div className="overview-header">
    <span>Transaction activity</span>
    <Tabs
      items={TABS}
      onChange={(tabKey) => setActiveTab(tabKey)}
      defaultActiveKey={activeTab}
      className="account-tabs transactions"
    />
  </div>
);
