import { USER_TYPE_DATA } from '../../../constants/user';

export const ONBOARDING_STEP_CREATE = 'create';
export const ONBOARDING_STEP_COMPANY_DETAILS = 'company-details';

export const WIZARD_STEPS_BY_INTEREST = {
  [USER_TYPE_DATA]: [ONBOARDING_STEP_CREATE, ONBOARDING_STEP_COMPANY_DETAILS],
};

export const ONBOARDING_DEFAULT_PARAMS = {
  // step 1
  organizationName: '',
  // step 2
  position: '',
  companyDescription: '',
};
