import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Page } from '../../components/layout';
import { TitleCompanyTracker } from './Components/TitleCompanyTracker';

export default () => {
  useDocumentTitle('Title Company Tracker');

  return (
    <Page>
      <TitleCompanyTracker />
    </Page>
  );
};
