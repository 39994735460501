// import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import { showErrorToast } from '../../../../../../services/notificationsService';
import { useGetMarketViewReport } from '../../../../../../queries/MarketView';

export const MarketViewReportTable = ({ selectedYear }) => {
  const { appliedFiltersMarketView: appliedFilters } = useSelector(
    ({ MarketViewReducer }) => MarketViewReducer,
  );
  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const {
    data: marketViewReportData,
    isLoading,
    isError,
  } = useGetMarketViewReport({
    selectedYear,
    appliedFilters,
  });

  if (isError) {
    showErrorToast('Fetching report failed!');
  }

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  return (
    <Table
      rowKey="organizationName"
      loading={isLoading}
      columns={getTableColumns({ selectedYear })}
      dataSource={marketViewReportData}
      pagination={pagination}
      scroll={tableScroll}
    />
  );
};
