import * as constants from '../../constants';
import OnboardingStepCreate from '../OnboardingStepCreate';
import OnboardingStepViewButtons from '../OnboardingStepViewButtons';
import OnboardingStepOrganizationDetails from '../OnboardingStepOrganizationDetails';

import './index.scss';

export default (props) => {
  const { currentStepKey, wizardSteps, stepIndex } = props;
  const STEPS = {
    [constants.ONBOARDING_STEP_CREATE]: <OnboardingStepCreate {...props} />,
    [constants.ONBOARDING_STEP_COMPANY_DETAILS]: (
      <OnboardingStepOrganizationDetails {...props} />
    ),
  };

  if (!STEPS[currentStepKey]) return null;

  return (
    <div className="onboarding-step-view">
      <div className="step-indicator">{`Onboarding - Step ${stepIndex + 1} of ${
        wizardSteps.length
      }`}</div>
      {STEPS[currentStepKey]}
      <OnboardingStepViewButtons {...props} />
    </div>
  );
};
