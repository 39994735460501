import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import AuthReducer from './Auth';
import DrawerReducer from './Drawer';
import ModalReducer from './Modal';
import FiltersReducer from './Filters';
import TransactionsReducer from './Transactions';
import MarketViewReducer from './MarketView';
import TopBuyersReport from './TopBuyersReport';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    AuthReducer,
    ModalReducer,
    DrawerReducer,
    FiltersReducer,
    TransactionsReducer,
    MarketViewReducer,
    TopBuyersReport,
  });
