import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const getCustomTemplates = async () => {
  const response = await getRequest(
    '/data/custom-templates/get-custom-templates',
  );

  const { data } = response.data;

  return data;
};

export const useGetCustomTemplates = () =>
  useQuery({
    queryKey: ['customTemplates'],
    queryFn: () => getCustomTemplates(),
  });

export const createUpdateCustomTemplate = async (params) => {
  const { data } = await postRequest(
    '/data/custom-templates/create-update-custom-template',
    params,
  );

  return data;
};

export const useCreateUpdateCustomTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createUpdateCustomTemplate,
    onSuccess: () => {
      showSuccessToast('Custom template saved successfully');
      queryClient.invalidateQueries({
        queryKey: ['customTemplates'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Saving custom template failed',
      );
    },
  });
};

export const deleteCustomTemplate = async (params) => {
  await postRequest('/data/custom-templates/delete-custom-template', params);
};

export const useDeleteCustomTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCustomTemplate,
    onSuccess: () => {
      showSuccessToast('Custom template deleted successfully');
      queryClient.invalidateQueries({
        queryKey: ['customTemplates'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Deleting custom template failed',
      );
    },
  });
};
