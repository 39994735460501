import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlagCheck } from '../../utils/useFeatureFlagCheck';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import { Transactions } from './Components/Transactions';
import ContentHeader from '../../components/ContentHeader';

export default () => {
  useDocumentTitle(`Transactions`);
  useEffect(() => {
    reportScreen('transactions page');
  }, []);
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);

  useFeatureFlagCheck(userDetails, 'transactionsReport');

  return (
    <Page>
      <ContentHeader title="Transactions" />
      <Transactions />
    </Page>
  );
};
