import { capitalizeFirstLetter } from '@zorba-shared/core/Services/textService';

export const getTableColumns = () => [
  {
    title: 'First name',
    key: 'firstName',
    render: ({ firstName }) => firstName,
  },
  {
    title: 'Last name',
    key: 'lastName',
    render: ({ lastName }) => lastName,
  },
  {
    title: 'Entity name',
    key: 'entityName',
    render: ({ entityName }) => entityName,
  },
  {
    title: 'Mailing address',
    key: 'mailingAddress',
    render: ({ mailingAddress }) => mailingAddress,
  },
  {
    title: 'Title',
    key: 'title',
    render: ({ title }) => capitalizeFirstLetter(title),
  },
  {
    title: '',
    key: 'openCorporatesLink',
    render: ({ openCorporatesLink }) => (
      <a href={openCorporatesLink} target="_blank" rel="noreferrer">
        Open Corporates
      </a>
    ),
  },
];
