const generateMonthlyColumns = (year) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // 0 for January, 11 for December

  const columns = [];

  months.forEach((month, index) => {
    const monthNumber = index + 1; // Month number, 1 for January, 12 for December
    if (year < currentYear || (year === currentYear && index <= currentMonth)) {
      columns.push({
        title: `Acquisitions ${monthNumber}/${year}`,
        dataIndex: `${month.toLowerCase()}Acquisitions`,
        key: `${monthNumber}-${year}-Acquisitions`,
        align: 'center',
        sorter: (a, b) =>
          a[`${month.toLowerCase()}Acquisitions`] -
          b[`${month.toLowerCase()}Acquisitions`],
      });
      columns.push({
        title: `Dispositions ${monthNumber}/${year}`,
        dataIndex: `${month.toLowerCase()}Dispositions`,
        key: `${monthNumber}-${year}-Dispositions`,
        align: 'center',
        sorter: (a, b) =>
          a[`${month.toLowerCase()}Dispositions`] -
          b[`${month.toLowerCase()}Dispositions`],
      });
      columns.push({
        title: `Aggregations ${monthNumber}/${year}`,
        dataIndex: `${month.toLowerCase()}Aggregations`,
        key: `${monthNumber}-${year}-Aggregations`,
        align: 'center',
        sorter: (a, b) =>
          a[`${month.toLowerCase()}Aggregations`] -
          b[`${month.toLowerCase()}Aggregations`],
      });
    }
  });

  return columns;
};

const calculateSumByMonth = (obj, dataKey) =>
  Object.keys(obj)
    .filter((key) => key.includes(dataKey))
    .reduce((sum, key) => sum + obj[key], 0);

const calculateSumByQuarter = (obj, months, dataKey) =>
  months.reduce((sum, monthIndex) => {
    const month = new Date(0, monthIndex)
      .toLocaleString('default', { month: 'long' })
      .toLowerCase();
    return sum + (obj[`${month}${dataKey}`] || 0);
  }, 0);

const generateQuarterColumns = (year) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const quarterColumns = [];

  const quarters = [
    { name: 'Q1', months: [0, 1, 2] },
    { name: 'Q2', months: [3, 4, 5] },
    { name: 'Q3', months: [6, 7, 8] },
    { name: 'Q4', months: [9, 10, 11] },
  ];

  quarters.forEach((quarter, index) => {
    if (
      year < currentYear ||
      (year === currentYear &&
        quarter.months.some((monthIndex) => monthIndex <= currentMonth))
    ) {
      const quarterLabel = `Q${index + 1} ${year}`;
      const quarterLabelWithQTD =
        year === currentYear &&
        quarter.months.some((monthIndex) => monthIndex === currentMonth)
          ? `${quarterLabel} - QTD`
          : quarterLabel;

      quarterColumns.push({
        title: `Acquisitions ${quarterLabelWithQTD}`,
        key: `${quarterLabel}-Acquisitions`,
        align: 'center',
        sorter: (a, b) =>
          calculateSumByQuarter(a, quarter.months, 'Acquisitions') -
          calculateSumByQuarter(b, quarter.months, 'Acquisitions'),
        render: (data) =>
          calculateSumByQuarter(data, quarter.months, 'Acquisitions'),
      });
      quarterColumns.push({
        title: `Dispositions ${quarterLabelWithQTD}`,
        key: `${quarterLabel}-Dispositions`,
        align: 'center',
        sorter: (a, b) =>
          calculateSumByQuarter(a, quarter.months, 'Dispositions') -
          calculateSumByQuarter(b, quarter.months, 'Dispositions'),
        render: (data) =>
          calculateSumByQuarter(data, quarter.months, 'Dispositions'),
      });
      quarterColumns.push({
        title: `Aggregations ${quarterLabelWithQTD}`,
        key: `${quarterLabel}-Aggregations`,
        align: 'center',
        sorter: (a, b) =>
          calculateSumByQuarter(a, quarter.months, 'Aggregations') -
          calculateSumByQuarter(b, quarter.months, 'Aggregations'),
        render: (data) =>
          calculateSumByQuarter(data, quarter.months, 'Aggregations'),
      });
    }
  });

  return quarterColumns;
};

export const getTableColumns = ({ selectedYear }) => {
  const monthsColumns = generateMonthlyColumns(selectedYear);
  const quarterColumns = generateQuarterColumns(selectedYear);

  return [
    {
      title: 'Organization',
      key: 'organizationName',
      render: ({ organizationId, organizationName }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a
            className="buyer-name"
            href={`/top-buyers/${organizationId}/${organizationName}`}
            target="_blank"
            rel="noreferrer"
          >
            {organizationName}
          </a>
        </div>
      ),
    },
    {
      title: 'Total Acquisitions',
      key: 'totalAcquisitions',
      render: (data) => calculateSumByMonth(data, 'Acquisitions'),
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        calculateSumByMonth(a, 'Acquisitions') -
        calculateSumByMonth(b, 'Acquisitions'),
    },
    {
      title: 'Total Dispositions',
      key: 'totalDispositions',
      render: (data) => calculateSumByMonth(data, 'Dispositions'),
      sorter: (a, b) =>
        calculateSumByMonth(a, 'Dispositions') -
        calculateSumByMonth(b, 'Dispositions'),
    },
    {
      title: 'Total Aggregations',
      key: 'totalAggregations',
      render: (data) => calculateSumByMonth(data, 'Aggregations'),
      sorter: (a, b) =>
        calculateSumByMonth(a, 'Aggregations') -
        calculateSumByMonth(b, 'Aggregations'),
    },
    ...quarterColumns,
    ...monthsColumns,
  ];
};
