import { useState } from 'react';
import getSlug from 'speakingurl';
import { Card, Divider } from 'antd';
import { v4 as uuid } from 'uuid';
import { capitalizeFirstLetter } from '@zorba-shared/core/Services/textService';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';
import { statesOptions } from '../../../../../../containers/drawer/FiltersDrawer/Components/FiltersBuilder/constants';
import { Spinner } from '../../../../../../components/layout';
import { rangeOptions } from '../../../../../TopBuyersReportPage/constants';
import {
  useGetAccountEntityBuyBox,
  useGetAccountEntityStates,
  downloadZipcodes,
} from '../../../../../../queries/AccountEntity';
import { ASSET_TYPES } from './constants';
import { EmptyState } from './Components/EmptyState';
import { SingleFamilyBuyBox } from './Components/SingleFamilyBuyBox';
import { MultiFamilyBuyBox } from './Components/MultiFamilyBuyBox';
import { VacantBuyBox } from './Components/VacantBuyBox';

import './index.scss';

export const BuyBoxCard = ({ entityName }) => {
  const [buyBoxState, setBuyBoxState] = useState('');
  const [selectedMonths, setSelectedMonths] = useState(3);
  const [propertyType, setPropertyType] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleRangeChange = (value) => {
    setBuyBoxState('');
    setSelectedMonths(value);
  };

  const {
    data: buyBoxData,
    isLoading: isBuyBoxLoading,
  } = useGetAccountEntityBuyBox({
    state: buyBoxState,
    entityName,
    selectedMonthRange: selectedMonths,
    propertyType,
  });

  const {
    data: activeStatesData,
    isLoading: isFetchingStates,
  } = useGetAccountEntityStates({
    selectedMonthRange: selectedMonths,
    propertyType,
    entityName,
  });

  const renderBuyBox = () => {
    if (propertyType === 'Single Family Home')
      return <SingleFamilyBuyBox buyBoxData={buyBoxData} />;
    if (propertyType === 'Multi Family Home')
      return <MultiFamilyBuyBox buyBoxData={buyBoxData} />;
    if (propertyType === 'Vacant')
      return <VacantBuyBox buyBoxData={buyBoxData} />;

    return null;
  };

  const handleChangePropertyType = (value) => {
    setBuyBoxState('');
    setPropertyType(value);
  };

  const handleDownloadZipCodes = async () => {
    const filename = `${getSlug(entityName)}_${getSlug(
      propertyType,
    )}_zipcodes.csv`;
    setIsDownloading(true);
    await downloadZipcodes(filename, {
      entityName,
      selectedMonthRange: selectedMonths,
      propertyType,
    });
    setIsDownloading(false);
  };

  return (
    <>
      <div className="range-container">
        <div>
          <ZorbaSelect
            className="asset-select"
            options={ASSET_TYPES}
            fullWidth={false}
            value={propertyType}
            onChange={handleChangePropertyType}
          />
        </div>
        {rangeOptions.map((option) => (
          <Card
            key={uuid()}
            className={`range-card ${
              selectedMonths === option.value ? 'active' : ''
            }`}
            onClick={() => handleRangeChange(option.value)}
          >
            {option.label}
          </Card>
        ))}
      </div>
      {!isFetchingStates && activeStatesData?.activeStates.length === 0 ? (
        <EmptyState
          selectedMonthRange={selectedMonths}
          propertyType={propertyType}
        />
      ) : (
        <Card
          title={
            <div className="buybox-title-container">
              <span>Buy Box</span>
              <ZorbaButton
                variant={BUTTON_VARIANT_LINK}
                fullWidth={false}
                onClick={handleDownloadZipCodes}
                loading={isDownloading}
                disabled={isDownloading || !propertyType || isFetchingStates}
              >
                Download zipcodes
              </ZorbaButton>
            </div>
          }
          className="buybox-card"
        >
          <div>
            <span>
              We reverse engineered this buyer’s buy box based on their
              transactions. Please select asset class and state to see the buy
              box.
            </span>
            <div className="states-container">
              {(activeStatesData?.activeStates || []).map((state) => (
                <Card
                  className={`state-card ${
                    buyBoxState === state ? 'active' : ''
                  }`}
                  key={uuid()}
                  onClick={() => setBuyBoxState(state)}
                >
                  {
                    statesOptions.find(
                      (stateOption) => stateOption.value === state,
                    ).label
                  }
                </Card>
              ))}
              <Divider />
              {isBuyBoxLoading || isFetchingStates ? (
                <div className="spinner-container">
                  <Spinner />
                </div>
              ) : (
                buyBoxData &&
                buyBoxState && (
                  <>
                    <div className="areas-container">
                      <h3>Cities:</h3>
                      <span>
                        {(buyBoxData?.uniqueCities || [])
                          .map(capitalizeFirstLetter)
                          .join(', ')}
                      </span>
                    </div>
                    {renderBuyBox()}
                  </>
                )
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
