import { useState, useEffect } from 'react';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { ActiveStatesTable } from './Components/ActiveStatesTable';
import { ConnectStateModal } from './Components/ConnectStateModal';
import { useGetConnectedStates } from '../../../../queries/ConnectStates';
import { setModalState } from '../../../../reducers/Modal/actions';
import { MODAL_CONNECT_STATE } from '../../../../reducers/Modal/types';
import { Spinner } from '../../../../components/layout';
import { CancelStateModal } from './Components/CancelStateModal';
import { ManageViews } from './Components/ManageViews';
import { CustomTemplates } from './Components/CustomTemplates';

import './index.scss';

export const ConnectStates = () => {
  const { data, isFetching } = useGetConnectedStates();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [stateToCancel, setStateToCancel] = useState();
  const handleOpenModal = () => {
    dispatch(setModalState(MODAL_CONNECT_STATE, true));
  };
  const { userDetails = {} } = useSelector(({ AuthReducer }) => AuthReducer);
  const {
    activatedStates,
    totalStateCredits = 0,
    totalContactCredits = 0,
    usedContactCredits = 0,
    hasNationwideAccess,
  } = userDetails.defaultOrganization.dataSubscription || {};
  const connectedStates = (
    userDetails?.defaultOrganization?.dataSubscription?.activatedStates || []
  ).length;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('pay_success') === 'true') {
      setLoading(true);
      setTimeout(() => {
        searchParams.delete('pay_success');
        history.replace({ search: searchParams.toString() });
        window.location.reload();
      }, 10000);
    }
  }, [location, history]);

  const getStateUsageText = () => {
    if (hasNationwideAccess) return 'Nationwide Access';

    if (!connectedStates) return 'NO DATA';

    return `${connectedStates}/50 states connected`;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="connect-states">
        <div className="content-container">
          <div className="stats">
            <Card>
              <h3>Billing plan</h3>
              <h1>
                {connectedStates || hasNationwideAccess
                  ? 'Active'
                  : 'NO SUBSCRIPTION'}
              </h1>
            </Card>
            <Card>
              <h3>State Data Usage</h3>
              <h1>{getStateUsageText()}</h1>
            </Card>
            <Card>
              <h3>Contacts Usage</h3>
              <h1>
                {totalContactCredits
                  ? `${usedContactCredits}/${totalContactCredits} credits  used`
                  : 'NO CREDITS'}
              </h1>
            </Card>
          </div>
          {hasNationwideAccess || activatedStates?.length ? null : (
            <Card className="empty-state">
              <img src="/img/upgrade-empty-state.png" />
              <div className="title">
                Plug into your first state data stream
              </div>
              <p>
                Connect your first state to seamlessly integrate and streamline
                transaction data.
                <br />
                {`We'll handle the cleaning and processing, enabling you to track
                your clients' activities and discover new opportunities.`}
              </p>
              <br />
              <br />
              <ZorbaButton
                fullWidth={false}
                variant={BUTTON_VARIANT_DARK}
                onClick={handleOpenModal}
                disabled={hasNationwideAccess}
              >
                Buy state data
              </ZorbaButton>
            </Card>
          )}

          {hasNationwideAccess ? null : (
            <Card className="active-markets" title="Active markets">
              <ActiveStatesTable
                data={data}
                isLoading={isFetching}
                setStateToCancel={setStateToCancel}
              />
            </Card>
          )}
        </div>
        <div className="connect-state-container">
          <div>
            <ZorbaButton
              variant={BUTTON_VARIANT_DARK}
              onClick={handleOpenModal}
              disabled={hasNationwideAccess}
            >
              Buy state data
            </ZorbaButton>
            {totalStateCredits > 0 && (
              <div
                style={{
                  margin: '10px auto 0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#808080',
                }}
              >
                <div color="purple">{`${totalStateCredits} state credits available`}</div>
              </div>
            )}
            {hasNationwideAccess && (
              <div className="upgrade-to-pro-card">
                <div className="right">
                  <p className="desc">
                    Your account has nationwide data access to all transactions
                    in all 50 states.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ManageViews />
      <CustomTemplates />
      <ConnectStateModal connectedStates={data} />
      <CancelStateModal stateToCancel={stateToCancel} connectedStates={data} />
    </>
  );
};
