import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { TopTitleCompanies } from './Components/TopTitleCompanies';

export default () => {
  useDocumentTitle('Top Title Companies Report');
  useEffect(() => {
    reportScreen('top title companies report');
  }, []);

  return (
    <Page>
      <ContentHeader title="Top Title Companies Report" />
      <TopTitleCompanies />
    </Page>
  );
};
