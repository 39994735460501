import {
  capitalizeFirstLetter,
  getDaysSince,
} from '@zorba-shared/core/Services/textService';
import { IoMdCheckmark } from '@react-icons/all-files/io/IoMdCheckmark';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';

export const getTableColumns = () => [
  {
    title: 'Kind',
    key: 'kind',
    render: ({ kind }) =>
      kind === 'entity' || kind === 'contact'
        ? `${capitalizeFirstLetter(kind)} request`
        : capitalizeFirstLetter(kind),
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) =>
      status === 'success' ? <IoMdCheckmark /> : <IoMdClose />,
    align: 'center',
  },
  {
    title: 'Name',
    key: 'entityName',
    render: ({ entityName, mappedOrganizationId, kind, contactName }) => (
      <Link
        to={
          mappedOrganizationId
            ? `/top-buyers/${mappedOrganizationId}/${encodeURIComponent(
                entityName,
              )}`
            : `/top-buyers/${encodeURIComponent(entityName)}`
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'black' }}
      >
        {kind === 'contact' || kind === 'entity'
          ? entityName
          : `${contactName ? `${contactName} - ${entityName}` : ''} `}
      </Link>
    ),
  },
  {
    title: '',
    key: 'createdAt',
    render: ({ createdAt }) => (
      <Tag>{`${getDaysSince(new Date(createdAt))} days ago`}</Tag>
    ),
  },
];
