import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  createOrganization,
  updateOrganizationDetails,
  updateUserDetails,
} from '../../../reducers/Auth/actions';
import { setModalState } from '../../../reducers/Modal/actions';

import { reportEvent } from '../../../services/analyticsService';
import { showErrorToast } from '../../../services/notificationsService';
import { buildOnboardingPageUrl } from '../../../services/urlBuilderService';
import { USER_TYPE_DATA } from '../../../constants/user';

import OnboardingStepView from './Components/OnboardingStepView';
import * as constants from './constants';
import { validateOnboarding } from './service';

import './index.scss';

const OnboardingWizard = (props) => {
  const { match } = props;
  const dispatch = useDispatch();
  const wizardSteps = constants.WIZARD_STEPS_BY_INTEREST[USER_TYPE_DATA];

  const [validationObj, setValidationObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    ...constants.ONBOARDING_DEFAULT_PARAMS,
  });
  const [stepIndex, setStepIndex] = useState(0);

  const currentStepKey = wizardSteps[stepIndex];

  useEffect(() => {
    reportEvent('data_onboarding', 'start');
    const stepKey = match?.params?.stepKey;
    if (stepKey !== constants.ONBOARDING_STEP_COMPANY_DETAILS) {
      dispatch(push(buildOnboardingPageUrl()));
    }
  }, []);

  const navigatePage = (newStepIndex) => {
    const newStepKey = wizardSteps[newStepIndex];
    setStepIndex(stepIndex + 1);
    const newUrl = buildOnboardingPageUrl(newStepKey);
    reportEvent('data_onboarding', newStepKey);
    dispatch(push(newUrl));
  };

  /** Triggered when the user click Continue */
  const onContinue = () => {
    const { isValid, validationObj: newValidationObj } = validateOnboarding(
      params,
      currentStepKey,
    );
    setValidationObj(newValidationObj);

    if (!isValid)
      return showErrorToast(
        newValidationObj.customError || 'Fill required fields',
      );
    reportEvent('data_onboarding', `${currentStepKey}_next_click`);
    // IN CASE THIS IS THE LAST STEP
    if (stepIndex === wizardSteps.length - 1) {
      reportEvent('data_onboarding', 'success', { ...params });
      dispatch(
        updateUserDetails(
          {
            dataSubscription: {
              companyType: params.companyType,
              creatorPosition: params.position,
              isCompletedOnboarding: true,
              isActive: true,
            },
          },
          () => {
            dispatch(push('/settings'));
          },
        ),
      );
    } else {
      navigatePage(stepIndex + 1);
      if (stepIndex === 0) {
        dispatch(
          updateUserDetails({
            organizationName: params.organizationName,
          }),
        );
      }
    }
  };

  /** Triggered when the user click Back */
  const onBack = () => {
    navigatePage(stepIndex - 1);
  };
  const onChange = (key, value) => {
    setParams({ ...params, [key]: value });
  };

  return (
    <div className={`onboarding-wizard ${currentStepKey}`}>
      <OnboardingStepView
        {...props}
        validationObj={validationObj}
        currentStepKey={currentStepKey}
        onContinue={onContinue}
        onBack={onBack}
        params={params}
        onChange={onChange}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        wizardSteps={wizardSteps}
        stepIndex={stepIndex}
      />
    </div>
  );
};

const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, organization } = AuthReducer;

  return {
    userDetails,
    organization,
  };
};
const mapDispatchToProps = {
  push,
  setModalState,
  updateUserDetails,
  createOrganization,
  updateOrganizationDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingWizard);
