import * as types from './types';

const initialState = {
  modalParams: {},
  [types.FILTERS_DRAWER]: false,
  [types.TRANSACTIONS_FILTERS_DRAWER]: false,
  [types.MARKET_VIEW_FILTERS_DRAWER]: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DRAWER_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
