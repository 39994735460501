import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { addCommas } from '@zorba-shared/core/Services/textService';
import {
  COMPANY_TYPES_COLORS,
  COMPANY_TYPES_MAP,
} from '../TopBuyersReportPage/Components/TopBuyersReport/Components/TopBuyersByEntityNameTable/constants';

export const getTableColumns = () => [
  {
    title: 'Account',
    key: 'organizationName',
    render: ({ organizationId, organizationName, companyType }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link
          className="buyer-name"
          to={`/top-buyers/${organizationId}/${organizationName}`}
        >
          {organizationName}
        </Link>
        {companyType && (
          <Tag
            color={COMPANY_TYPES_COLORS[companyType]}
            style={{ marginLeft: '10px' }}
          >
            {COMPANY_TYPES_MAP[companyType]}
          </Tag>
        )}
      </div>
    ),
    onFilter: (value, record) => record.organizationName.includes(value),
  },
  {
    title: 'Total transactions',
    key: 'Total acquisitions',
    render: ({ totalBuyerTransactions }) => addCommas(totalBuyerTransactions),
  },
];

export const getRowDetailsColumns = (totalBuyerTransactions) => [
  { title: 'Seller', render: ({ seller }) => seller },
  {
    title: 'Seller Organization',
    render: ({ sellerOrganizationName }) => sellerOrganizationName,
  },
  {
    title: 'Transactions',
    render: (detail) =>
      `${addCommas(detail.transactionCount)} (${(
        (detail.transactionCount / totalBuyerTransactions) *
        100
      ).toFixed(2)} %)`,
  },
];

export const rangeOptions = [
  { label: '3M', value: 3 },
  { label: '6M', value: 6 },
  { label: '9M', value: 9 },
  { label: '12M', value: 12 },
];
