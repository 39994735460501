import { AiOutlineDollar } from '@react-icons/all-files/ai/AiOutlineDollar';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { GiTakeMyMoney } from '@react-icons/all-files/gi/GiTakeMyMoney';
import { CgInsights } from '@react-icons/all-files/cg/CgInsights';
import { VscDebugDisconnect } from '@react-icons/all-files/vsc/VscDebugDisconnect';
import { MdPerson } from '@react-icons/all-files/md/MdPerson';
import { Link } from 'react-router-dom';
import { CgSearch } from '@react-icons/all-files/cg/CgSearch';
import { FaHandshake } from '@react-icons/all-files/fa/FaHandshake';
import { BsBuilding } from '@react-icons/all-files/bs/BsBuilding';
import { RiContactsBook2Line } from '@react-icons/all-files/ri/RiContactsBook2Line';
import { FaUserNinja } from '@react-icons/all-files/fa/FaUserNinja';

export const getSidebarNavItems = ({
  shouldDisableNavigation,
  companyType,
  isZorbaUser,
  featureFlags,
}) =>
  [
    {
      icon: <FaEye style={{ fontSize: '18px' }} />,
      label: shouldDisableNavigation ? (
        'My leads'
      ) : (
        <Link to="/">My leads</Link>
      ),
      disabled: shouldDisableNavigation,
    },
    {
      icon: <AiOutlineDollar style={{ fontSize: '18px' }} />,
      label: shouldDisableNavigation ? (
        'Top Buyers'
      ) : (
        <Link to="/top-buyers">Top Buyers</Link>
      ),
      disabled: shouldDisableNavigation,
    },
    ...(featureFlags?.transactionsReport?.isActive
      ? [
          {
            icon: <FaHandshake style={{ fontSize: '18px' }} />,
            label: shouldDisableNavigation ? (
              'Transactions'
            ) : (
              <Link to="/transactions">Transactions</Link>
            ),
            disabled: shouldDisableNavigation,
          },
        ]
      : []),
    ...(featureFlags?.marketOverviewReport?.isActive
      ? [
          {
            icon: <BsBuilding style={{ fontSize: '18px' }} />,
            label: shouldDisableNavigation ? (
              'Market view'
            ) : (
              <Link to="/market-view">Market overview</Link>
            ),
            disabled: shouldDisableNavigation,
          },
        ]
      : []),
    ...(companyType === 'lender'
      ? [
          {
            icon: <GiTakeMyMoney style={{ fontSize: '18px' }} />,
            label: shouldDisableNavigation ? (
              'Top SFR Lenders'
            ) : (
              <Link to="/top-lenders">Top SFR Lenders</Link>
            ),
            disabled: shouldDisableNavigation,
          },
        ]
      : []),
    ...(companyType === 'title_company'
      ? [
          {
            icon: <GiTakeMyMoney style={{ fontSize: '18px' }} />,
            label: shouldDisableNavigation ? (
              'Top Title Companies'
            ) : (
              <Link to="/top-title-companies">Top Title Companies</Link>
            ),
            disabled: shouldDisableNavigation,
          },
        ]
      : []),
    {
      icon: <CgInsights style={{ fontSize: '18px' }} />,
      label: shouldDisableNavigation ? (
        'AI Copilot'
      ) : (
        <Link to="/insights">AI Copilot</Link>
      ),
      disabled: shouldDisableNavigation,
    },
    {
      icon: <CgSearch style={{ fontSize: '18px' }} />,
      label: shouldDisableNavigation ? (
        'Buyer Search'
      ) : (
        <Link to="/search">Buyer Search</Link>
      ),
      disabled: shouldDisableNavigation,
    },
    {
      icon: <RiContactsBook2Line style={{ fontSize: '18px' }} />,
      label: shouldDisableNavigation ? (
        'Contacts'
      ) : (
        <Link to="/contacts">Contacts</Link>
      ),
      disabled: shouldDisableNavigation,
    },
    {
      icon: <FaUserNinja style={{ fontSize: '18px' }} />,
      label: shouldDisableNavigation ? (
        'Request logs'
      ) : (
        <Link to="/request-logs">Request logs</Link>
      ),
      disabled: shouldDisableNavigation,
    },
    ...(isZorbaUser
      ? [
          {
            icon: <MdPerson style={{ fontSize: '18px' }} />,
            label: <Link to="/company-transfers">Company transfers</Link>,
          },
        ]
      : []),
    {
      icon: <VscDebugDisconnect style={{ fontSize: '18px' }} />,
      label: <Link to="/settings">Settings</Link>,
    },
  ].map((navItem, index) => ({ ...navItem, key: (index + 1).toString() }));
