import {
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

export const MAP_PROPERTY_TYPE_TO_STRING = {
  44: 'Single Family Home',
  23: 'Multi Family Home',
  17: 'Vacant',
  21: 'Vacant',
};

export const getTableColumns = () => [
  {
    title: 'Seller',
    key: 'sellerName',
    render: ({ sellerName }) => sellerName,
  },
  {
    title: 'Buyer',
    key: 'buyerName',
    render: ({ buyerName }) => buyerName,
  },
  {
    title: 'Address',
    key: 'address',
    render: ({ address }) => address,
  },
  {
    title: 'State',
    key: 'state',
    render: ({ state }) => state,
  },
  {
    title: 'Title company name',
    key: 'titleCompanyName',
    render: ({ titleCompanyName }) =>
      titleCompanyName !== 'NONE LISTED ON DOCUMENT' ? titleCompanyName : '',
    sorter: (a, b, sortOrder) => {
      const nameA =
        a.titleCompanyName && a.titleCompanyName !== 'NONE LISTED ON DOCUMENT'
          ? a.titleCompanyName
          : '';
      const nameB =
        b.titleCompanyName && b.titleCompanyName !== 'NONE LISTED ON DOCUMENT'
          ? b.titleCompanyName
          : '';

      if (nameA === '' && nameB === '') return 0;
      if (nameA === '') return sortOrder === 'ascend' ? 1 : -1;
      if (nameB === '') return sortOrder === 'ascend' ? -1 : 1;

      return nameA.localeCompare(nameB);
    },
  },
  {
    title: 'Property Type',
    key: 'propertyType',
    render: ({ propertyType }) => MAP_PROPERTY_TYPE_TO_STRING[propertyType],
  },
  {
    title: 'Transaction amount',
    key: 'transactionAmount',
    render: ({ transactionAmount }) => getPriceText(transactionAmount),
  },
  {
    title: 'AVM',
    key: 'avm',
    render: ({ avm }) => getPriceText(avm),
  },
  {
    title: 'Transaction date',
    key: 'transactionDate',
    render: ({ transactionDate }) => getFormattedTime(transactionDate),
  },
];
