import { Card } from 'antd';
import { useState } from 'react';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { Spinner } from '../../../../../../components/layout';
import { AcquisitionsChart } from '../AcquisitionsChart';
import { OverviewHeader } from './Header';
import { TAB_TO_DATA_KEY } from './Header/constants';
import { useGetAccountEntityStats } from '../../../../../../queries/AccountEntity';
import { showErrorToast } from '../../../../../../services/notificationsService';

import './index.scss';

export const Overview = ({
  entityName,
  appliedFilters,
  accountDetailsData,
  isDetailsLoading,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const {
    data: accountStatsData,
    isLoading: isStatsLoading,
    isError: isStatsError,
  } = useGetAccountEntityStats({ entityName, appliedFilters });

  if (isStatsError) {
    showErrorToast('Fetching account stats failed!');
  }

  return (
    <>
      {!isDetailsLoading ? (
        <div className="stats">
          <Card>
            <h3>Acquisitions</h3>
            <h1>{accountDetailsData?.acquiredPropertiesCount || 0}</h1>
          </Card>
          <Card>
            <h3>Dispositions</h3>
            <h1>{accountDetailsData?.soldPropertiesCount || 0}</h1>
          </Card>
          <Card>
            <h3>Acquisitions GMV</h3>
            <h1>
              {getPriceText(accountDetailsData?.totalTransactionsAmount || 0)}
            </h1>
          </Card>
        </div>
      ) : (
        <Spinner />
      )}
      <div className="entities-and-chart-container">
        <Card
          title={
            <OverviewHeader activeTab={activeTab} setActiveTab={setActiveTab} />
          }
          className="acquisitions-chart-card"
        >
          <AcquisitionsChart
            isLoading={isStatsLoading}
            accountStatsData={accountStatsData}
            dataKey={TAB_TO_DATA_KEY[activeTab]}
          />
        </Card>
      </div>
    </>
  );
};
