export const PREDEFINED_REPORTS = {
  BY_KNOWN_ACCOUNTS: 'by_known_accounts',
  BY_ENTITY_NAME: 'by_entity_name',
};
export const getDefaultFilters = (query = '') => ({
  query,
});

export const rangeOptions = [
  { label: '1M', value: 1 },
  { label: '2M', value: 2 },
  { label: '3M', value: 3 },
  { label: '6M', value: 6 },
  { label: '12M', value: 12 },
];
export const getRangeDates = (selectedRange) => {
  const endDate = new Date();
  const startDate = new Date();

  startDate.setMonth(startDate.getMonth() - selectedRange);

  const formatDate = (date) =>
    `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

  const startRange = formatDate(startDate);
  const endRange = formatDate(endDate);

  return [startRange, endRange];
};
