import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { TopLendersReport } from './Components/TopLendersReport';

export default () => {
  useDocumentTitle('Top Lenders Report');
  useEffect(() => {
    reportScreen('top lenders report');
  }, []);

  return (
    <Page>
      <ContentHeader title="Top SFR Lenders report" />
      <TopLendersReport />
    </Page>
  );
};
