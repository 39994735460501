import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const discoverOrganizationContacts = async ({
  organizationId,
  isDiscoverClicked,
}) => {
  const response = await postRequest(
    '/data/discover-organization/get-organization-contacts',
    { organizationId, isDiscoverClicked },
  );

  const { data } = response.data;

  return data;
};
export const useDiscoverOrganizationContacts = ({
  organizationId,
  isDiscoverClicked,
}) =>
  useQuery({
    queryKey: ['contacts', organizationId, isDiscoverClicked],
    queryFn: () =>
      discoverOrganizationContacts({ organizationId, isDiscoverClicked }),
  });

export const getContactEmail = async ({ contacts }) => {
  const { data } = await postRequest(
    '/data/discover-organization/get-contact-email',
    {
      contacts,
    },
  );

  return data;
};

export const useGetContactEmail = (refetch, onSuccess) =>
  useMutation({
    mutationFn: getContactEmail,
    onSuccess: (data) => {
      onSuccess(data?.usedContactCredits);
      refetch();
    },
    onError: () => {
      showErrorToast('Fetching contact email failed');
    },
  });

export const reportContact = async ({ contactId }) => {
  const { data } = await postRequest(
    '/data/discover-organization/report-contact',
    {
      contactId,
    },
  );

  return data;
};

export const useReportContact = (refetch, onSuccess) =>
  useMutation({
    mutationFn: reportContact,
    onSuccess: () => {
      onSuccess();
      refetch();
    },
    onError: () => {
      showErrorToast('Reporting contact failed');
    },
  });

export const deleteContact = async (params) => {
  await postRequest('/data/discover-organization/delete-contact', params);
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteContact,
    onSuccess: () => {
      showSuccessToast('Contact deleted successfully');
      queryClient.invalidateQueries({
        queryKey: ['contacts'],
      });
    },
    onError: () => {
      showErrorToast('Deleting contact failed');
    },
  });
};

export const editContact = async (params) => {
  await postRequest('/data/discover-organization/edit-contact', params);
};

export const useEditContact = (handleCloseModal) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editContact,
    onSuccess: () => {
      showSuccessToast('Contact edited successfully');
      queryClient.invalidateQueries({
        queryKey: ['contacts'],
      });
      handleCloseModal();
    },
    onError: () => {
      showErrorToast('Editing contact failed');
    },
  });
};
