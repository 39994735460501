export const parameters = [
  '{{user_name}} - Your name',
  '{{user_first_name}} - Your first name',
  '{{user_last_name}} - Your last name',
  '{{contact_first_name}} - The first name of the contact',
  '{{contact_last_name}} - The last name of the contact',
  '{{contact_organization_name}} - Organization name of the contact',
  '{{acquiredProperties_total}} - Number of acquired properties in last 90 days',
  '{{acquisition_1.address}} - The address of the latest property they acquired',
  // '{{acquisition_1.city}} - The city of the latest property they acquired',
  '{{acquisition_1.state}} - The state of the latest property they acquired',
  // '{{acquisition_1.zip}} - The zip of the latest property they acquired',
  '{{acquisition_1.title_company_name}} - The title company name of the latest property they acquired',
  '{{acquisition_1.lender_name}} - The lender name of the latest property they acquired',
  '{{acquisition_2.address}} - The address of the second latest property they acquired',
  // '{{acquisition_2.city}} - The city of the second latest property they acquired',
  '{{acquisition_2.state}} - The state of the second latest property they acquired',
  // '{{acquisition_2.zip}} - The zip of the second latest property they acquired',
  '{{acquisition_2.title_company_name}} - The title company name of the second latest property they acquired',
  '{{acquisition_2.lender_name}} - The lender name of the second latest property they acquired',
];
