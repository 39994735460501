import { Table } from 'antd';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import { useGetRequestLogs } from '../../../../queries/RequestLogs';
import { getTableColumns } from './constants';

export const RequestLogs = () => {
  const { data, isLoading } = useGetRequestLogs();

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : 'max-content',
  };

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  return (
    <Table
      rowKey="_id"
      dataSource={data}
      loading={isLoading}
      columns={getTableColumns()}
      scroll={tableScroll}
      pagination={pagination}
    />
  );
};
