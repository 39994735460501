import { StateBuilder } from './Components/FiltersBuilder/StateBuilder';
import { PropertyTypeBuilder } from './Components/FiltersBuilder/PropertyTypeBuilder';
import { PurchasePriceBuilder } from './Components/FiltersBuilder/PurchasePriceBuilder';
import { LocationBuilder } from './Components/FiltersBuilder/LocationBuilder';

export const REPORT_FILTER_CONFIG = [
  {
    title: 'State',
    value: 'state',
    builder: StateBuilder,
  },
  {
    title: 'Property type',
    value: 'propertyType',
    builder: PropertyTypeBuilder,
  },
  {
    title: 'Purchase Price',
    value: 'purchasePrice',
    builder: PurchasePriceBuilder,
  },
  {
    title: 'Location',
    value: 'location',
    builder: LocationBuilder,
  },
];
