import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const parseDateString = (dateString) => dayjs(dateString);

export const ZorbaDateRangePicker = ({
  disabled,
  onChange,
  value,
  className,
  rangePresets = [],
}) => {
  const getValueAsDayJs = (stringValues) => {
    if (stringValues && stringValues.length === 2) {
      return [
        parseDateString(stringValues[0]),
        parseDateString(stringValues[1]),
      ];
    }
  };

  const rangePresetOptions = rangePresets.map((rangePreset) => ({
    label: rangePreset.label,
    value: [dayjs().add(-rangePreset.value, 'day'), dayjs()],
  }));

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      onChange(dateStrings.map((date) => dayjs(date).format('YYYY-MM-DD')));
    } else {
      onChange([]);
    }
  };

  const disabledDate = (current) =>
    current && (current < dayjs('2023-01-01') || current > dayjs());

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        className={`${className || ''}`}
        presets={rangePresetOptions}
        onChange={onRangeChange}
        value={getValueAsDayJs(value)}
        disabled={disabled}
        disabledDate={disabledDate}
        format="MM-DD-YYYY"
      />
    </Space>
  );
};
