import _ from 'lodash';
import * as types from './types';

export const resetAppliedFilters = () => (dispatch) => {
  dispatch({
    type: types.SET_APPLIED_FILTERS,
    payload: { appliedFiltersTransactions: {} },
  });
};

export const deleteAppliedFilter = (key) => (dispatch, getState) => {
  const previousAppliedFilters = getState().FiltersReducer
    .appliedFiltersTransactions;
  const newFilters = _.omit(previousAppliedFilters, key);

  dispatch({
    type: types.SET_APPLIED_FILTERS,
    payload: { appliedFiltersTransactions: newFilters },
  });
};

export const removeStateFromAppliedFilters = (state) => (
  dispatch,
  getState,
) => {
  const previousAppliedFilters = getState().FiltersReducer
    .appliedFiltersTransactions;
  const previousAppliedStates = getState().FiltersReducer
    .appliedFiltersTransactions.state;

  const radioValue = Object.keys(
    getState().FiltersReducer.appliedFiltersTransactions.state,
  )[0];

  const updatedArray = previousAppliedStates[radioValue].filter(
    (el) => el !== state,
  );

  if (updatedArray.length) {
    dispatch({
      type: types.SET_APPLIED_FILTERS,
      payload: {
        currentPage: 0,
        appliedFiltersTransactions: {
          ...previousAppliedFilters,
          state: { [radioValue]: updatedArray },
        },
      },
    });
  } else {
    dispatch(deleteAppliedFilter('state'));
  }
};

export const removePropertyTypeFromAppliedFilters = (propertyType) => (
  dispatch,
  getState,
) => {
  const previousAppliedFilters = getState().FiltersReducer
    .appliedFiltersTransactions;
  const previousAppliedPropertyTypes = getState().FiltersReducer
    .appliedFiltersTransactions.propertyType;

  const radioValue = Object.keys(
    getState().FiltersReducer.appliedFiltersTransactions.propertyType,
  )[0];

  const updatedArray = previousAppliedPropertyTypes[radioValue].filter(
    (el) => el !== propertyType,
  );

  if (updatedArray.length) {
    dispatch({
      type: types.SET_APPLIED_FILTERS,
      payload: {
        currentPage: 0,
        appliedFiltersTransactions: {
          ...previousAppliedFilters,
          propertyType: { [radioValue]: updatedArray },
        },
      },
    });
  } else {
    dispatch(deleteAppliedFilter('propertyType'));
  }
};

export const removeTransactionTypeFromAppliedFilters = () => (dispatch) => {
  dispatch(deleteAppliedFilter('transactionType'));
};

export const setAppliedFilters = (key, filter) => (dispatch, getState) => {
  const previousAppliedFilters = getState().FiltersReducer
    .appliedFiltersTransactions;

  dispatch({
    type: types.SET_APPLIED_FILTERS,
    payload: {
      currentPage: 0,
      appliedFiltersTransactions: { ...previousAppliedFilters, [key]: filter },
    },
  });
};
