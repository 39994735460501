import { Tag, Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { RiArrowGoBackLine } from '@react-icons/all-files/ri/RiArrowGoBackLine';
import { AiOutlineSave } from '@react-icons/all-files/ai/AiOutlineSave';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { capitalizeFirstLetter } from '@zorba-shared/core/Services/textService';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useState } from 'react';
import { isMobile } from '../../../services/clientService';
import {
  BUTTON_VARIANT_LIGHT,
  BUTTON_VARIANT_LINK,
} from '../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../components/forms';
import {
  propertyTypeOptions,
  statesOptions,
} from '../../drawer/FiltersDrawer/Components/FiltersBuilder/constants';
import {
  removePropertyTypeFromAppliedFilters,
  removeStateFromAppliedFilters,
  removeTransactionTypeFromAppliedFilters,
  resetAppliedFilters,
  setFiltersFromView,
} from '../../../reducers/Filters/actions';
import { setDrawerState } from '../../../reducers/Drawer/actions';
import { FILTERS_DRAWER } from '../../../reducers/Drawer/types';
import { useGetViews, useUpdateView } from '../../../queries/View';
import { FiltersDrawer } from '../../drawer/FiltersDrawer';

import './index.scss';

export const ViewPanel = ({
  children,
  saveMode = true,
  availableFilters = [],
}) => {
  const dispatch = useDispatch();
  const { appliedFilters: storedAppliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const appliedFilters = availableFilters.length
    ? Object.fromEntries(
        Object.entries(storedAppliedFilters).filter(([key]) =>
          availableFilters.includes(key),
        ),
      )
    : storedAppliedFilters;

  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [createViewMode, setCreateViewMode] = useState(false);
  const { data: views } = useGetViews();
  const handleClearState = (state) => {
    dispatch(removeStateFromAppliedFilters(state));
  };

  const handleClearPropertyType = (propertyType) => {
    dispatch(removePropertyTypeFromAppliedFilters(propertyType));
  };

  const handleClearTransactionType = () => {
    dispatch(removeTransactionTypeFromAppliedFilters());
  };

  const handleClearAllFilters = () => {
    dispatch(resetAppliedFilters());
  };

  const openFiltersDrawer = () => {
    dispatch(setDrawerState(FILTERS_DRAWER, true));
  };

  const defaultView =
    userDetails?.defaultOrganization?.dataSubscription?.defaultView;
  const defaultFilters = views?.find((view) => view._id === defaultView)
    ?.appliedFilters;

  const isViewChanged = _.isEqual(defaultFilters, appliedFilters);

  const resetView = () => {
    const selectedViewFilters = views.find((view) => view._id === defaultView)
      .appliedFilters;
    dispatch(setFiltersFromView(selectedViewFilters));
  };

  const handleCreateView = () => {
    setCreateViewMode(true);
    dispatch(setDrawerState(FILTERS_DRAWER, true));
  };

  const { mutate: updateView, isPending } = useUpdateView();
  const handleUpdateView = () => {
    updateView({ viewId: defaultView, appliedFilters });
  };

  return (
    <>
      <div className="applied-filters-container">
        <div className="tags-container">
          {(
            appliedFilters?.state?.isAnyOf ||
            appliedFilters?.state?.isNoneOf ||
            []
          ).map((el) => (
            <Tag className="tag-close" key={uuid()}>
              {statesOptions.find((option) => option.value === el)?.label}
              <IoMdClose onClick={() => handleClearState(el)} />
            </Tag>
          ))}
          {(
            appliedFilters?.propertyType?.isAnyOf ||
            appliedFilters?.propertyType?.isNoneOf ||
            []
          ).map((el) => (
            <Tag className="tag-close" key={uuid()}>
              {propertyTypeOptions.find((option) => option.value === el)?.label}
              <IoMdClose onClick={() => handleClearPropertyType(el)} />
            </Tag>
          ))}
          {appliedFilters?.transactionType && (
            <Tag className="tag-close" key={uuid()}>
              {capitalizeFirstLetter(appliedFilters.transactionType)}
              <IoMdClose onClick={() => handleClearTransactionType()} />
            </Tag>
          )}
        </div>
        {Object.keys(appliedFilters).length > 0 && (
          <ZorbaButton
            variant={BUTTON_VARIANT_LINK}
            onClick={handleClearAllFilters}
            fullWidth={isMobile()}
          >
            Clear all
          </ZorbaButton>
        )}
        <ZorbaButton
          variant={BUTTON_VARIANT_LIGHT}
          onClick={openFiltersDrawer}
          fullWidth={isMobile()}
          icon={<BiFilterAlt />}
        >
          {Object.keys(appliedFilters).length
            ? `Applied filters (${Object.keys(appliedFilters).length})`
            : 'Filters'}
        </ZorbaButton>
        {saveMode && (
          <>
            <Tooltip title="Reset view">
              <div>
                <ZorbaButton
                  fullWidth={false}
                  variant={BUTTON_VARIANT_LIGHT}
                  disabled={isViewChanged || !defaultView}
                  onClick={resetView}
                >
                  <RiArrowGoBackLine />
                </ZorbaButton>
              </div>
            </Tooltip>
            <Tooltip title="Save view">
              <div>
                <ZorbaButton
                  fullWidth={false}
                  variant={BUTTON_VARIANT_LIGHT}
                  disabled={isViewChanged || isPending || !defaultView}
                  onClick={handleUpdateView}
                  loading={isPending}
                >
                  <AiOutlineSave />
                </ZorbaButton>
              </div>
            </Tooltip>
            <Tooltip title="Create view">
              <div>
                <ZorbaButton
                  fullWidth={false}
                  variant={BUTTON_VARIANT_LIGHT}
                  onClick={handleCreateView}
                >
                  <AiOutlinePlus />
                </ZorbaButton>
              </div>
            </Tooltip>
          </>
        )}
        {children}
      </div>
      <FiltersDrawer
        createViewMode={createViewMode}
        setCreateViewMode={setCreateViewMode}
        availableFilters={availableFilters}
      />
    </>
  );
};
