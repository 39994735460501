import { Table } from 'antd';
import { getTableColumns } from './constants';
import { isMobile } from '../../../../../../services/clientService';

export const AcquiredPropertiesTable = ({
  isLoading,
  data,
  isMultiFamilySelected,
  isVacantSelected,
}) => {
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  return (
    <Table
      rowKey="address"
      loading={isLoading}
      columns={getTableColumns({ isMultiFamilySelected, isVacantSelected })}
      dataSource={data?.acquiredProperties || []}
      scroll={tableScroll}
    />
  );
};
