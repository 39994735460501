export const TABS = [
  {
    key: '1',
    label: 'Acquisitions',
  },
  {
    key: '2',
    label: 'Dispositions',
  },
];

export const TAB_TO_DATA_KEY = {
  1: 'Acquisitions',
  2: 'Dispositions',
};
