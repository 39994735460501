import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import { v4 as uuid } from 'uuid';
import { ZorbaButton } from '../../../../../components/forms';
import { BUTTON_VARIANT_LIGHT } from '../../../../../components/forms/ZorbaButton/constants';

export const FilterList = ({
  filterConfig,
  handleBack,
  selectFilter,
  availableFilters,
}) => {
  const filtersList = availableFilters.length
    ? filterConfig.filter((filter) =>
        availableFilters.some(
          (availableFilter) => availableFilter === filter.value,
        ),
      )
    : filterConfig;

  return (
    <div className="filter-report">
      <ZorbaButton
        fullWidth={false}
        onClick={handleBack}
        icon={<BiArrowBack />}
        variant={BUTTON_VARIANT_LIGHT}
        className="btn-filter-back"
      >
        Back
      </ZorbaButton>

      {filtersList?.map((item) => (
        <div
          className="filter-item"
          key={uuid()}
          onClick={() => selectFilter(item.value)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
