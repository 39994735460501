import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { CreateWatchListModal } from './Components/CreateWatchListModal';
import { useGetWatchLists } from '../../../../../../queries/Watchlist';
import { Spinner } from '../../../../../../components/layout';

import './index.scss';

export const MyLists = ({ setSelectedList, selectedList }) => {
  const { data: lists, isLoading } = useGetWatchLists();
  const history = useHistory();

  const handleChange = (list) => {
    setSelectedList(list);
    history.push(`?watchlist=${list.listName}`);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="lists-container">
      {(lists || []).map((list) => (
        <span
          key={uuid()}
          onClick={() => handleChange(list)}
          className={selectedList?.listName === list.listName ? 'active' : ''}
        >
          {list.listName}
        </span>
      ))}
      <CreateWatchListModal />
    </div>
  );
};
