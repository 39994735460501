import { Tabs } from 'antd';
import { getTabs } from './constants';
import {
  useGetMappingRequests,
  useRequestContactMapping,
} from '../../../../../../queries/MappingRequests';

import './index.scss';

export const AccountTabs = ({ setActiveTab, activeTab, entityName }) => {
  const {
    mutate: handleRequestContact,
    isPending: isRequestingContacts,
  } = useRequestContactMapping();

  const {
    data: mappingRequests,
    isLoading: isFetchingMappingRequests,
  } = useGetMappingRequests();

  const requestedContacts = (mappingRequests || [])
    .filter((request) => request.kind === 'contact')
    .map((request) => request.entityName);

  const handleOnChange = (tabKey) => {
    if (tabKey === '4') {
      handleRequestContact({ entityName });
    } else {
      setActiveTab(tabKey);
    }
  };

  const isContactRequested =
    isRequestingContacts ||
    isFetchingMappingRequests ||
    requestedContacts.some((entity) => entity === entityName);

  return (
    <Tabs
      items={getTabs(isContactRequested)}
      onChange={(tabKey) => handleOnChange(tabKey)}
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      className="account-tabs"
    />
  );
};
