import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getUserData, setGuest } from '../reducers/Auth/actions';
import {
  getAuthToken,
  postRefreshTasks,
  QUERY_PARAM_FORCE_AUTH,
  QUERY_PARAM_USER_ID,
  QUERY_PARAM_MAGIC_TOKEN,
} from '../services/authService';
import { getQueryParam } from '../services/clientService';
import { buildLoginUrlWithRedirect } from '../services/urlBuilderService';

const PublicRoute = (props) => {
  const {
    isAppLoading,
    userDetails,
    component: Component,
    path,
    sidebar,
    isInit,
    history,
    pageName,
    ...rest
  } = props;
  useEffect(() => {
    postRefreshTasks();
  }, []);
  useEffect(() => {
    const queryUserId = getQueryParam(QUERY_PARAM_USER_ID);
    const queryForceAuth = getQueryParam(QUERY_PARAM_FORCE_AUTH);
    const authToken = getAuthToken();
    const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);

    if (!isInit) {
      if (!userDetails && !isAppLoading && (authToken || magicToken)) {
        let placeFriendlyUrl;

        if (window.location.pathname.startsWith('/p/')) {
          placeFriendlyUrl = window.location.pathname
            .substring(1)
            .replace('p', '');
        }
        props.getUserData(queryUserId, placeFriendlyUrl);
      } else if (!isAppLoading && queryForceAuth) {
        props.push(buildLoginUrlWithRedirect());
      } else {
        props.setGuest();
      }
    }
  }, [userDetails, isAppLoading, isInit]);

  const render = () =>
    isInit && !isAppLoading ? (
      <Component
        {...props}
        sidebar={userDetails ? sidebar : false}
        pageName={pageName}
      />
    ) : (
      'Loading...'
    );

  return <Route path={path} render={render} {...rest} />;
};
const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, isAppLoading, isInit } = AuthReducer;
  return {
    userDetails,
    isAppLoading,
    isInit,
  };
};
const mapDispatchToProps = {
  getUserData,
  push,
  setGuest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
