export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LATEST_NOTIFICATIONS = 'SET_LATEST_NOTIFICATIONS';
export const PROVIDER_LOGIN = 'PROVIDER_LOGIN';
export const SET_GUEST = 'SET_GUEST';
export const SET_AUTH_LOADING_STATE = 'SET_AUTH_LOADING_STATE';
export const LOCAL_AUTH = 'LOCAL_AUTH';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export const SET_IS_ADDING_NEW_MEMBERS = 'SET_IS_ADDING_NEW_MEMBERS';

export const SET_IS_SUPER_USER = 'SET_IS_SUPER_USER';
