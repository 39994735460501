import { Card } from 'antd';
import { showErrorToast } from '../../../../../../services/notificationsService';
import { useGetTitleEntityClients } from '../../../../../../queries/TitleEntity';
import { ClientsTable } from '../ClientsTable';

export const Clients = ({
  selectedRange,
  titleCompanyName,
  appliedFilters,
}) => {
  const {
    data: clientsData,
    isLoading: isClientsDataLoading,
    isError: isClientsDataError,
  } = useGetTitleEntityClients({
    selectedRange,
    titleCompanyName,
    appliedFilters,
  });

  if (isClientsDataError) {
    showErrorToast('Fetching clients failed!');
  }

  return (
    <>
      <Card title={`Clients (${clientsData?.length || 0})`}>
        <ClientsTable data={clientsData} isLoading={isClientsDataLoading} />
      </Card>
    </>
  );
};
