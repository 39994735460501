import { useEffect, useState } from 'react';
import { Divider, Popconfirm, Table, Tooltip } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import SearchCard from './Components/SearchCard';
import { postRequest } from '../../services/requestService';
import { HARD_CODED_ORGANIZATIONS } from './constants';
import { useGetWatchLists } from '../../queries/Watchlist';
import { setModalState } from '../../reducers/Modal/actions';
import {
  MODAL_ADD_ACCOUNT_TO_WATCHLIST,
  MODAL_CREATE_ENTITY,
  MODAL_CREATE_ORGANIZATION,
} from '../../reducers/Modal/types';
import { AddAccountToWatchListModal } from '../TopBuyersReportPage/Components/TopBuyersReport/Components/AddAccountToWatchListModal';
import { ZorbaButton } from '../../components/forms';
import { BUTTON_VARIANT_LIGHT } from '../../components/forms/ZorbaButton/constants';
import { CreateOrganizationModal } from './Components/CreateOrganizationModal';
import { CreateEntityModal } from './Components/CreateEntityModal';
import { capitalizeEveryWord } from '../../services/textService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

import './index.scss';

export default () => {
  useDocumentTitle('Top Buyers Report');
  const [buyers, setBuyers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(false);
  useEffect(() => {
    reportScreen('buyer_search');
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: lists } = useGetWatchLists();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');

  const [selectedAccountId, setSelectedAccountId] = useState('');
  const navigateToWatchlist = () => {
    history.push('/');
  };

  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');

  const onSearch = async (searchParams) => {
    setLoading(true);
    const res = await postRequest('/data/buyer-search', searchParams);
    setBuyers(res?.data?.buyers || []);
    setLoading(false);
  };

  const openAddAccountModal = (accountId) => {
    dispatch(setModalState(MODAL_ADD_ACCOUNT_TO_WATCHLIST, true));
    setSelectedAccountId(accountId);
  };

  const openCreateOrganizationModal = () => {
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION, true));
  };
  const openCreateEntityModal = () => {
    dispatch(setModalState(MODAL_CREATE_ENTITY, true));
  };

  const handleEntitySelect = (entityName) => {
    setSelectedEntity(entityName);
    dispatch(setModalState(MODAL_CREATE_ENTITY, true));
  };

  const handleBlackListEntity = async (entityName, organizationId) => {
    try {
      await postRequest('/data/entity/blacklist-entity', {
        entityName,
        organizationId,
      });
      showSuccessToast('Entity successfully blacklisted');
    } catch (error) {
      showErrorToast('Blacklisting entity failed');
    }
  };

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  return (
    <Page className="buyer-search">
      <ContentHeader title="Buyer Search" />
      {isZorbaUser && (
        <div className="admin-container">
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_LIGHT}
            onClick={openCreateEntityModal}
          >
            Create entity
          </ZorbaButton>
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_LIGHT}
            onClick={openCreateOrganizationModal}
          >
            Create organization
          </ZorbaButton>
        </div>
      )}
      <SearchCard onSearch={onSearch} loading={loading} query={query} />
      <Divider />
      {!buyers?.length && (
        <div className="top-accounts">
          {HARD_CODED_ORGANIZATIONS.map((buyer) => (
            <div className="top-account-item" key={buyer.value}>
              <Link
                target="_blank"
                to={`/top-buyers/${buyer.value}/${buyer.text}`}
              >
                {buyer.text}
              </Link>
            </div>
          ))}
        </div>
      )}
      <Table
        loading={loading}
        dataSource={buyers}
        style={{ width: '80%' }}
        pagination={pagination}
        columns={[
          {
            key: 'name',
            title: 'Company name',
            render: (record) => {
              if (record.organizationId)
                return (
                  <Link
                    className="org-name"
                    target="_blank"
                    to={`/top-buyers/${
                      record.organizationId
                    }/${encodeURIComponent(record.organizationName)}`}
                  >
                    {record.organizationName}
                  </Link>
                );
              return (
                <div>
                  {record.organizationName
                    ? capitalizeEveryWord(record.organizationName)
                    : ''}
                </div>
              );
            },
          },
          {
            title: 'Entity name',
            render: ({ entityName, organizationId }) => (
              <div>
                <Link
                  className="org-name"
                  target="_blank"
                  to={`/top-buyers/${encodeURIComponent(entityName)}`}
                >
                  {entityName}
                </Link>
                {isZorbaUser && !organizationId && (
                  <FaPlusCircle
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => handleEntitySelect(entityName)}
                  />
                )}
              </div>
            ),
          },
          {
            title: 'Watchlist',
            key: 'isOnWatchlist',
            render: ({ organizationId }) => {
              if (!lists?.length)
                return (
                  <Tooltip title="Create a watchlist to easily track your favorite organizations">
                    <span
                      className="save-on-watchlist"
                      onClick={navigateToWatchlist}
                    >
                      Save
                    </span>
                  </Tooltip>
                );

              if (
                lists.every((list) => list.accountIds.includes(organizationId))
              )
                return '';

              return (
                <span
                  className="save-on-watchlist"
                  onClick={() => openAddAccountModal(organizationId)}
                >
                  Save
                </span>
              );
            },
            align: 'center',
          },
          ...(isZorbaUser
            ? [
                {
                  title: 'Blacklist',
                  key: 'isBlacklisted',
                  render: ({ entityName, isBlacklisted, organizationId }) => {
                    if (!isBlacklisted && organizationId) {
                      return (
                        <Popconfirm
                          title={`You are about to blacklist ${entityName}`}
                          onConfirm={() =>
                            handleBlackListEntity(entityName, organizationId)
                          }
                          okText="Confirm"
                          cancelText="Cancel"
                        >
                          <span className="save-on-watchlist">Blacklist</span>
                        </Popconfirm>
                      );
                    }

                    return '';
                  },
                  align: 'center',
                },
              ]
            : []),
        ]}
      />
      <AddAccountToWatchListModal
        selectedAccountId={selectedAccountId}
        setSelectedAccountId={setSelectedAccountId}
      />
      <CreateOrganizationModal />
      <CreateEntityModal entity={selectedEntity} />
    </Page>
  );
};
