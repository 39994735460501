import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import ContentHeader from '../../components/ContentHeader';
import { Page } from '../../components/layout';
import { Watchlist } from './Components/Watchlist';
import { useGetWatchLists } from '../../queries/Watchlist';

export default () => {
  useDocumentTitle('Watchlist');
  useEffect(() => {
    reportScreen('watchlist page');
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const watchlist = queryParams.get('watchlist');
  const history = useHistory();
  const { data: lists, isLoading } = useGetWatchLists();
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    if (lists?.length) {
      const matchingList = lists.find((list) => list.listName === watchlist);

      if (matchingList) {
        setSelectedList(matchingList);
      } else if (!watchlist) {
        const defaultList = lists[0];
        setSelectedList(defaultList);
        history.push(`?watchlist=${defaultList.listName}`);
      }
    }
  }, [lists, watchlist, history]);

  useEffect(() => {
    if (selectedList && selectedList.listName !== watchlist) {
      history.push(`?watchlist=${selectedList.listName}`);
    }
  }, [selectedList, watchlist, history]);

  return (
    <Page>
      {!isLoading && (
        <ContentHeader
          title={`Watchlist ${selectedList ? '-' : ''} ${
            selectedList?.listName || ''
          }`}
        />
      )}
      <Watchlist
        setSelectedList={setSelectedList}
        selectedList={selectedList}
      />
    </Page>
  );
};
