// import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import { v4 as uuid } from 'uuid';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

export const getTableColumns = (isZorbaUser, deleteEntity) => [
  {
    title: 'Entity Name',
    key: 'entityName',
    render: ({ name }) => name,
  },
  // {
  //   title: 'Acquired Properties',
  //   key: 'acquiredProperties',
  //   render: ({ acquiredProperties }) => acquiredProperties,
  // },
  // {
  //   title: 'Last Acquisition',
  //   key: 'lastAcquisition',
  //   render: ({ lastTransactionDate }) => getFormattedTime(lastTransactionDate),
  // },
  ...(isZorbaUser
    ? [
        {
          title: '',
          key: uuid(),
          render: ({ name, organizationId }) => (
            <BiTrash
              style={{ cursor: 'pointer' }}
              onClick={() => deleteEntity({ entityName: name, organizationId })}
            />
          ),
        },
      ]
    : []),
];
