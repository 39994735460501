import { useEffect } from 'react';
import { Page } from '../../components/layout';
import ContentHeader from '../../components/ContentHeader';
import { Insights } from './Components/Insights';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';

const InsightsPage = () => {
  useDocumentTitle('Insights');
  useEffect(() => {
    reportScreen('insights page');
  }, []);

  return (
    <Page>
      <ContentHeader title="AI Copilot" />
      <Insights />
    </Page>
  );
};

export default InsightsPage;
