import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaLink } from '@react-icons/all-files/fa/FaLink';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { useGetWatchLists } from '../../../../../../queries/Watchlist';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_ADD_ACCOUNT_TO_WATCHLIST } from '../../../../../../reducers/Modal/types';
import { AddAccountToWatchListModal } from '../../../../../TopBuyersReportPage/Components/TopBuyersReport/Components/AddAccountToWatchListModal';

import './index.scss';

export const Header = ({ buyerName, accountDetailsData, organizationId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedAccountId, setSelectedAccountId] = useState();
  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/search');
    }
  };
  const { data: lists, isLoading } = useGetWatchLists();
  const openAddAccountModal = () => {
    setSelectedAccountId(organizationId);
    dispatch(setModalState(MODAL_ADD_ACCOUNT_TO_WATCHLIST, true));
  };

  const renderSaveToWatchlistIcon = () => {
    if (
      isLoading ||
      !accountDetailsData ||
      lists.every((list) => list.accountIds.includes(organizationId))
    ) {
      return '';
    }

    if (!isLoading && !lists?.length) {
      return (
        <Tooltip title="Create a watchlist to easily track your favorite organizations">
          <FaPlus
            style={{
              fontSize: 17,
              color: '#646464',
              margin: '2px 5px 0',
              marginTop: 2,
              cursor: 'pointer',
            }}
            onClick={() => history.push('/')}
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Save to watchlist">
        <FaPlus
          style={{
            fontSize: 17,
            color: '#646464',
            margin: '2px 5px 0',
            marginTop: 2,
            cursor: 'pointer',
          }}
          onClick={openAddAccountModal}
        />
      </Tooltip>
    );
  };

  return (
    <div className="back-container">
      <div className="back-btn" onClick={goBack}>
        <div className="circle-icon">
          <MdArrowBack size={30} color="#7299EC" />
        </div>
        <span className="back-text">Back</span>
      </div>
      <h1 className="buyer-name">{buyerName}</h1>
      {renderSaveToWatchlistIcon()}
      {accountDetailsData?.organization?.linkedin_page && (
        <a
          href={accountDetailsData?.organization?.linkedin_page}
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin
            style={{ fontSize: 25, margin: '2px 5px 0', marginTop: 2 }}
          />
        </a>
      )}

      {accountDetailsData?.organization?.website_url && (
        <a
          href={accountDetailsData?.organization?.website_url}
          target="_blank"
          rel="noreferrer"
        >
          <FaLink
            style={{
              fontSize: 17,
              color: '#333',
              margin: '2px 5px 0',
              marginTop: 2,
            }}
          />
        </a>
      )}
      <AddAccountToWatchListModal
        selectedAccountId={selectedAccountId}
        setSelectedAccountId={setSelectedAccountId}
      />
    </div>
  );
};
