import Cookies from 'js-cookie';

const BASE_URL_BY_ENV = {
  development: 'https://localhost:5001',
  staging: 'https://stgapi.getzorba.com',
  production: 'https://api.getzorba.com',
};

export const isDev = () =>
  ['development', 'staging', null, undefined].includes(
    process.env.REACT_APP_HOST_ENV,
  );

export const getEnvName = () => process.env.REACT_APP_HOST_ENV || 'development';

export const BASE_URL = BASE_URL_BY_ENV[getEnvName()];

const isClientSide = () => typeof window !== 'undefined' && window.document;

if (isClientSide()) {
  window.URLSearchParams =
    window.URLSearchParams ||
    function (searchString) {
      const self = this;
      self.searchString = searchString;
      self.get = function (name) {
        const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(
          self.searchString,
        );
        if (results == null) {
          return null;
        }
        return decodeURI(results[1]) || 0;
      };
    };

  const envName = getEnvName();
  if (envName !== 'production' && document.title[0] !== '[') {
    document.title = `[${envName}] ${document.title}`;
  }
}

export const isMobile = () => {
  try {
    if (!navigator) return false;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  } catch (e) {
    return false;
  }
};

export const isSafari = () =>
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const isIE = () => /MSIE|Trident|Edg/.test(navigator.userAgent);

export const getQueryParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get(name);
  return myParam || undefined;
};

export const removeCookie = (cookieName, domain) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
};

export const getAndDeleteCookie = (name) => {
  const cookieValue = Cookies.get(name);
  if (cookieValue) {
    removeCookie(name, '.getzorba.com');
  }

  return cookieValue;
};
