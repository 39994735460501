import { Link } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';

export const getTableColumns = ({
  watchlistName,
  handleRemoveFromWatchList,
}) => [
  {
    title: 'Company',
    key: 'name',
    render: ({ id, name }) => (
      <Link className="buyer-name" to={`/top-buyers/${id}/${name}`}>
        {name}
      </Link>
    ),
  },
  {
    title: 'Markets',
    key: 'states',
    render: ({ states }) => {
      if (states.length <= 8) {
        return states.join(', ');
      }
      const displayed = states.slice(0, 8).join(', ');
      const moreCount = states.length - 8;
      return `${displayed} and ${moreCount} more`;
    },
  },
  {
    title: 'Acquired properties',
    key: 'acquired',
    render: ({ acquired }) => acquired,
  },
  {
    title: 'Sold properties',
    key: 'sold',
    render: ({ sold }) => sold,
  },
  {
    title: '',
    key: 'deleteIcon',
    render: ({ id, name }) => (
      <Popconfirm
        title={`You are about to remove ${name} from the ${watchlistName} list`}
        onConfirm={() => handleRemoveFromWatchList(id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <FaTrash style={{ cursor: 'pointer' }} />
      </Popconfirm>
    ),
  },
];
