import {
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

export const getTableColumns = () => [
  {
    title: 'Date',
    render: ({ transactionDate }) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {getFormattedTime(transactionDate)}
      </div>
    ),
    width: 250,
  },
  {
    title: 'Buyer',
    render: ({ buyer }) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {buyer}
      </div>
    ),
    width: 350,
  },
  {
    title: 'Seller',
    render: ({ seller }) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {seller}
      </div>
    ),
    width: 350,
  },
  {
    title: 'Address',
    render: ({ address }) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {address}
      </div>
    ),
    width: 400,
  },
  {
    title: 'Amount',
    render: ({ transactionAmount }) => getPriceText(transactionAmount),
  },
];
