import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import { ConfigProvider } from 'antd';
import createStore, { history } from './configureStore';
import Routes from './routes';
import { initLoggers } from './services/loggingService';
import { Theme } from './constants/theme';
import { initAnalytics } from './services/analyticsService';
import { setInitialClientUrl } from './services/userTrackingService';

initLoggers();

const createdStore = createStore(history);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = (props) => {
  useEffect(() => {
    initAnalytics();
    setInitialClientUrl();
  }, []);

  return (
    <ConfigProvider theme={{ token: Theme() }}>
      <QueryClientProvider client={queryClient}>
        <Provider store={createdStore}>
          <ConnectedRouter history={history}>
            <ToastContainer />
            <Routes {...props} />
          </ConnectedRouter>
        </Provider>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default Sentry.withProfiler(App);
