import { Card } from 'antd';
import { v4 as uuid } from 'uuid';
import {
  COMPANY_POSITION_OPTIONS,
  DESCRIBE_YOUR_COMPANY_OPTIONS,
} from './constants';

import './index.scss';

export default (props) => {
  const {
    params: { companyType, position },
    validationObj,
  } = props;
  const onChange = (value, field) => {
    props.onChange(field, value);
  };

  return (
    <div className="onboarding-step organization-details">
      <div className="company-type-container">
        <label className="question-label">
          What’s the best way to describe your company?
        </label>
        <div className="card-container">
          {DESCRIBE_YOUR_COMPANY_OPTIONS.map((option) => (
            <Card
              key={uuid()}
              className={`card ${
                companyType === option.value ? 'active' : ''
              }`}
              hoverable
              onClick={() => {
                console.log(option.value)
                onChange(option.value, 'companyType')
              }}
            >
              <span className="card-description">{option.description}</span>
              <span className="card-label">{option.label}</span>
            </Card>
          ))}
        </div>
        {validationObj.companyType && (
          <span className="error">{validationObj.companyType}</span>
        )}
      </div>
      <div className="position-container">
        <label className="question-label">
          What’s your position in the company?
        </label>
        <div className="card-container">
          {COMPANY_POSITION_OPTIONS.map((option) => (
            <Card
              key={uuid()}
              className={`card ${position === option.value ? 'active' : ''}`}
              hoverable
              onClick={() => onChange(option.value, 'position')}
            >
              <span className="card-label">{option.label}</span>
              <span className="card-title">{option.title}</span>
            </Card>
          ))}
        </div>
        {validationObj.position && (
          <span className="error">{validationObj.position}</span>
        )}
      </div>
    </div>
  );
};
