export const DESCRIBE_YOUR_COMPANY_OPTIONS = [
  {
    label: 'Lender',
    description:
      'You lend capital to investors and looking to grow your borrower list.',
    value: 'lender',
  },
  {
    label: 'Title company',
    description: `You're looking to connect with SFR investors and monitor your existing clients' activity.`,
    value: 'title_company',
  },
  {
    label: 'Brokerage',
    description:
      'You’re looking to sell properties to the most active investors in today’s market.',
    value: 'brokerage',
  },
  {
    label: 'Other',
    description: 'You provide services to asset mangers and investors.',
    value: 'other',
  },
];

export const COMPANY_POSITION_OPTIONS = [
  {
    label: 'I work in ',
    title: 'Sales',
    value: 'sales',
  },
  {
    label: 'I work in ',
    title: 'Marketing',
    value: 'marketing',
  },
  {
    label: 'I work in ',
    title: 'Operations',
    value: 'operations',
  },
  {
    label: 'I am a ',
    title: 'Founder / Revenue Leader',
    value: 'founder_revenue_leader',
  },
  {
    label: 'I work in ',
    title: 'Account Management',
    value: 'account_management',
  },
  {
    label: 'None of these sound like my department',
    value: 'none',
  },
];
