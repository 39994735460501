export const getTabs = () => [
  {
    key: '1',
    label: 'Overview',
  },
  {
    key: '2',
    label: 'Investor Client List',
  },
  {
    key: '3',
    label: 'Transactions',
  },
];
