import { useIsFetching, useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getTopTitleCompanies = async (searchCriteria = {}) => {
  const res = await postRequest(
    '/data/top-title-companies-report',
    searchCriteria,
  );

  const { data } = res.data;

  return data;
};

export const useGetTopTitleCompanies = ({
  selectedMonthRange,
  appliedFilters,
  isMounted,
}) => {
  const isQueryEnabled = useIsFetching({ queryKey: ['views'] }) === 0;

  return useQuery({
    queryKey: ['topTitleCompanies', selectedMonthRange, appliedFilters],
    queryFn: () => getTopTitleCompanies({ selectedMonthRange, appliedFilters }),
    enabled: isQueryEnabled && isMounted,
  });
};
