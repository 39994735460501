import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setModalState } from '../../../../../../../../reducers/Modal/actions';
import { MODAL_CREATE_ORGANIZATION_REGEX } from '../../../../../../../../reducers/Modal/types';
import {
  ZorbaInput,
  ZorbaButton,
} from '../../../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../../../components/forms/ZorbaButton/constants';
import { useCreateOrganizationRegex } from '../../../../../../../../queries/Account';

export const CreateOrganizationRegexModal = ({
  organizationId,
  organizationName,
  organizationRegexesData = [],
}) => {
  const { isCreateOrganizationRegexModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [regex, setRegex] = useState('');

  const handleCloseModal = () => {
    setRegex('');
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION_REGEX, false));
  };

  const {
    mutate: createOrganizationRegex,
    isPending,
  } = useCreateOrganizationRegex();
  const handleCreateOrganizationRegex = async () => {
    await createOrganizationRegex({ organizationId, regex: regex.trim() });
    handleCloseModal();
  };

  return (
    <Modal
      open={isCreateOrganizationRegexModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`Create regex - ${organizationName}`}
      className="create-entity-modal"
      destroyOnClose
    >
      <div className="input-container">
        <ZorbaInput
          placeholder="Regex"
          onChange={(e) => setRegex(e.target.value)}
          value={regex}
        />
      </div>
      <div className="create-button-container">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={handleCreateOrganizationRegex}
          disabled={
            regex.length <= 2 ||
            isPending ||
            !organizationId ||
            organizationRegexesData.some(
              (organizationRegex) => organizationRegex.regex === regex.trim(),
            )
          }
          loading={isPending}
        >
          Create
        </ZorbaButton>
      </div>
    </Modal>
  );
};
