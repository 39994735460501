export const getTabs = (isZorbaUser) => [
  {
    key: '1',
    label: 'Overview',
    content: 'Test',
  },
  {
    key: '2',
    label: 'Buy Box',
  },
  {
    key: '3',
    label: 'Transactions',
  },
  {
    key: '4',
    label: 'Contacts',
  },
  ...(isZorbaUser ? [{ key: '5', label: 'Admin' }] : []),
];
