import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const getConnectedStates = async () => {
  const response = await getRequest('/data/states/get-states');

  const { data } = response.data;

  return data;
};

export const useGetConnectedStates = () =>
  useQuery({
    queryKey: ['connectedStates'],
    queryFn: () => getConnectedStates(),
  });

export const connectState = async (params) => {
  await postRequest('/data/states/connect-state', params);
};

export const useConnectState = (onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: connectState,
    onSuccess: () => {
      onSuccess();
      showSuccessToast('State successfully connected!');
      queryClient.invalidateQueries({
        queryKey: ['connectedStates'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Connecting state failed!',
      );
    },
  });
};

export const requestUpgradeToPro = async () => {
  await postRequest('/data/states/upgrade-to-pro');
};

export const useRequestUpgradeToPro = () =>
  useMutation({
    mutationFn: requestUpgradeToPro,
    onSuccess: () => {
      showSuccessToast(
        'Thank you for requesting an upgrade to the PRO version. Our team will reach out to you shortly!',
      );
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Requesting upgrade failed!',
      );
    },
  });

export const cancelStateSubscription = async (params) => {
  await postRequest('/data/states/cancel-state-subscription', params);
};

export const useCancelStateSubscription = (onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelStateSubscription,
    onSuccess: () => {
      onSuccess();
      showSuccessToast('State subscription successfully cancelled!');
      queryClient.invalidateQueries({
        queryKey: ['connectedStates'],
      });
    },
    onError: (error) => {
      showErrorToast(
        error?.response?.data?.reason || 'Cancelling state subscription failed!',
      );
    },
  });
};