import { useState, useMemo, useEffect } from 'react';
import { Card } from 'antd';
import { useSelector } from 'react-redux';

import { useGetInsights } from '../../../../queries/Insights';
import { INSIGHT_TYPES } from '../../../../queries/Insights/constants';
import { AddAccountToWatchListModal } from '../../../TopBuyersReportPage/Components/TopBuyersReport/Components/AddAccountToWatchListModal';
import { InsightsList } from './Components/InsightsList';
import { InsightPlaceholder } from './Components/InsightPlaceholder';
import { InsightDetails } from './Components/InsightDetails';
import { ViewPanel } from '../../../../containers/view/ViewPanel';

import './index.scss';

export const Insights = () => {
  const selectedDays = 90;
  const [selectedInsight, setSelectedInsight] = useState('');
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const [insightType, setInsightType] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState('');

  const { data, isLoading } = useGetInsights({
    selectedDays,
    appliedFilters,
  });

  const dataSource = useMemo(() => {
    if (!data) return [];

    switch (insightType) {
      case INSIGHT_TYPES.ACQUISITION_DECREASE:
        return data.acquisitionDecreaseEvents;
      case INSIGHT_TYPES.ACQUISITION_INCREASE:
        return data.acquisitionIncreaseEvents;
      case INSIGHT_TYPES.ACQUISITION_PAUSE:
        return data.acquisitionPauseEvents;
      case INSIGHT_TYPES.ACQUISITION_MARKET_EXPANSION:
        return data.acquisitionMarketExpansionEvents;
      default:
        return [
          ...data.acquisitionMarketExpansionEvents,
          ...data.acquisitionIncreaseEvents,
          ...data.acquisitionDecreaseEvents,
          ...data.acquisitionPauseEvents,
        ];
    }
  }, [data, insightType]); // Dependencies for memoization

  const selectInsight = (insight) => {
    setSelectedInsight(insight);
  };

  useEffect(() => {
    setSelectedInsight('');
  }, [appliedFilters]);

  return (
    <div className="insights-page">
      <div className="insights-filter">
        <ViewPanel
          saveMode={false}
          availableFilters={['state', 'propertyType']}
        />
      </div>
      <div className="cards-container">
        <Card
          title={
            <div className="latest-insights-card-title">
              Market highlights from the past 90 days
              <span className="latest-insights-card-additional-title">
                Use the filters button to gain insights into specific markets.
              </span>
            </div>
          }
          className="latest-insights-card"
        >
          <InsightsList
            insightType={insightType}
            selectInsight={selectInsight}
            dataSource={dataSource}
            isLoading={isLoading}
            setInsightType={setInsightType}
            selectedInsight={selectedInsight}
          />
        </Card>
        <Card className="insight-detail-card">
          {!selectedInsight ? (
            <InsightPlaceholder />
          ) : (
            <InsightDetails
              selectedInsight={selectedInsight}
              setSelectedAccountId={setSelectedAccountId}
              dateRange={data?.periods || []}
            />
          )}
        </Card>
      </div>
      <AddAccountToWatchListModal
        selectedAccountId={selectedAccountId}
        setSelectedAccountId={setSelectedAccountId}
      />
    </div>
  );
};
