import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { getTableColumns } from './constants';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CANCEL_STATE } from '../../../../../../reducers/Modal/types';
import { isMobile } from '../../../../../../services/clientService';

export const ActiveStatesTable = ({ data, isLoading, setStateToCancel }) => {
  const dispatch = useDispatch();
  const handleOpenCancelModal = (state) => {
    setStateToCancel(state);
    dispatch(setModalState(MODAL_CANCEL_STATE, true));
  };
  const columns = getTableColumns({ handleOpenCancelModal });
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  return (
    <Table
      className="active-states-table"
      rowKey="name"
      dataSource={data}
      loading={isLoading}
      columns={columns}
      scroll={tableScroll}
    />
  );
};
