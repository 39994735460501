import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { showErrorToast } from '../../../../services/notificationsService';
import { useGetTitleOrganizationDetails } from '../../../../queries/TitleOrganization';
import { getDefaultRangePickerValue } from '../../../../utils';
import { ViewPanel } from '../../../../containers/view/ViewPanel';
import { ZorbaDateRangePicker } from '../../../../components/forms/ZorbaDateRangePicker';
import { AccountTabs } from './Components/AccountTabs';
import { Header } from './Components/Header';
import { Overview } from './Components/Overview';
import { Transactions } from './Components/Transactions';
import { Clients } from './Components/Clients';
import { AdminPanel } from '../../../AccountPage/Components/AccountDetails/Components/AdminPanel';

import './index.scss';

export const TitleOrganization = () => {
  const { postgresId } = useParams();
  const [activeTab, setActiveTab] = useState('1');
  const [selectedRange, setSelectedRange] = useState(
    getDefaultRangePickerValue(90),
  );
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );

  const {
    data: titleOrganizationData,
    isLoading,
    isError,
  } = useGetTitleOrganizationDetails({
    selectedRange,
    postgresId,
    appliedFilters,
  });

  if (isError) {
    showErrorToast('Fetching title organization details failed!');
  }

  const isMultiFamilySelected = appliedFilters?.propertyType?.isAnyOf?.includes(
    'Multi Family Home',
  );
  const isVacantSelected = appliedFilters?.propertyType?.isAnyOf?.includes(
    'Vacant',
  );
  const companyType = titleOrganizationData?.organization?.company_type;
  const titleOrganizationName = titleOrganizationData?.organization?.name;

  const renderComponent = () => {
    switch (activeTab) {
      case '1':
        return (
          <Overview
            appliedFilters={appliedFilters}
            selectedRange={selectedRange}
            postgresId={postgresId}
            titleOrganizationData={titleOrganizationData}
            isDataLoading={isLoading}
          />
        );
      case '2':
        return (
          <Clients
            postgresId={postgresId}
            appliedFilters={appliedFilters}
            selectedRange={selectedRange}
          />
        );
      case '3':
        return (
          <Transactions
            isMultiFamilySelected={isMultiFamilySelected}
            isVacantSelected={isVacantSelected}
            appliedFilters={appliedFilters}
            selectedRange={selectedRange}
            postgresId={postgresId}
          />
        );
      case '4':
        return (
          <AdminPanel
            companyType={companyType}
            isFetchingCompanyData={isLoading}
            organizationId={postgresId}
            organizationName={titleOrganizationName}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <div className="title-company-page">
      <div className="account-details-filter">
        <Header titleCompanyName={titleOrganizationName} />
        <AccountTabs setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
      <Divider className="divider" />
      {(activeTab === '1' || activeTab === '2' || activeTab === '3') && (
        <ViewPanel availableFilters={['propertyType', 'state']}>
          <ZorbaDateRangePicker
            onChange={(e) => setSelectedRange(e)}
            value={selectedRange}
            rangePresets={[
              { label: 'Last 30 days', value: 30 },
              { label: 'Last 60 days', value: 60 },
              { label: 'Last 90 days', value: 90 },
            ]}
            disabled={isLoading}
          />
        </ViewPanel>
      )}
      {renderComponent()}
    </div>
  );
};
