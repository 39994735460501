import { Table } from 'antd';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import { useRemoveFromWatchlist } from '../../../../../../queries/Watchlist';

export const EntitiesTable = ({ data, isLoading, selectedList }) => {
  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  const {
    mutate: removeFromWatchlist,
    isPending: isRemovingFromWatchlist,
  } = useRemoveFromWatchlist();

  const handleRemoveFromWatchList = (accountId) => {
    removeFromWatchlist({ listId: selectedList._id, accountId });
  };

  return (
    <Table
      rowKey="id"
      loading={isLoading || isRemovingFromWatchlist}
      columns={getTableColumns({
        watchlistName: selectedList?.listName,
        handleRemoveFromWatchList,
      })}
      dataSource={data?.entities || []}
      pagination={pagination}
      scroll={tableScroll}
    />
  );
};
