import { List, Radio, Tag } from 'antd';
import { v4 as uuid } from 'uuid';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { INSIGHT_TYPES } from '../../../../../../queries/Insights/constants';
import { getInsightDescription, getTagDescription } from '../../constants';

export const InsightsList = ({
  setInsightType,
  insightType,
  isLoading,
  dataSource,
  selectInsight,
  selectedInsight,
}) => (
  <>
    <Radio.Group
      options={[
        { label: 'All', value: '' },
        {
          label: 'Acq Decrease',
          value: INSIGHT_TYPES.ACQUISITION_DECREASE,
        },
        {
          label: 'Acq Growth',
          value: INSIGHT_TYPES.ACQUISITION_INCREASE,
        },
        {
          label: 'Acq Pause',
          value: INSIGHT_TYPES.ACQUISITION_PAUSE,
        },
        {
          label: 'Acq Market Expansion',
          value: INSIGHT_TYPES.ACQUISITION_MARKET_EXPANSION,
        },
      ]}
      onChange={(e) => {
        selectInsight('');
        setInsightType(e.target.value);
      }}
      value={insightType}
      optionType="button"
      buttonStyle="solid"
      className="insights-radio"
    />
    <List
      loading={isLoading}
      pagination={{
        position: 'bottom',
        align: 'end',
        pageSize: 50,
        showTotal: (totalItems, range) =>
          `${range[0]}-${range[1]} of ${totalItems} insights`,
      }}
      dataSource={dataSource}
      className="insights-list"
      renderItem={(item) => (
        <List.Item
          onClick={() => selectInsight(item)}
          className={
            item.organizationName === selectedInsight.organizationName
              ? 'selected'
              : ''
          }
          key={uuid()}
        >
          <List.Item.Meta
            title={
              <>
                <div className="list-item-container">
                  <div className="list-item-title-container">
                    <div>
                      <a
                        href={`https://data.getzorba.com/top-buyers/${item.organizationId}/${item.organizationName}`}
                        className="organization-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.organizationName}{' '}
                      </a>
                      <span className="insight-description">
                        {getInsightDescription(item)}
                      </span>
                    </div>
                    <div>
                      <Tag color="#D72F59" className="insight-tag">
                        {getTagDescription(item)}
                      </Tag>
                    </div>
                  </div>
                  <div className="icon-container">
                    <FiChevronRight size={40} />
                  </div>
                </div>
              </>
            }
          />
        </List.Item>
      )}
    />
  </>
);
