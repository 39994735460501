import { Tag } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { PlacesAutoComplete } from '..';
import './index.scss';
import { getDealAddress } from '../../../utils';

export default (props) => {
  const [areaList, setAreaList] = useState(props.areaList || []);
  const isControlled = !!props.onChange;
  const areaListValue = isControlled ? props.value || [] : areaList;

  const onPlacesChange = (address) => {
    if (!address) return;

    const updatedAreaList = _.uniqBy(
      [...areaListValue, address],
      'googlePlaceId',
    );
    setAreaList(updatedAreaList);

    if (isControlled) {
      props.onChange(updatedAreaList);
    } else {
      setAreaList(updatedAreaList);
    }
  };
  const onRemove = (index) => {
    const updatedAreaList = areaListValue.filter((x, y) => y !== index);

    if (isControlled) {
      props.onChange(updatedAreaList);
    } else {
      setAreaList(updatedAreaList);
    }
  };

  return (
    <div className="multi-places-auto-complete">
      <PlacesAutoComplete
        clearInputOnSelect
        help=""
        onChange={(address) => onPlacesChange(address)}
        showOnlyLocalitiesAndZipCodes={props.showOnlyLocalitiesAndZipCodes}
        hasNationwideAccess={props.hasNationwideAccess}
        activatedStates={props.activatedStates}
      />
      <div className="area-list-wrapper">
        {areaListValue.map((singleArea, i) => (
          <Tag
            key={singleArea.googlePlaceId}
            closable
            onClose={() => onRemove(i)}
          >
            {getDealAddress({ address: singleArea })}
          </Tag>
        ))}
      </div>
    </div>
  );
};
