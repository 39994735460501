import { Card, Input } from 'antd';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { useState } from 'react';
import { ZorbaButton, ZorbaSelect } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { statesOptions } from '../../../../containers/drawer/FiltersDrawer/Components/FiltersBuilder/constants';
import './index.scss';

export default (props) => {
  const { loading } = props;
  const [searchParams, setSearchParams] = useState({});

  const onChange = (key, value) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    });
  };
  const onSearchClick = () => {
    props.onSearch(searchParams);
  };

  return (
    <Card className="search-card-unmasking">
      <div className="search-container">
        <div className="row">
          <Input
            value={searchParams.name}
            placeholder="ARMM ASSET COMPANY 2 LLC"
            onChange={(e) => onChange('entityName', e.target.value)}
          />
          <ZorbaButton
            loading={loading}
            onClick={() => onSearchClick()}
            variant={BUTTON_VARIANT_DARK}
            disabled={!searchParams.entityName || !searchParams.state}
          >
            {loading ? null : <FaSearch />}
          </ZorbaButton>
        </div>
        <ZorbaSelect
          className="select"
          placeholder="Select State"
          onChange={(newValue) => onChange('state', newValue)}
          options={statesOptions}
          disabled={loading}
          fullWidth={false}
        />
      </div>
    </Card>
  );
};
