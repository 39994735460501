import { useState } from 'react';
import { Divider, Radio, Table, Tag, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInsightDescription,
  getInsightDetailedDescription,
  getTagDescription,
} from '../../constants';
import { useGetWatchLists } from '../../../../../../queries/Watchlist';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_ADD_ACCOUNT_TO_WATCHLIST } from '../../../../../../reducers/Modal/types';
import { getTableColumns } from './constants';
import { useGetInsightTransactions } from '../../../../../../queries/Insights';

export const InsightDetails = ({
  selectedInsight,
  setSelectedAccountId,
  dateRange,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState('previousPeriod');
  const dispatch = useDispatch();
  const { data: lists } = useGetWatchLists();
  const history = useHistory();
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const openAddAccountModal = (accountId) => {
    dispatch(setModalState(MODAL_ADD_ACCOUNT_TO_WATCHLIST, true));
    setSelectedAccountId(accountId);
  };

  const { data, isLoading } = useGetInsightTransactions({
    dateRange,
    appliedFilters,
    organizationId: selectedInsight.organizationId,
  });
  const saveToWatchlist = (organizationId) => {
    const navigateToWatchlist = () => {
      history.push('/');
    };

    if (!lists?.length)
      return (
        <Tooltip title="Create a watchlist to easily track your favorite organizations">
          <span className="save-on-watchlist" onClick={navigateToWatchlist}>
            Save
          </span>
        </Tooltip>
      );

    if (lists.every((list) => list.accountIds.includes(organizationId)))
      return '';

    return (
      <span
        className="save-on-watchlist"
        onClick={() => openAddAccountModal(organizationId)}
      >
        Save
      </span>
    );
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: true,
    y: 500,
  };

  return (
    <div className="insight-details-container">
      <div className="insight-details-title">
        {saveToWatchlist(selectedInsight.organizationId)}
        <Tag color="#D72F59" className="insight-tag">
          {getTagDescription(selectedInsight)}
        </Tag>
      </div>
      <div className="insight-description-container">
        <a
          href={`https://data.getzorba.com/top-buyers/${selectedInsight.organizationId}/${selectedInsight.organizationName}`}
          className="organization-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {selectedInsight.organizationName}
        </a>
        <span className="insight-description">
          {getInsightDescription(selectedInsight)}
        </span>
      </div>
      <Divider />
      <div className="insight-explanation-container">
        <h2>How did we gain this insight?</h2>
        <span>{getInsightDetailedDescription(selectedInsight, dateRange)}</span>
        <Radio.Group
          options={[
            {
              label: `${
                new Date(dateRange[1]?.startDate).getMonth() + 1
              }/${new Date(dateRange[1]?.startDate).getFullYear()} - ${
                new Date(dateRange[1]?.endDate).getMonth() + 1
              }/${new Date(dateRange[1]?.endDate).getFullYear()} 
               ${
                 data?.previousTransactions?.length
                   ? `(${data.previousTransactions.length} properties)`
                   : ''
               }           
               `,
              value: 'previousPeriod',
            },
            {
              label: `Last 3 months ${
                data?.currentTransactions?.length
                  ? `(${data.currentTransactions.length} properties)`
                  : ''
              }`,
              value: 'currentPeriod',
            },
          ]}
          onChange={(e) => {
            setSelectedPeriod(e.target.value);
          }}
          value={selectedPeriod}
          optionType="button"
          buttonStyle="solid"
          className="insights-period-radio"
        />
      </div>
      <div className="insight-table-container">
        <Table
          columns={getTableColumns()}
          dataSource={
            selectedPeriod === 'currentPeriod'
              ? data?.currentTransactions || []
              : data?.previousTransactions || []
          }
          loading={isLoading}
          scroll={tableScroll}
          pagination={false}
        />
      </div>
    </div>
  );
};
