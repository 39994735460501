import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useFeatureFlagCheck = (userDetails, key) => {
  const history = useHistory();

  useEffect(() => {
    if (
      !userDetails?.defaultOrganization?.dataSubscription?.featureFlags[key] ||
      !userDetails?.defaultOrganization?.dataSubscription?.featureFlags[key]
        .isActive
    ) {
      history.push('/');
    }
  }, [userDetails, key, history]);
};
