import { useState } from 'react';
import { Card } from 'antd';
import { v4 as uuid } from 'uuid';
import { MarketViewReportTable } from './Components/MarketViewReportTable';
import { MarketViewPanel } from './Components/MarketViewPanel';

import './index.scss';

const rangeOptions = [
  { value: 2023, label: '2023' },
  { value: 2024, label: '2024' },
];

export const MarketViewReport = () => {
  const [selectedYear, setSelectedYear] = useState(2024);

  const handleRangeChange = (value) => {
    setSelectedYear(value);
  };

  return (
    <div className="market-view-report-page">
      <div className="market-view-report-radio">
        <MarketViewPanel>
          <div className="range-container">
            {rangeOptions.map((option) => (
              <Card
                key={uuid()}
                className={`range-card ${
                  selectedYear === option.value ? 'active' : ''
                }`}
                onClick={() => handleRangeChange(option.value)}
              >
                {option.label}
              </Card>
            ))}
          </div>
        </MarketViewPanel>
      </div>
      <Card className="market-view-report-table-container">
        <MarketViewReportTable selectedYear={selectedYear} />
      </Card>
    </div>
  );
};
