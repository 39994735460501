import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import { RequestLogs } from './Components/RequestLogs';
import ContentHeader from '../../components/ContentHeader';

export default () => {
  useDocumentTitle(`Request Logs`);
  useEffect(() => {
    reportScreen('request logs page');
  }, []);

  return (
    <Page>
      <ContentHeader title="Request logs" />
      <RequestLogs />
    </Page>
  );
};
