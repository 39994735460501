import { useSelector } from 'react-redux';
import classNames from 'classnames';

import DisableApp from './Components/DisableApp';

import './index.scss';

const Page = ({ className, wide, align, children }) => {
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  return (
    <div
      className={classNames(
        { [className]: className !== '' },
        { wide, [`align-${align}`]: !!align },
      )}
    >
      <div className="page-inner">
        <div className="page-inner-content">{children}</div>
      </div>
      <DisableApp
        isVerified={userDetails?.defaultOrganization?.verification?.verified}
        isBanned={userDetails?.isBanned}
        userDetails={userDetails}
      />
    </div>
  );
};

export default Page;
