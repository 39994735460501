import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const ZorbaDropdown = (props) => {
  const {
    btnClass = 'deal-actions',
    className = '',
    actions = [],
    btnText,
    placement = 'bottomRight',
    selectable = false,
    selectedKeys = [],
  } = props;
  if (!actions.length) return null;

  const menuItems = actions.map((a) =>
    a.subMenu ? (
      <Menu.SubMenu
        key={a?.title}
        title={a?.title}
        popupClassName={a?.className}
      >
        {a.subMenu}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={a?.title} onClick={a?.onClick} disabled={a?.disabled}>
        {a?.title || 'Unknown'}
        {a.linkComponent}
      </Menu.Item>
    ),
  );

  const ActionBtn = () =>
    typeof btnText === 'string' ? (
      <>
        {btnText}
        <DownOutlined />
      </>
    ) : (
      btnText
    );

  return (
    <Dropdown
      overlayClassName={className}
      overlay={
        <Menu selectable={selectable} defaultSelectedKeys={selectedKeys}>
          {menuItems}
        </Menu>
      }
      placement={placement}
      trigger="click"
    >
      <a
        className={classNames({
          [btnClass]: btnClass,
        })}
        rel="noopener noreferrer"
        onClick={(e) => e.preventDefault()}
        role="menu"
      >
        <ActionBtn />
      </a>
    </Dropdown>
  );
};

ZorbaDropdown.defaultProps = {
  btnText: 'Click',
  actions: [],
  className: '',
  placement: 'bottomRight',
};
ZorbaDropdown.propTypes = {
  btnText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  placement: PropTypes.string,
};

export default ZorbaDropdown;
