import _ from 'lodash';
import * as constants from './constants';
import {
  ONBOARDING_STEP_CREATE,
  ONBOARDING_STEP_COMPANY_DETAILS,
} from './constants';

export const validateOnboarding = (params, step) => {
  const { organizationName, companyType, position } = params;
  const validationObj = {
    organizationName: '',
    companyType: '',
    position: '',
  };
  switch (step) {
    case ONBOARDING_STEP_CREATE:
      if (organizationName?.length < 3) {
        validationObj.organizationName = 'Required. At least 3 letters';
      }
      break;
    case ONBOARDING_STEP_COMPANY_DETAILS:
      if (!companyType) {
        validationObj.companyType =
          'Required. Please select one of the options';
      }
      if (!position) {
        validationObj.position = 'Required. Please select one of the options';
      }
      break;
    default:
      break;
  }

  return {
    isValid:
      Object.values(validationObj).filter((x) => x.length > 0).length === 0,
    validationObj,
  };
};

export const getStepParams = (currentStepKey, params) => {
  switch (currentStepKey) {
    case constants.ONBOARDING_STEP_CREATE:
      return _.pick(params, ['organizationName']);
    case constants.ONBOARDING_STEP_COMPANY_DETAILS:
      return _.pick(params, ['position', 'companyType']);
    default:
      return {};
  }
};
