import {
  addCommas,
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

const MAP_PROPERTY_TYPE_TO_STRING = {
  44: 'Single Family Home',
  23: 'Multi Family Home',
  17: 'Vacant',
  21: 'Vacant',
};
export const getTableColumns = ({
  isMultiFamilySelected,
  isVacantSelected,
}) => [
  {
    title: 'Buyer',
    key: 'buyer',
    render: ({ buyer }) => buyer,
  },
  {
    title: 'Seller',
    key: 'seller',
    render: ({ seller }) => seller,
  },
  {
    title: 'Address',
    key: 'address',
    render: ({ address }) => address,
  },
  {
    title: 'State',
    key: 'state',
    render: ({ state }) => state,
  },
  {
    title: 'Property Type',
    key: 'propertyType',
    render: ({ propertyType }) => MAP_PROPERTY_TYPE_TO_STRING[propertyType],
  },
  ...(isMultiFamilySelected
    ? [
        {
          title: 'Units count',
          key: 'unitsCount',
          render: ({ unitsCount }) => unitsCount,
        },
      ]
    : []),
  ...(isVacantSelected
    ? [
        {
          title: 'Lot size',
          key: 'lotSize',
          render: ({ lotSize }) =>
            lotSize ? `${addCommas(lotSize)} sqft` : '',
        },
      ]
    : []),
  {
    title: 'Title company name',
    key: 'titleCompanyName',
    render: ({ titleCompanyName }) =>
      titleCompanyName !== 'NONE LISTED ON DOCUMENT' ? titleCompanyName : '',
    sorter: (a, b, sortOrder) => {
      const nameA =
        a.titleCompanyName && a.titleCompanyName !== 'NONE LISTED ON DOCUMENT'
          ? a.titleCompanyName
          : '';
      const nameB =
        b.titleCompanyName && b.titleCompanyName !== 'NONE LISTED ON DOCUMENT'
          ? b.titleCompanyName
          : '';

      if (nameA === '' && nameB === '') return 0;
      if (nameA === '') return sortOrder === 'ascend' ? 1 : -1;
      if (nameB === '') return sortOrder === 'ascend' ? -1 : 1;

      return nameA.localeCompare(nameB);
    },
  },
  {
    title: 'Lender name',
    key: 'lenderName',
    render: ({ lenderName }) => lenderName,
  },
  {
    title: 'Purchase price',
    key: 'purchasePrice',
    render: ({ purchasePrice }) => getPriceText(purchasePrice),
  },
  {
    title: 'AVM',
    key: 'avm',
    render: ({ avm }) => getPriceText(avm),
  },
  {
    title: 'Transaction date',
    key: 'transactionDate',
    render: ({ transactionDate }) => getFormattedTime(transactionDate),
  },
];
