export const ASSET_TYPES = [
  {
    label: 'Select asset class ... ',
    value: null,
  },
  {
    label: 'Single Family Home',
    value: 'Single Family Home',
  },
  {
    label: 'Multi Family Home',
    value: 'Multi Family Home',
  },
  {
    label: 'Vacant Lots',
    value: 'Vacant',
  },
];
