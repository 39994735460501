import { Table } from 'antd';
import { getTableColumns } from './constants';
import { isMobile } from '../../../../services/clientService';

export const CompaniesTable = ({ data, loading }) => {
  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  return (
    <Table
      rowKey="openCorporatesLink"
      dataSource={data}
      columns={getTableColumns()}
      pagination={pagination}
      scroll={tableScroll}
      loading={loading}
    />
  );
};
