import { useState } from 'react';
import { Card } from 'antd';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuid } from 'uuid';
import { setModalState } from '../../../../reducers/Modal/actions';
import { MODAL_CREATE_WATCHLIST } from '../../../../reducers/Modal/types';
import { useGetListDetails } from '../../../../queries/Watchlist';
import { MyLists } from './Components/MyLists';
import { EntitiesTable } from './Components/EntitiesTable';
import { rangeOptions } from '../../../TopBuyersReportPage/constants';

import './index.scss';

export const Watchlist = ({ selectedList, setSelectedList }) => {
  const [selectedMonths, setSelectedMonths] = useState(3);
  const dispatch = useDispatch();
  const openCreateWatchlistModal = () => {
    dispatch(setModalState(MODAL_CREATE_WATCHLIST, true));
  };
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );

  const handleRangeChange = (value) => {
    setSelectedMonths(value);
  };

  const { data, isLoading: isDetailsLoading, isRefetching } = useGetListDetails(
    {
      listId: selectedList?._id,
      selectedMonthRange: selectedMonths,
      appliedFilters,
    },
  );

  return (
    <div className="watchlist-page">
      <div className="range-container">
        {rangeOptions.map((option) => (
          <Card
            key={uuid()}
            className={`range-card ${
              selectedMonths === option.value ? 'active' : ''
            }`}
            onClick={() => handleRangeChange(option.value)}
          >
            {option.label}
          </Card>
        ))}
      </div>
      {!isDetailsLoading && !isRefetching && (
        <div className="stats">
          <Card>
            <h3>Accounts</h3>
            <h1>{data?.accounts || 0}</h1>
          </Card>
          <Card>
            <h3>Total Acquisitions</h3>
            <h1>{data?.totalAcquisitions || 0}</h1>
          </Card>
          <Card>
            <h3>Total Dispositions</h3>
            <h1>{data?.totalDispositions || 0}</h1>
          </Card>
          <Card>
            <h3>Total Acquisitions GMV</h3>
            <h1>{getPriceText(data?.totalAcquisitionsGMV || 0)}</h1>
          </Card>
        </div>
      )}
      <div className="watchlist-content-container">
        <Card
          title={
            <div className="my-lists-title">
              My lists{' '}
              <FaPlusCircle
                size={25}
                className="plus-icon"
                onClick={openCreateWatchlistModal}
              />
            </div>
          }
          className="my-lists-card"
        >
          <MyLists
            setSelectedList={setSelectedList}
            selectedList={selectedList}
          />
        </Card>
        <Card title="Accounts" className="accounts-card">
          <EntitiesTable
            selectedList={selectedList}
            data={data}
            isLoading={isDetailsLoading || isRefetching}
          />
        </Card>
      </div>
    </div>
  );
};
