import { INSIGHT_TYPES } from '../../../../queries/Insights/constants';

export const daysOptions = [
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 60 days', value: 60 },
  { label: 'Last 90 days', value: 90 },
];

export const getInsightDescription = (insight) => {
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_DECREASE) {
    return ` reduced their acquisition volume by ${Number(
      insight.decreasePercentage,
    ).toFixed(0)}%`;
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_INCREASE) {
    return ` increased their acquisition volume by ${Number(
      insight.increasePercentage,
    ).toFixed(0)}%`;
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_PAUSE) {
    return ` paused acquisitions in all markets`;
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_MARKET_EXPANSION) {
    const diffStates = insight.currentStates.filter(
      (currentState) => !insight.previousStates.includes(currentState),
    );
    return ` recently expanded to ${diffStates.join(', ')}`;
  }

  return '';
};

export const getTagDescription = (insight) => {
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_DECREASE) {
    return 'Acquisition decrease';
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_INCREASE) {
    return 'Acquisition growth';
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_PAUSE) {
    return 'Acquisition pause';
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_MARKET_EXPANSION) {
    return 'Market expansion';
  }

  return '';
};

export const getInsightDetailedDescription = (insight, dateRange) => {
  if (!insight || !dateRange?.length) return '';

  const startMonthPreviousPeriod = new Date(
    dateRange[1].startDate,
  ).toLocaleString('en-US', {
    month: 'long',
  });
  const endMonthPreviousPeriod = new Date(dateRange[1].endDate).toLocaleString(
    'en-US',
    {
      month: 'long',
    },
  );
  const startYearPreviousPeriod = new Date(
    dateRange[1].startDate,
  ).getFullYear();
  const endYearPreviousPeriod = new Date(dateRange[1].endDate).getFullYear();

  const startMonthCurrentPeriod = new Date(
    dateRange[0].startDate,
  ).toLocaleString('en-US', {
    month: 'long',
  });
  const startYearCurrentPeriod = new Date(dateRange[0].startDate).getFullYear();

  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_DECREASE) {
    return (
      <>
        From {startMonthPreviousPeriod} {startYearPreviousPeriod} to{' '}
        {endMonthPreviousPeriod} {endYearPreviousPeriod},{' '}
        {insight.organizationName} acquired a total of{' '}
        <strong>{insight.propertiesPreviousPeriod}</strong> properties. Since{' '}
        {startMonthCurrentPeriod} {startYearCurrentPeriod}, they have decreased{' '}
        their volume to <strong>{insight.propertiesCurrentPeriod}</strong>{' '}
        properties.
      </>
    );
  }
  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_INCREASE) {
    return (
      <>
        From {startMonthPreviousPeriod} {startYearPreviousPeriod} to{' '}
        {endMonthPreviousPeriod} {endYearPreviousPeriod},{' '}
        {insight.organizationName} acquired a total of{' '}
        <strong>{insight.propertiesPreviousPeriod}</strong> properties. Since{' '}
        {startMonthCurrentPeriod} {startYearCurrentPeriod}, they have increased{' '}
        their volume to <strong>{insight.propertiesCurrentPeriod}</strong>{' '}
        properties.
      </>
    );
  }

  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_PAUSE) {
    return (
      <>
        From {startMonthPreviousPeriod} {startYearPreviousPeriod} to{' '}
        {endMonthPreviousPeriod} {endYearPreviousPeriod},{' '}
        {insight.organizationName} acquired a total of{' '}
        <strong>{insight.propertiesPreviousPeriod}</strong> properties. Since{' '}
        {startMonthCurrentPeriod} {startYearCurrentPeriod}, they have not
        acquired a single property.
      </>
    );
  }

  if (insight.insightType === INSIGHT_TYPES.ACQUISITION_MARKET_EXPANSION) {
    return (
      <>
        From {startMonthPreviousPeriod} {startYearPreviousPeriod} to{' '}
        {endMonthPreviousPeriod} {endYearPreviousPeriod},{' '}
        {insight.organizationName} acquired a total of{' '}
        <strong>{insight.propertiesPreviousPeriod}</strong> properties across{' '}
        <strong>{insight.previousStates.join(', ')}</strong>. Since{' '}
        {startMonthCurrentPeriod} {startYearCurrentPeriod}, they have acquired{' '}
        <strong>{insight.propertiesCurrentPeriod}</strong> properties in those
        same markets, plus{' '}
        <strong>
          {insight.currentStates
            .filter((state) => !insight.previousStates.includes(state))
            .join(', ')}
        </strong>
        .
      </>
    );
  }

  return '';
};
