import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import dayjs from 'dayjs';

export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = (searchParamsToSet) => {
    history.push({ search: searchParamsToSet.toString() });
  };

  return [searchParams, setSearchParams];
};

export const toQueryString = (payload) => {
  const params = new URLSearchParams();
  if (payload) {
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      if (value && (!Array.isArray(value) || value.length > 0)) {
        params.append(key, value);
      }
    });
  }
  return params;
};

export const getDefaultRangePickerValue = (defaultDateRange) => [
  dayjs().add(-defaultDateRange, 'day').format('YYYY-MM-DD'),
  dayjs().format('YYYY-MM-DD'),
];

export const anonymizeRow = (index, startingIndex = 2) => {
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const hasNationwideAccess =
    userDetails?.defaultOrganization?.dataSubscription?.hasNationwideAccess;

  return !hasNationwideAccess && index > startingIndex ? 'blur-row' : '';
};

export const camelCaseToTitleCase = (input) => {
  let result = input.replace(/([a-z])([A-Z])/g, '$1 $2');

  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
};

export const getDealAddress = (deal) => {
  if (!deal?.address) return '';

  return deal.address.formattedAddress?.replace(', USA', '');
};
