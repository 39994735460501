import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../../../../services/clientService';
import { getTableColumns } from './constants';
import { useDeleteEntity } from '../../../../../../queries/Account';

export const EntitiesTable = ({ isLoading, accountDetailsData }) => {
  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : '',
  };

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 10,
  };

  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const isZorbaUser = userDetails?.email?.includes('getzorba.com');
  const { mutate: deleteEntity, isPending } = useDeleteEntity();

  return (
    <Table
      rowKey="name"
      loading={isLoading || isPending}
      columns={getTableColumns(isZorbaUser, deleteEntity)}
      dataSource={accountDetailsData?.entities || []}
      scroll={tableScroll}
      style={{ maxHeight: '450px', overflow: 'auto' }}
      pagination={pagination}
    />
  );
};
