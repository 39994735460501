import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { v4 as uuid } from 'uuid';
import { DEAL_SUBMISSION_MATCH_STATUS_TITLES } from '@zorba-shared/core/Constants/offer';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { ZorbaButton } from '../../../../../../../../components/forms';
import { optionTitles } from '../FiltersBuilder/constants';
import { BUTTON_VARIANT_LINK } from '../../../../../../../../components/forms/ZorbaButton/constants';
import { camelCaseToTitleCase } from '../../../../../../../../utils';

const convertSelectedFilterToString = (obj) => {
  if (typeof obj === 'string') return obj;

  if (Array.isArray(obj) && typeof obj[0] !== 'object') {
    if (obj[0] && obj[1]) {
      return `between ${getPriceText(obj[0])} and ${getPriceText(obj[1])}`;
    }
    if (obj[0] && !obj[1]) {
      return `higher than ${getPriceText(obj[0])}`;
    }
    if (!obj[0] && obj[1]) {
      return `lower than ${getPriceText(obj[1])}`;
    }
  }

  if (Array.isArray(obj) && typeof obj[0] === 'object') {
    return obj.map((el) => el?.formattedAddress || '').join(' | ');
  }

  if (!obj || typeof obj !== 'object') {
    return '';
  }

  const key = Object.keys(obj)[0];
  const values = obj[key];

  if (!Array.isArray(values)) {
    return '';
  }

  if (values.length === 0) {
    return '';
  }

  const result = values
    .map((value) => DEAL_SUBMISSION_MATCH_STATUS_TITLES[value] || value)
    .join(', ');

  return `${result}`;
};

export const InitialFiltersState = ({
  appliedFilters,
  handleOpenFilterList,
  handleOpenFilterDetail,
  filterConfig,
  handleOnDelete,
}) => {
  const getTextFromAppliedFilter = (filterKey) => {
    const filter = appliedFilters[filterKey];
    return convertSelectedFilterToString(filter);
  };

  const getSelectedRadioValue = (filterKey) => {
    const filterObject = appliedFilters[filterKey];
    if (typeof filterObject === 'string' || Array.isArray(filterObject))
      return 'is ';

    const key = Object.keys(filterObject)[0];

    return `${optionTitles[key]} `;
  };

  return (
    <>
      <h4>Applied filters ({Object.keys(appliedFilters).length})</h4>
      <div className="applied-filters-summary-container">
        {Object.keys(appliedFilters).length ? (
          Object.keys(appliedFilters).map((filterKey) => (
            <div key={uuid()}>
              <div className="delete-filter-container">
                <ZorbaButton
                  fullWidth={false}
                  variant={BUTTON_VARIANT_LINK}
                  onClick={() => handleOnDelete(filterKey)}
                >
                  Delete
                </ZorbaButton>
              </div>
              <div
                className="applied-filter-description"
                onClick={() => handleOpenFilterDetail(filterKey)}
              >
                <span className="applied-filter-title">{`${camelCaseToTitleCase(
                  filterKey,
                )} `}</span>
                <span>{getSelectedRadioValue(filterKey)}</span>
                <span className="applied-filter-title">
                  {getTextFromAppliedFilter(filterKey)}
                </span>
              </div>
            </div>
          ))
        ) : (
          <span>This report has no filters applied</span>
        )}
      </div>
      {filterConfig.length > 0 && (
        <div className="add-filter-container">
          <ZorbaButton
            onClick={handleOpenFilterList}
            icon={<AiOutlinePlus />}
            fullWidth={false}
            disabled={!filterConfig.length}
          >
            Add filter
          </ZorbaButton>
        </div>
      )}
    </>
  );
};
