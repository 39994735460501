import { Card, Input } from 'antd';
import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import {
  BUTTON_VARIANT_DARK,
  BUTTON_VARIANT_LINK,
} from '../../../../../../components/forms/ZorbaButton/constants';
import { parameters } from './constants';
import {
  useCreateUpdateCustomTemplate,
  useDeleteCustomTemplate,
  useGetCustomTemplates,
} from '../../../../../../queries/CustomTemplates';
import { Spinner } from '../../../../../../components/layout';

import './index.scss';

export const CustomTemplates = () => {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [options, setOptions] = useState([
    { label: 'Select template...', value: '' },
  ]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [errors, setErrors] = useState([]);
  const onTextAreaChange = (newTextAreaValue) => {
    const parameterRegex = /{{\s*\w+(\.\w+)*\s*}}/g;
    const extractedParameters = newTextAreaValue.match(parameterRegex) || [];

    const definedParameters = parameters.map((param) => param.split(' ')[0]);
    const missingParameters = extractedParameters.filter(
      (param) => !definedParameters.includes(param),
    );

    setErrors(missingParameters);
    setTextAreaValue(newTextAreaValue);
  };

  const { data: customTemplates, isLoading } = useGetCustomTemplates();

  useEffect(() => {
    if (customTemplates?.length) {
      const customOptions = customTemplates.map((customTemplate) => ({
        label: customTemplate.templateName,
        value: customTemplate.templateName,
      }));
      setOptions([
        { label: 'Select template...', value: '' },
        ...customOptions,
      ]);
    }
  }, [customTemplates]);

  const templateToUpdate = (customTemplates || []).find(
    (template) => template.templateName === selectedTemplate,
  );

  const isTemplateChanged = templateToUpdate?.template !== textAreaValue;
  const isTemplateNameChanged =
    templateToUpdate?.templateName !== newTemplateName;
  const handleSelect = (newValue) => {
    const alreadyCreatedTemplate = customTemplates.find(
      (template) => template.templateName === newValue,
    );
    if (alreadyCreatedTemplate) {
      setTextAreaValue(alreadyCreatedTemplate.template);
      setNewTemplateName(alreadyCreatedTemplate.templateName);
    } else {
      setNewTemplateName('');
      setTextAreaValue('');
    }
    setSelectedTemplate(newValue);
  };

  const handleRevertChanges = () => {
    if (templateToUpdate) {
      setTextAreaValue(templateToUpdate.template);
    } else {
      setTextAreaValue('');
    }
  };

  const {
    mutate: deleteCustomTemplate,
    isPending: isDeletingCustomTemplate,
  } = useDeleteCustomTemplate();

  const {
    mutate: createUpdateCustomTemplate,
    isPending,
  } = useCreateUpdateCustomTemplate();
  const handleCreateCustomTemplate = () => {
    createUpdateCustomTemplate({
      templateName: newTemplateName,
      template: textAreaValue,
      templateId: templateToUpdate?._id || null,
    });
    setSelectedTemplate(newTemplateName);
  };

  const handleDeleteCustomTemplate = () => {
    deleteCustomTemplate({
      templateId: templateToUpdate._id,
    });
    setSelectedTemplate('');
    setTextAreaValue('');
  };

  const handleAddTemplate = () => {
    setSelectedTemplate('');
    setTextAreaValue('');
    setNewTemplateName('');
  };

  return (
    <div className="custom-templates-container">
      <div className="description-container">
        <h2>Custom Marketing Templates</h2>
        {isLoading || isDeletingCustomTemplate ? <Spinner /> : null}
      </div>
      {!isLoading && !isDeletingCustomTemplate && (
        <>
          <div className="select-container">
            <ZorbaSelect
              onSelect={handleSelect}
              fullWidth={false}
              placeholder="Select template"
              options={options}
              value={selectedTemplate}
            />
            <FaPlusCircle
              size={20}
              style={{ cursor: 'pointer' }}
              onClick={handleAddTemplate}
            />
          </div>
          <div className="card-and-parameters-container">
            <Card className="custom-template-card">
              <ZorbaInput
                placeholder="Type the name of new template"
                onChange={(e) => setNewTemplateName(e.target.value)}
                value={newTemplateName}
              />
              <h4>
                {templateToUpdate?.updatedBy
                  ? `Last Updated by: ${templateToUpdate.updatedBy.name}`
                  : ''}
              </h4>
              <Input.TextArea
                placeholder={`Hi {{contact_first_name}},
We just noticed your team works with title companies like {{acquisition_1_title_company_name}}, {{acquisition_2_title_company_name}}
`}
                onChange={(e) => onTextAreaChange(e.target.value)}
                className="text-area"
                value={textAreaValue}
              />
              {errors.length > 0 && (
                <div className="errors-container">
                  {errors.map((error) => (
                    <div
                      key={uuid()}
                    >{`${error} is not a valid parameter.`}</div>
                  ))}
                </div>
              )}
            </Card>
            <div className="available-parameters-container">
              <span className="available-parameters-title">
                Available parameters:
              </span>
              {parameters.map((parameter) => (
                <div key={uuid()} className="param-container">
                  {parameter}
                </div>
              ))}
            </div>
          </div>
          <div className="buttons-container">
            {templateToUpdate && (
              <ZorbaButton
                fullWidth={false}
                variant={BUTTON_VARIANT_LINK}
                className="delete-custom-template-button"
                disabled={!selectedTemplate || !templateToUpdate}
                onClick={handleDeleteCustomTemplate}
                loading={isDeletingCustomTemplate}
              >
                Delete
              </ZorbaButton>
            )}
            <ZorbaButton
              fullWidth={false}
              variant={BUTTON_VARIANT_LINK}
              className="revert-changes-button"
              disabled={
                !selectedTemplate ||
                (!isTemplateChanged && !isTemplateNameChanged)
              }
              onClick={handleRevertChanges}
            >
              Revert Changes
            </ZorbaButton>
            <ZorbaButton
              fullWidth={false}
              variant={BUTTON_VARIANT_DARK}
              disabled={
                !textAreaValue.trim() ||
                errors.length ||
                (!isTemplateChanged && !isTemplateNameChanged) ||
                isDeletingCustomTemplate
              }
              onClick={handleCreateCustomTemplate}
              loading={isLoading || isPending}
            >
              {templateToUpdate ? 'Update Template' : 'Create Template'}
            </ZorbaButton>
          </div>
        </>
      )}
    </div>
  );
};
