import React, { useEffect } from 'react';
import { Layout, Tooltip } from 'antd';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useGetViews } from '../../../../queries/View';
import { ZorbaSelect } from '../../../../components/forms';
import { setDefaultView } from '../../../../reducers/Auth/actions';
import { setFiltersFromView } from '../../../../reducers/Filters/actions';

import './index.scss';
import { camelCaseToTitleCase } from '../../../../utils';
import { optionTitles } from '../../../drawer/FiltersDrawer/Components/FiltersBuilder/constants';

const { Header } = Layout;

const convertSelectedFilterToString = (obj) => {
  if (typeof obj === 'string') return obj;

  if (!obj || typeof obj !== 'object') {
    return '';
  }

  const key = Object.keys(obj)[0];
  const values = obj[key];

  if (!Array.isArray(values)) {
    return '';
  }

  if (values.length === 0) {
    return '';
  }

  const result = values.join(', ');

  return `${result}`;
};

export default (props) => {
  const { data: views } = useGetViews();
  const dispatch = useDispatch();

  const options = (views || []).map((view) => ({
    label: view.viewName,
    value: view._id,
  }));
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const defaultView =
    userDetails?.defaultOrganization?.dataSubscription?.defaultView;

  const handleChange = (viewId) => {
    dispatch(setDefaultView(viewId));
  };

  useEffect(() => {
    const selectedViewFilters =
      (views || []).find((view) => view._id === defaultView)?.appliedFilters ||
      {};
    dispatch(setFiltersFromView(selectedViewFilters));
  }, [defaultView, views]);

  const appliedFilters =
    (views || []).find((view) => view._id === defaultView)?.appliedFilters ||
    {};

  const getSelectedRadioValue = (filterKey) => {
    const filterObject = appliedFilters[filterKey];
    if (typeof filterObject === 'string') return 'is ';

    const key = Object.keys(filterObject)[0];

    return `${optionTitles[key]} `;
  };

  const getTextFromAppliedFilter = (filterKey) => {
    const filter = appliedFilters[filterKey];
    return convertSelectedFilterToString(filter);
  };

  const getTooltipText = () =>
    Object.keys(appliedFilters).length
      ? Object.keys(appliedFilters).map((filterKey) => (
          <div key={uuid()}>
            <div className="applied-filter-description">
              <span className="applied-filter-title">{`${camelCaseToTitleCase(
                filterKey,
              )} `}</span>
              <span>{getSelectedRadioValue(filterKey)}</span>
              <span className="applied-filter-title">
                {getTextFromAppliedFilter(filterKey)}
              </span>
            </div>
          </div>
        ))
      : '';

  return (
    <Header className="site-header">
      {props.collapsed ? (
        <FaChevronRight
          className="trigger"
          onClick={() => props.setCollapsed(!props.collapsed)}
        />
      ) : (
        <FaChevronLeft
          className="trigger"
          onClick={() => props.setCollapsed(!props.collapsed)}
        />
      )}
      {views?.length > 0 && (
        <div>
          <Tooltip title={getTooltipText()} placement="left">
            <div>
              <ZorbaSelect
                className="default-view-select"
                options={[{ label: 'Select view...', value: null }, ...options]}
                fullWidth={false}
                value={defaultView}
                onChange={(value) => handleChange(value)}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </Header>
  );
};
