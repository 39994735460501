import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Select } from 'antd';
import { transactionTypeOptions } from '../constants';
import { setAppliedFilters } from '../../../../../../reducers/Filters/actions';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';

export const TransactionTypeBuilder = ({ onApplyFilter }) => {
  const dispatch = useDispatch();
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const defaultSelectValue = appliedFilters.transactionType;

  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState(
    defaultSelectValue,
  );
  const handleSelectChange = (newValue) => {
    setSelectedTransactionTypes(newValue);
  };

  const isApplyButtonDisabled = !selectedTransactionTypes;

  const handleApply = () => {
    dispatch(setAppliedFilters('transactionType', selectedTransactionTypes));
    onApplyFilter();
  };

  return (
    <div className="builder-container">
      <h3>Transaction Type</h3>
      <div className="options-container">
        <Select
          className="select"
          placeholder="Select Transaction Type"
          onChange={(newValue) => handleSelectChange(newValue)}
          value={selectedTransactionTypes}
          options={transactionTypeOptions}
        />
        `{' '}
      </div>
      <ZorbaButton
        variant={BUTTON_VARIANT_DARK}
        disabled={isApplyButtonDisabled}
        onClick={handleApply}
        fullWidth={false}
      >
        Apply
      </ZorbaButton>
    </div>
  );
};
