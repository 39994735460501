import { Tag } from 'antd';
import { v4 as uuid } from 'uuid';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../../../../../../services/clientService';
import {
  BUTTON_VARIANT_LIGHT,
  BUTTON_VARIANT_LINK,
} from '../../../../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import {
  propertyTypeOptions,
  statesOptions,
} from '../MarketViewFiltersDrawer/Components/FiltersBuilder/constants';
import {
  removePropertyTypeFromAppliedFilters,
  removeStateFromAppliedFilters,
  resetAppliedFilters,
} from '../../../../../../reducers/MarketView/actions';
import { setDrawerState } from '../../../../../../reducers/Drawer/actions';
import { MARKET_VIEW_FILTERS_DRAWER } from '../../../../../../reducers/Drawer/types';
import { MarketViewFiltersDrawer } from '../MarketViewFiltersDrawer';

import './index.scss';

export const MarketViewPanel = ({ children }) => {
  const dispatch = useDispatch();
  const { appliedFiltersMarketView: appliedFilters } = useSelector(
    ({ MarketViewReducer }) => MarketViewReducer,
  );
  const handleClearState = (state) => {
    dispatch(removeStateFromAppliedFilters(state));
  };

  const handleClearPropertyType = (propertyType) => {
    dispatch(removePropertyTypeFromAppliedFilters(propertyType));
  };

  const handleClearAllFilters = () => {
    dispatch(resetAppliedFilters());
  };
  const openFiltersDrawer = () => {
    dispatch(setDrawerState(MARKET_VIEW_FILTERS_DRAWER, true));
  };

  return (
    <>
      <div className="market-view-applied-filters-container">
        <div className="tags-container">
          {(
            appliedFilters?.state?.isAnyOf ||
            appliedFilters?.state?.isNoneOf ||
            []
          ).map((el) => (
            <Tag className="tag-close" key={uuid()}>
              {statesOptions.find((option) => option.value === el)?.label}
              <IoMdClose onClick={() => handleClearState(el)} />
            </Tag>
          ))}
          {(
            appliedFilters?.propertyType?.isAnyOf ||
            appliedFilters?.propertyType?.isNoneOf ||
            []
          ).map((el) => (
            <Tag className="tag-close" key={uuid()}>
              {propertyTypeOptions.find((option) => option.value === el)?.label}
              <IoMdClose onClick={() => handleClearPropertyType(el)} />
            </Tag>
          ))}
        </div>
        {Object.keys(appliedFilters).length > 0 && (
          <ZorbaButton
            variant={BUTTON_VARIANT_LINK}
            onClick={handleClearAllFilters}
            fullWidth={isMobile()}
          >
            Clear all
          </ZorbaButton>
        )}
        <ZorbaButton
          variant={BUTTON_VARIANT_LIGHT}
          onClick={openFiltersDrawer}
          fullWidth={isMobile()}
          icon={<BiFilterAlt />}
        >
          {Object.keys(appliedFilters).length
            ? `Applied filters (${Object.keys(appliedFilters).length})`
            : 'Filters'}
        </ZorbaButton>
        {children}
      </div>
      <MarketViewFiltersDrawer />
    </>
  );
};
