import { useIsFetching, useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getTopLenders = async (searchCriteria = {}) => {
  const res = await postRequest('/data/top-lenders-report', searchCriteria);

  const { data } = res.data;

  return data;
};

export const useGetTopLenders = ({
  selectedMonthRange,
  appliedFilters,
  isMounted,
}) => {
  const isQueryEnabled = useIsFetching({ queryKey: ['views'] }) === 0;

  return useQuery({
    queryKey: ['topLenders', selectedMonthRange, appliedFilters],
    queryFn: () => getTopLenders({ selectedMonthRange, appliedFilters }),
    enabled: isQueryEnabled && isMounted,
  });
};
