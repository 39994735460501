import {
  NATIONWIDE_MONTHLY_MARKETING_PRICE,
  PRICE_PER_STATE,
} from './constants';

export const buildCheckoutParams = (
  existingAndNewItemsCount,
  newStatesToSubscribe,
  availableCredits = 0,
) => {
  let monthlyPrice;
  let addedItemsCount;

  if (existingAndNewItemsCount < 3) {
    monthlyPrice = Math.max(
      PRICE_PER_STATE * (newStatesToSubscribe.length - availableCredits),
      0,
    );
    addedItemsCount = newStatesToSubscribe.length;
  } else if (existingAndNewItemsCount - availableCredits > 11) {
    monthlyPrice = NATIONWIDE_MONTHLY_MARKETING_PRICE;
    addedItemsCount = 12;
  } else {
    monthlyPrice = Math.max(
      PRICE_PER_STATE * (newStatesToSubscribe.length - availableCredits),
      0,
    );
    addedItemsCount = newStatesToSubscribe.length;
  }
  // Return the quarterly price
  return { payNowCost: monthlyPrice * 3, addedItemsCount };
};
