import { optionTitles } from '../../../../../../containers/drawer/FiltersDrawer/Components/FiltersBuilder/constants';

export const convertSelectedFilterToString = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return '';
  }

  const key = Object.keys(obj)[0];
  const values = obj[key];

  if (!Array.isArray(values)) {
    return '';
  }

  if (values.length === 0) {
    return '';
  }

  const result = values.map((value) => value).join(', ');

  return `${result}`;
};

export const getSelectedRadioValue = (appliedFilters, filterKey) => {
  const filterObject = appliedFilters[filterKey];
  const key = Object.keys(filterObject)[0];

  return `${optionTitles[key]} `;
};

export const getTextFromAppliedFilter = (appliedFilters, filterKey) => {
  const filterObject = appliedFilters[filterKey];
  return convertSelectedFilterToString(filterObject);
};
