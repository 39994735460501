import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getInsights = async ({ selectedDays, appliedFilters }) => {
  const response = await postRequest('/data/insights/get-insights', {
    daysSince: selectedDays,
    appliedFilters,
  });

  const { data } = response.data;

  return data;
};

export const useGetInsights = ({ selectedDays, appliedFilters }) =>
  useQuery({
    queryKey: ['insights', selectedDays, appliedFilters],
    queryFn: () => getInsights({ selectedDays, appliedFilters }),
  });

export const getInsightTransactions = async ({
  dateRange,
  appliedFilters,
  organizationId,
}) => {
  const response = await postRequest(
    '/data/insights/get-insight-transactions',
    {
      appliedFilters,
      dateRange,
      organizationId,
    },
  );

  const { data } = response.data;

  return data;
};

export const useGetInsightTransactions = ({
  dateRange,
  appliedFilters,
  organizationId,
}) =>
  useQuery({
    queryKey: [
      'insightTransactions',
      dateRange,
      appliedFilters,
      organizationId,
    ],
    queryFn: () =>
      getInsightTransactions({ dateRange, appliedFilters, organizationId }),
  });
