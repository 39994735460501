import { Page } from '../../components/layout';
import OnboardingWizard from '../../containers/onboarding/OnboardingWizard';

import './index.scss';

export default (props) => (
  <Page className="onboarding-page crm" {...props}>
    <OnboardingWizard {...props} />
  </Page>
);
