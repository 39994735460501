import { Liquid } from 'liquidjs';

const removeEmptyStrings = (obj) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      removeEmptyStrings(value);
    }

    if (value === '') {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }

    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      Object.keys(obj[key]).length === 0
    ) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj;
};
export const injectParams = async (template, params) => {
  const engine = new Liquid({
    strictVariables: true,
    lenientIf: true,
  });
  const cleanedParams = removeEmptyStrings(params);
  let renderedTemplate = await engine.parseAndRender(template, cleanedParams);

  renderedTemplate = renderedTemplate.replace(/\n/g, '<br/>');

  return renderedTemplate;
};

export const parseLiquidError = (errorMessage) => {
  if (!errorMessage) return '';

  const match = errorMessage.match(/undefined variable: (\S+)/);
  if (match) {
    const missingParam = match[1];
    return `We apologize, but this template can’t be used for this organization as some of the necessary variables, including ${missingParam} are missing from our data. Please choose a different template or create a new one.`;
  }

  return 'We apologize, but this template can’t be used for this organization.';
};

export const extractRequiredParams = (template) => {
  const regex = /{{\s*([a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*)\s*}}/g;
  let match;
  const params = new Set();

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(template)) !== null) {
    params.add(match[1]);
  }

  return Array.from(params);
};

export const findMatchingProperties = (acquiredProperties, requiredParams) => {
  let firstMatchingProperty = {};
  let secondMatchingProperty = {};

  if (
    requiredParams.includes('acquisition_1.address') ||
    requiredParams.includes('acquisition_1.title_company_name') ||
    requiredParams.includes('acquisition_1.lender_name')
  ) {
    const matchingProperty = acquiredProperties.find(
      (property) =>
        property.address.length > 10 &&
        (!requiredParams.includes('acquisition_1.title_company_name') ||
          (property.titleCompanyName &&
            property.titleCompanyName !== 'NONE LISTED ON DOCUMENT')) &&
        (!requiredParams.includes('acquisition_1.lender_name') ||
          property.lenderName),
    );

    if (matchingProperty) {
      firstMatchingProperty = matchingProperty;
    }
  }

  if (
    requiredParams.includes('acquisition_2.address') ||
    requiredParams.includes('acquisition_2.title_company_name') ||
    requiredParams.includes('acquisition_2.lender_name')
  ) {
    const matchingProperty = acquiredProperties.find(
      (property) =>
        property.address.length > 10 &&
        (!requiredParams.includes('acquisition_2.title_company_name') ||
          (property.titleCompanyName &&
            property.titleCompanyName !== 'NONE LISTED ON DOCUMENT')) &&
        (!requiredParams.includes('acquisition_2.lender_name') ||
          property.lenderName) &&
        property.address !== firstMatchingProperty?.address,
    );

    if (matchingProperty) {
      secondMatchingProperty = matchingProperty;
    }
  }

  return [firstMatchingProperty, secondMatchingProperty];
};
