import {
  addCommas,
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

const MAP_PROPERTY_TYPE_TO_STRING = {
  44: 'Single Family Home',
  23: 'Multi Family Home',
  17: 'Vacant',
  21: 'Vacant',
};
export const getTableColumns = ({
  isMultiFamilySelected,
  isVacantSelected,
}) => [
  {
    title: 'Title company name',
    key: 'titleCompanyName',
    render: ({ titleCompanyName }) => titleCompanyName,
  },
  {
    title: 'Buyer',
    key: 'buyer',
    render: ({ buyer }) => buyer,
  },
  {
    title: 'Buyer type',
    key: 'buyerEntityCode',
    render: ({ buyerEntityCode }) =>
      buyerEntityCode === 'Y' ? 'Investor' : 'Home Buyer',
    filters: [
      { text: 'Investors', value: 'Y' },
      { text: 'Home Buyers', value: 'Rest' }, // Label for the filter, but not used as a value
    ],
    onFilter: (value, { buyerEntityCode }) => {
      if (value === 'Y') {
        return buyerEntityCode === 'Y';
      }
      return buyerEntityCode !== 'Y'; // All values not equal to 'Y' will be considered "Home Buyers"
    },
  },
  {
    title: 'Seller',
    key: 'seller',
    render: ({ seller }) => seller,
  },
  {
    title: 'Address',
    key: 'address',
    render: ({ address }) => address,
  },
  {
    title: 'State',
    key: 'state',
    render: ({ state }) => state,
  },
  {
    title: 'Property Type',
    key: 'propertyType',
    render: ({ propertyType }) => MAP_PROPERTY_TYPE_TO_STRING[propertyType],
  },
  ...(isMultiFamilySelected
    ? [
        {
          title: 'Units count',
          key: 'unitsCount',
          render: ({ unitsCount }) => unitsCount,
        },
      ]
    : []),
  ...(isVacantSelected
    ? [
        {
          title: 'Lot size',
          key: 'lotSize',
          render: ({ lotSize }) =>
            lotSize ? `${addCommas(lotSize)} sqft` : '',
        },
      ]
    : []),
  {
    title: 'Purchase price',
    key: 'purchasePrice',
    render: ({ purchasePrice }) => getPriceText(purchasePrice),
  },
  {
    title: 'AVM',
    key: 'avm',
    render: ({ avm }) => getPriceText(avm),
  },
  {
    title: 'Transaction date',
    key: 'transactionDate',
    render: ({ transactionDate }) => getFormattedTime(transactionDate),
  },
];
