import { Popover as AntPopover } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { renderClonedChildren } from '../../../services/reactService';
import './index.scss';

const Popover = (props) => {
  const { className, title, titleIcon } = props;
  const popoverProps = _.pick(props, [
    'placement',
    'content',
    'trigger',
    'visible',
    'onVisibleChange',
  ]);

  return (
    <AntPopover
      {...popoverProps}
      title={
        title ? (
          <div className="contact-add-popover-title">
            {titleIcon || null}
            <div className="text">{title}</div>
          </div>
        ) : null
      }
      overlayClassName={classNames('popover-wrapper', {
        [className]: !!className,
      })}
      content={props.content}
    >
      {renderClonedChildren(props)}
    </AntPopover>
  );
};

Popover.defaultProps = {
  placement: 'top',
  title: '',
  trigger: 'click',
};

Popover.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  trigger: PropTypes.string,
};

export default Popover;
