import { Card } from 'antd';
import { AcquiredPropertiesTable } from '../AcquiredPropertiesTable';
import { SoldPropertiesTable } from '../SoldPropertiesTable';
import { PortfolioAggregationsTable } from '../PortfolioAggregationsTable';
import { useGetAccountTransactions } from '../../../../../../queries/Account';
import { showErrorToast } from '../../../../../../services/notificationsService';

export const Transactions = ({
  isMultiFamilySelected,
  isVacantSelected,
  selectedRange,
  postgresId,
  appliedFilters,
}) => {
  const {
    data: accountTransactionsData,
    isLoading: isTransactionsLoading,
    isError: isTransactionsError,
  } = useGetAccountTransactions({ selectedRange, postgresId, appliedFilters });

  if (isTransactionsError) {
    showErrorToast('Fetching transactions failed!');
  }

  return (
    <>
      <Card
        title={`Recent acquisitions (${
          accountTransactionsData?.acquiredProperties?.length || 0
        })`}
      >
        <AcquiredPropertiesTable
          isLoading={isTransactionsLoading}
          data={accountTransactionsData}
          isMultiFamilySelected={isMultiFamilySelected}
          isVacantSelected={isVacantSelected}
        />
      </Card>
      <Card
        title={`Recent dispositions (${
          accountTransactionsData?.soldProperties?.length || 0
        })`}
      >
        <SoldPropertiesTable
          isLoading={isTransactionsLoading}
          data={accountTransactionsData}
          isMultiFamilySelected={isMultiFamilySelected}
          isVacantSelected={isVacantSelected}
        />
      </Card>
      <Card
        title={`Recent inter-company transfers (${
          accountTransactionsData?.portfolioAggregations?.length || 0
        })`}
      >
        <PortfolioAggregationsTable
          isLoading={isTransactionsLoading}
          data={accountTransactionsData}
        />
      </Card>
    </>
  );
};
