export const LOCAL_STORAGE_IGNORE_ANALYTICS = 'ignore_analytics';

export const getLocalStorageItem = (key) => {
  if (!localStorage) return null;
  return localStorage.getItem(key);
};

export const setLocalStorageItem = (key, value) => {
  if (!localStorage) return;

  localStorage.setItem(key, value);
};

export const removeLocalStorageItem = (key) => {
  if (!localStorage) return;

  localStorage.removeItem(key);
};
