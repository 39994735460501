import { Card } from 'antd';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultView } from '../../../../../../reducers/Auth/actions';
import { useGetViews, useDeleteView } from '../../../../../../queries/View';
import { camelCaseToTitleCase } from '../../../../../../utils';
import { ZorbaButton } from '../../../../../../components/forms';
import {
  BUTTON_VARIANT_DARK,
  BUTTON_VARIANT_LIGHT,
  BUTTON_VARIANT_LINK,
} from '../../../../../../components/forms/ZorbaButton/constants';
import { getTextFromAppliedFilter, getSelectedRadioValue } from './utils';

import './index.scss';

export const ManageViews = () => {
  const dispatch = useDispatch();
  const { data: views } = useGetViews();
  const { mutate: deleteView, isPending } = useDeleteView();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const defaultView =
    userDetails?.defaultOrganization?.dataSubscription?.defaultView;

  const handleChange = (viewId) => {
    dispatch(setDefaultView(viewId));
  };
  const handleCreateWatchList = (viewId) => {
    deleteView({ viewId });
  };

  return (
    views?.length > 0 && (
      <div className="manage-views-container">
        <div className="description-container">
          <h2>Choose & Organize Views</h2>
          <span>
            Select the data app template you’d like to use. Once selected, the
            data in the app will be displayed based on those template filters.
          </span>
        </div>
        <div className="view-cards-container">
          {views.map((view) => (
            <Card className="view-card">
              <h2>{view.viewName}</h2>
              {Object.keys(view.appliedFilters).map((filterKey) => (
                <div key={uuid()} className="filter">
                  <span className="applied-filter">{`${camelCaseToTitleCase(
                    filterKey,
                  )} `}</span>
                  <span>
                    {getSelectedRadioValue(view.appliedFilters, filterKey)}
                  </span>
                  <span className="applied-filter">
                    {getTextFromAppliedFilter(view.appliedFilters, filterKey)}
                  </span>
                </div>
              ))}
              <div className="buttons-container">
                <ZorbaButton
                  fullWidth={false}
                  variant={
                    view._id === defaultView
                      ? BUTTON_VARIANT_LIGHT
                      : BUTTON_VARIANT_DARK
                  }
                  disabled={view._id === defaultView}
                  onClick={() => handleChange(view._id)}
                >
                  {view._id === defaultView ? '✓ Selected' : 'Select'}
                </ZorbaButton>
                {view._id !== defaultView && (
                  <ZorbaButton
                    fullWidth={false}
                    variant={BUTTON_VARIANT_LINK}
                    className="remove-button"
                    isLoading={isPending}
                    disabled={isPending}
                    onClick={() => handleCreateWatchList(view._id)}
                  >
                    Remove
                  </ZorbaButton>
                )}
              </div>
            </Card>
          ))}
        </div>
      </div>
    )
  );
};
