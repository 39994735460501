import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import './index.scss';

export default (props) => {
  const {
    variant,
    className,
    rounded = false,
    fullWidth = true,
    children,
    disabled,
    withTooltip,
    tooltipTitle,
  } = props;

  const inheritedProps = _.pick(props, [
    'htmlType',
    'onClick',
    'type',
    'href',
    'icon',
    'target',
    'loading',
    'style',
  ]);

  return withTooltip ? (
    <Tooltip title={tooltipTitle}>
      <Button
        {...inheritedProps}
        className={classNames('zorba-button', {
          [className]: className,
          [variant]: variant,
          'btn-rounded': rounded,
          'full-width': fullWidth,
        })}
        disabled={disabled}
      >
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button
      {...inheritedProps}
      className={classNames('zorba-button', {
        [className]: className,
        [variant]: variant,
        'btn-rounded': rounded,
        'full-width': fullWidth,
      })}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
