import * as types from './types';

const initialState = {
  modalParams: {},

  [types.MODAL_PARAM_EMAIL_VERIFICATION]: false,
  [types.MODAL_CREATE_WATCHLIST]: false,
  [types.MODAL_ADD_ACCOUNT_TO_WATCHLIST]: false,
  [types.MODAL_PARAM_MARKETING]: false,
  [types.MODAL_CONNECT_STATE]: false,
  [types.MODAL_CANCEL_STATE]: false,
  [types.MODAL_CREATE_ORGANIZATION]: false,
  [types.MODAL_CREATE_ENTITY]: false,
  [types.MODAL_CREATE_CONTACT]: false,
  [types.MODAL_CREATE_ORGANIZATION_REGEX]: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_MODAL_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
