import { Card } from 'antd';
import { AcquiredPropertiesTable } from '../AcquiredPropertiesTable';
import { SoldPropertiesTable } from '../SoldPropertiesTable';
import { showErrorToast } from '../../../../../../services/notificationsService';
import { useGetAccountEntityTransactions } from '../../../../../../queries/AccountEntity';
export const Transactions = ({
  isMultiFamilySelected,
  isVacantSelected,
  selectedRange,
  entityName,
  appliedFilters,
}) => {
  const {
    data: accountTransactionsData,
    isLoading: isTransactionsLoading,
    isError: isTransactionsError,
  } = useGetAccountEntityTransactions({
    selectedRange,
    entityName,
    appliedFilters,
  });

  if (isTransactionsError) {
    showErrorToast('Fetching transactions failed!');
  }

  return (
    <>
      <Card
        title={`Recent acquisitions (${
          accountTransactionsData?.acquiredProperties?.length || 0
        })`}
      >
        <AcquiredPropertiesTable
          isLoading={isTransactionsLoading}
          data={accountTransactionsData}
          isMultiFamilySelected={isMultiFamilySelected}
          isVacantSelected={isVacantSelected}
        />
      </Card>
      <Card
        title={`Recent dispositions (${
          accountTransactionsData?.soldProperties?.length || 0
        })`}
      >
        <SoldPropertiesTable
          isLoading={isTransactionsLoading}
          data={accountTransactionsData}
          isMultiFamilySelected={isMultiFamilySelected}
          isVacantSelected={isVacantSelected}
        />
      </Card>
    </>
  );
};
