import { Form } from 'antd';
import { ZorbaInput } from '../../../../../components/forms';

import './index.scss';

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export default (props) => {
  const {
    params: { organizationName },
    validationObj,
  } = props;
  const onChange = (e) => {
    const newVal = capitalize(e.target.value);
    props.onChange('organizationName', newVal);
  };
  return (
    <div className="onboarding-step create">
      <Form.Item
        className="wide"
        help={validationObj.organizationName || ' '}
        validateStatus={
          validationObj?.organizationName?.length > 0 ? 'error' : 'success'
        }
        label="What’s the name of your company or team?"
      >
        <ZorbaInput
          maxLength={26}
          value={organizationName}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  );
};
