import { useEffect } from 'react';
import { getEnvName } from '../services/clientService';

export function useDocumentTitle(title) {
  useEffect(() => {
    const envName = getEnvName();
    const newTitle = title || 'Zorba SFR Dashboard';
    document.title =
      envName !== 'production' ? `[${envName}] ${newTitle}` : newTitle;
  }, [title]);
}
