import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { isEmailValid } from '../../../services/textService';
import { ZorbaInput } from '..';

export default (props) => {
  const { defaultValue, showErrorMessage, errorMessage = '' } = props;
  const [value, setValue] = useState('');
  const [testedValue, setTestedValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [touched, setTouched] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isValid, setIsValid] = useState(false);

  const inputParams = _.pick(props, [
    'fullWidth',
    'variant',
    'formItemWithoutLabel',
  ]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, []);

  useEffect(() => {
    setValue(defaultValue);
    setTestedValue(defaultValue);
  }, [defaultValue]);
  const validateInput = (newValue) => {
    let newErrorMessage;

    if (newValue === testedValue) return;
    let isNewValueValid = true;

    if (!isEmailValid(newValue)) {
      newErrorMessage = 'Invalid email';
      isNewValueValid = false;
    }

    setTouched(true);
    setIsValid(isNewValueValid);
    if (props.onChange) {
      props.onChange({
        value: isNewValueValid ? newValue : '',
        isValid: isNewValueValid,
        errorMessage: newErrorMessage,
      });
    }
    setTestedValue(newValue);
  };

  const onChange = (newValue) => {
    setValue(newValue);
    validateInput(newValue);
    setTouched(false);
  };

  return (
    <ZorbaInput
      {...inputParams}
      className={classNames({ invalid: !!errorMessage })}
      inputId="email-input"
      label={props.label === undefined ? 'Email' : props.label}
      prefix={<i className="fas fa-envelope" />}
      placeholder="john@doe.com"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => validateInput(e.target.value)}
      help={showErrorMessage ? errorMessage : ''}
    />
  );
};
