import { Select, Radio, Space } from 'antd';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import { setAppliedFilters } from '../../../../../../reducers/Filters/actions';

import { statesOptions, options } from '../constants';

import '../index.scss';

export const StateBuilder = ({ onApplyFilter }) => {
  const dispatch = useDispatch();
  const { appliedFilters } = useSelector(
    ({ FiltersReducer }) => FiltersReducer,
  );
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [defaultRadioValue] = Object.keys(appliedFilters.state ?? {});
  const defaultSelectValue = defaultRadioValue
    ? appliedFilters.state[defaultRadioValue]
    : [];

  const [radioValue, setRadioValue] = useState(defaultRadioValue);
  const [selectedStates, setSelectedStates] = useState(defaultSelectValue);
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSelectChange = (newValue) => {
    setSelectedStates(newValue);
  };

  const isApplyButtonDisabled = !radioValue || !selectedStates.length;
  const handleApply = () => {
    dispatch(
      setAppliedFilters('state', {
        [radioValue]: selectedStates,
      }),
    );
    onApplyFilter();
  };

  const connectedStates =
    userDetails?.defaultOrganization?.dataSubscription?.activatedStates || [];
  const hasNationwideAccess =
    userDetails?.defaultOrganization?.dataSubscription?.hasNationwideAccess;

  const filteredStateOptions =
    connectedStates.length && !hasNationwideAccess
      ? statesOptions.filter((stateOption) =>
          connectedStates.some((state) => state.name === stateOption.value),
        )
      : statesOptions;

  return (
    <div className="builder-container">
      <h3>State</h3>
      <div className="options-container">
        <Radio.Group onChange={handleRadioChange} value={radioValue}>
          <Space direction="vertical">
            {options.map((option) => (
              <Radio key={uuid()} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        {radioValue && (
          <Select
            mode="multiple"
            className="select"
            placeholder="Select State"
            onChange={(newValue) => handleSelectChange(newValue)}
            value={selectedStates}
            options={filteredStateOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase()) ||
              option.value.toLowerCase().includes(input.toLowerCase())
            }
          />
        )}
      </div>
      <ZorbaButton
        variant={BUTTON_VARIANT_DARK}
        disabled={isApplyButtonDisabled}
        onClick={handleApply}
        fullWidth={false}
      >
        Apply
      </ZorbaButton>
    </div>
  );
};
