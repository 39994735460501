export const getTableColumns = () => [
  {
    title: 'Title company name',
    key: 'titleCompanyName',
    render: ({ titleCompanyName }) => titleCompanyName,
  },
  {
    title: 'Total Acquired Properties',
    key: 'totalAcquiredProperties',
    render: ({ totalAcquiredProperties }) => totalAcquiredProperties,
  },
];
