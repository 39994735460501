import { Tooltip } from 'antd';

export default (props) => {
  const { title, trigger } = props;
  return (
    <Tooltip {...props} title={title} trigger={trigger}>
      {props.children}
    </Tooltip>
  );
};
