import { useEffect } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { reportScreen } from '../../services/analyticsService';
import { Page } from '../../components/layout';
import { ConnectStates } from './Components/ConnectStates';

export default () => {
  useDocumentTitle('Settings');
  useEffect(() => {
    reportScreen('settings');
  }, []);

  return (
    <Page>
      <ConnectStates />
    </Page>
  );
};
