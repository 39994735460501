import { Card } from 'antd';
import { useState } from 'react';
import { AcquiredPropertiesTable } from '../AcquiredPropertiesTable';
import { showErrorToast } from '../../../../../../services/notificationsService';
import { useGetTitleEntityTransactions } from '../../../../../../queries/TitleEntity';

export const Transactions = ({
  isMultiFamilySelected,
  isVacantSelected,
  selectedRange,
  titleCompanyName,
  appliedFilters,
}) => {
  const [filteredCount, setFilteredCount] = useState(0);
  const {
    data: transactionsData,
    isLoading: isTransactionsLoading,
    isError: isTransactionsError,
  } = useGetTitleEntityTransactions({
    selectedRange,
    titleCompanyName,
    appliedFilters,
  });

  if (isTransactionsError) {
    showErrorToast('Fetching transactions failed!');
  }

  return (
    <>
      <Card
        title={`Recent transactions (${
          filteredCount || transactionsData?.length || 0
        })`}
      >
        <AcquiredPropertiesTable
          isLoading={isTransactionsLoading}
          data={transactionsData}
          isMultiFamilySelected={isMultiFamilySelected}
          isVacantSelected={isVacantSelected}
          setFilteredCount={setFilteredCount}
        />
      </Card>
    </>
  );
};
