import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setModalState } from '../../../../reducers/Modal/actions';
import { MODAL_CREATE_ENTITY } from '../../../../reducers/Modal/types';
import {
  ZorbaInput,
  ZorbaButton,
  ZorbaSelect,
} from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { getRequest, postRequest } from '../../../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/notificationsService';

import './index.scss';

export const CreateEntityModal = ({ entity }) => {
  const { isCreateEntityModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [entityName, setEntityName] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (entity) {
      setEntityName(entity);
    }
  }, [entity]);

  const handleCloseModal = () => {
    setEntityName('');
    setOrganizationId('');
    dispatch(setModalState(MODAL_CREATE_ENTITY, false));
  };

  const fetchOrganizations = async () => {
    try {
      setIsLoading(true);
      const { data } = await getRequest(
        '/data/organization/get-all-organizations',
      );
      setOrganizations(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOrganizations([]);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleCreateEntity = async () => {
    try {
      setIsLoading(true);
      await postRequest('/data/entity/create-entity', {
        entityName,
        organizationId,
      });
      setIsLoading(false);
      handleCloseModal();
      showSuccessToast('Entity created successfully');
    } catch (error) {
      showErrorToast('Creating entity failed!');
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isCreateEntityModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Create new entity"
      className="create-entity-modal"
      destroyOnClose
    >
      <div className="select-container">
        <ZorbaSelect
          label="Select organization"
          onChange={(value) => setOrganizationId(value)}
          value={organizationId}
          options={organizations.map((organization) => ({
            label: organization.name,
            value: organization.id,
          }))}
          showSearch
          filterOption={filterOption}
        />
      </div>
      <div className="input-container">
        <ZorbaInput
          placeholder="Entity name"
          onChange={(e) => setEntityName(e.target.value)}
          value={entityName}
        />
      </div>
      <div className="create-button-container">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={handleCreateEntity}
          disabled={entityName.length <= 2 || isLoading || !organizationId}
          loading={isLoading}
        >
          Create
        </ZorbaButton>
      </div>
    </Modal>
  );
};
