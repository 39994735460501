import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Radio, Select, Space } from 'antd';
import { v4 as uuid } from 'uuid';
import { propertyTypeOptions, options } from '../constants';
import { setAppliedFilters } from '../../../../../../../../../reducers/Transactions/actions';
import { ZorbaButton } from '../../../../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../../../../components/forms/ZorbaButton/constants';

export const PropertyTypeBuilder = ({ onApplyFilter }) => {
  const dispatch = useDispatch();
  const { appliedFiltersTransactions: appliedFilters } = useSelector(
    ({ TransactionsReducer }) => TransactionsReducer,
  );
  const [defaultRadioValue] = Object.keys(appliedFilters.propertyType ?? {});
  const defaultSelectValue = defaultRadioValue
    ? appliedFilters.propertyType[defaultRadioValue]
    : [];

  const [radioValue, setRadioValue] = useState(defaultRadioValue);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(
    defaultSelectValue,
  );

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSelectChange = (newValue) => {
    setSelectedPropertyTypes(newValue);
  };

  const isApplyButtonDisabled = !radioValue || !selectedPropertyTypes.length;

  const handleApply = () => {
    dispatch(
      setAppliedFilters('propertyType', {
        [radioValue]: selectedPropertyTypes,
      }),
    );
    onApplyFilter();
  };

  return (
    <div className="builder-container">
      <h3>Property Type</h3>
      <div className="options-container">
        <Radio.Group onChange={handleRadioChange} value={radioValue}>
          <Space direction="vertical">
            {options.map((option) => (
              <Radio key={uuid()} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        {radioValue && (
          <Select
            mode="tags"
            className="select"
            placeholder="Select Property Type"
            onChange={(newValue) => handleSelectChange(newValue)}
            value={selectedPropertyTypes}
            options={propertyTypeOptions}
          />
        )}
      </div>
      <ZorbaButton
        variant={BUTTON_VARIANT_DARK}
        disabled={isApplyButtonDisabled}
        onClick={handleApply}
        fullWidth={false}
      >
        Apply
      </ZorbaButton>
    </div>
  );
};
