import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getTitleEntityDetails = async (params) => {
  const response = await postRequest('/data/title-entity/details', params);

  const { data } = response.data;

  return data;
};
export const useGetTitleEntityDetails = ({
  selectedRange,
  titleCompanyName,
  appliedFilters,
}) =>
  useQuery({
    queryKey: [
      'titleEntityDetails',
      selectedRange,
      titleCompanyName,
      appliedFilters,
    ],
    queryFn: () =>
      getTitleEntityDetails({
        selectedRange,
        titleCompanyName,
        appliedFilters,
      }),
    enabled: Boolean(titleCompanyName),
  });

export const getTitleEntityStats = async (params) => {
  const response = await postRequest('/data/title-entity/stats', params);

  const { data } = response.data;

  return data;
};
export const useGetTitleEntityStats = ({ titleCompanyName, appliedFilters }) =>
  useQuery({
    queryKey: ['titleEntityStats', titleCompanyName, appliedFilters],
    queryFn: () =>
      getTitleEntityStats({
        titleCompanyName,
        appliedFilters,
      }),
    enabled: Boolean(titleCompanyName),
  });

export const getTitleEntityTransactions = async (params) => {
  const response = await postRequest('/data/title-entity/transactions', params);

  const { data } = response.data;

  return data;
};
export const useGetTitleEntityTransactions = ({
  titleCompanyName,
  appliedFilters,
  selectedRange,
}) =>
  useQuery({
    queryKey: [
      'titleEntityTransactions',
      titleCompanyName,
      appliedFilters,
      selectedRange,
    ],
    queryFn: () =>
      getTitleEntityTransactions({
        titleCompanyName,
        appliedFilters,
        selectedRange,
      }),
    enabled: Boolean(titleCompanyName),
  });

export const getTitleEntityClients = async (params) => {
  const response = await postRequest('/data/title-entity/clients', params);

  const { data } = response.data;

  return data;
};
export const useGetTitleEntityClients = ({
  titleCompanyName,
  appliedFilters,
  selectedRange,
}) =>
  useQuery({
    queryKey: [
      'titleEntityClients',
      titleCompanyName,
      appliedFilters,
      selectedRange,
    ],
    queryFn: () =>
      getTitleEntityClients({
        titleCompanyName,
        appliedFilters,
        selectedRange,
      }),
    enabled: Boolean(titleCompanyName),
  });
