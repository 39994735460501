import { Card } from 'antd';
import { getPriceText } from '@zorba-shared/core/Services/textService';

export const MultiFamilyBuyBox = ({ buyBoxData }) => (
  <>
    <div className="buybox-container">
      <Card className="buybox-detail-card">
        <h2>{buyBoxData?.yearBuilt ? `${buyBoxData.yearBuilt}+` : 'N/D'}</h2>
        <h3>Year built</h3>
      </Card>
      <Card className="buybox-detail-card">
        <h2>
          {buyBoxData?.unitsCount != null ? `${buyBoxData.unitsCount}+` : 'N/D'}
        </h2>
        <h3>Number of units</h3>
      </Card>
    </div>
    <div className="buybox-container">
      <Card className="buybox-detail-card">
        <h2>{buyBoxData?.lotSize ? `${buyBoxData.lotSize} sqft+` : 'N/D'}</h2>
        <h3>Lot size</h3>
      </Card>
      <Card className="buybox-detail-card">
        <h2>
          {buyBoxData?.averagePurchasePrice
            ? `${getPriceText(buyBoxData.averagePurchasePrice)}`
            : 'N/D'}
        </h2>
        <h3>AVG Purchase Price</h3>
      </Card>
    </div>
  </>
);
