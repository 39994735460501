import { Modal } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_CANCEL_STATE } from '../../../../../../reducers/Modal/types';
import { statesOptions } from '../../../../../../containers/drawer/FiltersDrawer/Components/FiltersBuilder/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import {
  BUTTON_VARIANT_DARK,
  BUTTON_VARIANT_LINK,
} from '../../../../../../components/forms/ZorbaButton/constants';
import { useCancelStateSubscription } from '../../../../../../queries/ConnectStates';

import './index.scss';

export const CancelStateModal = ({ stateToCancel, connectedStates }) => {
  const { isCancelStateModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_CANCEL_STATE, false));
  };
  const handleSuccess = () => {
    handleCloseModal();
  };

  const {
    mutate: cancelStateSubscription,
    isPending,
  } = useCancelStateSubscription(handleSuccess);

  const newDate = new Date(stateToCancel?.activatedAt);
  newDate.setMonth(newDate.getMonth() + 3);

  const stateExpiration = moment(newDate).format('MMMM DD, YYYY');

  const handleCancelSubscription = () => {
    cancelStateSubscription({ stateId: stateToCancel._id });
  };

  const stateLabel = statesOptions.find(
    (stateOption) => stateOption.value === stateToCancel?.name,
  )?.label;

  const activeStates = connectedStates?.filter(
    (state) => state.isActive && !state.disabledAt,
  );

  return (
    <Modal
      open={isCancelStateModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`Disconnect ${stateLabel} State Data`}
      className="cancel-state-modal"
      destroyOnClose
      width={700}
    >
      <div className="modal-container">
        <span className="description">
          {`Are you sure you’d like to disconnect the data stream of the state of
        ${stateLabel} from your account? By canceling now, you’ll still be able to
        access this state data until ${stateExpiration}. After that, this data
        source will be automatically disconnected from your account.`}
        </span>
        <span className="description">
          The changes will take affect to the next billing cycle.
        </span>
        <div className="policy-explanation">
          {activeStates?.length > 3 ? (
            <>
              In your next billing cycle, your subscription will be reduced from{' '}
              <strong>{activeStates.length} states</strong> to{' '}
              <strong>{activeStates.length - 1} states</strong>.
            </>
          ) : (
            <>
              Your subscription charge will remain the same, and in your next
              billing cycle, you’ll receive <strong>1 state</strong> credit to
              connect a new state.
            </>
          )}
        </div>
        <div className="buttons-container">
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_LINK}
            onClick={handleCloseModal}
            disabled={isPending}
          >
            Cancel
          </ZorbaButton>
          <ZorbaButton
            disabled={isPending}
            fullWidth={false}
            variant={BUTTON_VARIANT_DARK}
            onClick={handleCancelSubscription}
          >
            Disconnect
          </ZorbaButton>
        </div>
      </div>
    </Modal>
  );
};
