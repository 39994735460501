import { useQuery } from '@tanstack/react-query';
import { postRequest } from '../../services/requestService';

export const getTransactions = async (searchCriteria = {}) => {
  const res = await postRequest('/data/transactions', searchCriteria);

  const { data } = res.data;

  return data;
};

export const useGetTransactions = ({ selectedMonthRange, appliedFilters }) =>
  useQuery({
    queryKey: ['transactions', selectedMonthRange, appliedFilters],
    queryFn: () =>
      getTransactions({
        selectedMonthRange,
        appliedFilters,
      }),
  });
